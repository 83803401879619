import React, {useState} from "react";
import {Line} from "react-chartjs-2";
import {styles} from "../utils/Styles";
import type {ChartData, ChartOptions} from "chart.js";
import Chart from "chart.js/auto";
import {CategoryScale} from "chart.js";
import { DayData } from "../../layout/solar-battery/batteryInterfaces";
import { useTranslation } from 'react-i18next';

Chart.register(CategoryScale);

const LineChartday: React.FC<DayData> = ({
    jan, feb, mrt, apr, mei, jun, jul, aug, sep, okt, nov, dec,
}) => {
    const { t } = useTranslation();
    const [selectedMonths, setSelectedMonths] = useState<string[]>(["Mar", "Jun", "Sep"]);
    const [allHours, setAllHours] = useState<boolean>(false);

    const hourList = jan.map((record) => record.hour_of_day);
    const hourListTotal = hourList.filter((_, index) => index % 2 === 0);
    const hourListDuringDay = hourList.slice(7, 19);
    const monthData = {
        Jan: jan.map((record) => record.battery_content),
        Feb: feb.map((record) => record.battery_content),
        Mar: mrt.map((record) => record.battery_content),
        Apr: apr.map((record) => record.battery_content),
        May: mei.map((record) => record.battery_content),
        Jun: jun.map((record) => record.battery_content),
        Jul: jul.map((record) => record.battery_content),
        Aug: aug.map((record) => record.battery_content),
        Sep: sep.map((record) => record.battery_content),
        Oct: okt.map((record) => record.battery_content),
        Nov: nov.map((record) => record.battery_content),
        Dec: dec.map((record) => record.battery_content),
    };
    const monthDataTotal = {
        Jan: jan.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Feb: feb.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Mar: mrt.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Apr: apr.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        May: mei.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Jun: jun.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Jul: jul.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Aug: aug.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Sep: sep.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Oct: okt.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Nov: nov.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
        Dec: dec.filter((_, index) => index % 2 === 0).map((record) => record.battery_content),
    }
    const monthDataDuringDay = {
        Jan: jan.slice(7, 19).map((record) => record.battery_content),
        Feb: feb.slice(7, 19).map((record) => record.battery_content),
        Mar: mrt.slice(7, 19).map((record) => record.battery_content),
        Apr: apr.slice(7, 19).map((record) => record.battery_content),
        May: mei.slice(7, 19).map((record) => record.battery_content),
        Jun: jun.slice(7, 19).map((record) => record.battery_content),
        Jul: jul.slice(7, 19).map((record) => record.battery_content),
        Aug: aug.slice(7, 19).map((record) => record.battery_content),
        Sep: sep.slice(7, 19).map((record) => record.battery_content),
        Oct: okt.slice(7, 19).map((record) => record.battery_content),
        Nov: nov.slice(7, 19).map((record) => record.battery_content),
        Dec: dec.slice(7, 19).map((record) => record.battery_content),
    }
    const colorMap: Record<string, string> = {
        Jan: "rgba(5, 86, 139, 0.5)",
        Feb: "rgba(109, 54, 3, 0.5)",
        Mar: "rgb(5, 196, 196)",
        Apr: "rgba(255, 99, 132, 0.5)",
        May: "rgba(153, 102, 255, 0.5)",
        Jun: "rgba(251, 255, 0, 0.5)",
        Jul: "rgba(54, 162, 235, 0.5)",
        Aug: "rgba(255, 159, 64, 0.5)",
        Sep: "rgb(34, 128, 42)",
        Oct: "rgba(185, 182, 12, 0.5)",
        Nov: "rgba(134, 6, 146, 0.5)",
        Dec: "rgba(0, 0, 0, 0.5)"
    };

    const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const month = event.target.value;
        if (event.target.checked) {
            setSelectedMonths([...selectedMonths, month]);
        } else {
            setSelectedMonths(selectedMonths.filter((m) => m !== month));
        }
    };
    // monthData[month as keyof typeof monthData]
    const chartData = {
        labels: allHours ? hourListTotal : hourListDuringDay,
        datasets: selectedMonths.map((month) => ({
            label: `Average content ${month}`,
            borderColor: colorMap[month],
            data: allHours ? monthDataTotal[month as keyof typeof monthDataTotal] : monthDataDuringDay[month as keyof typeof monthDataDuringDay],
            borderWidth: 2,
            fill: false,
            type: "line",
        })),
    };

    const options: ChartOptions<"line"> = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                ticks: {
                    font: {
                        family: styles.label.fontFamily,
                    },
                },
            },
            y: {
                grid: {
                    color: "rgba(200, 200, 200, 0.2)", // Light gray grid lines
                },
                title: {
                    display: true, // Enables Y-axis title
                    text: t("DailyVerageGraphY"), // The label for Y-axis
                    font: {
                        size: 12, // Adjust size if needed
                        weight: "normal",
                    },
                },
                ticks: {
                    font: {
                        family: styles.label.fontFamily,
                    },
                },
            },
        },
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: styles.label.fontFamily,
                    },
                },
            },
        },
    };
    return (
        <div>
            <div style={{ marginBottom: "20px" }}>
                <label style={{fontWeight: "bold"}}>Select Months: </label>
                <br />
                <div style={{ marginTop: "5px" ,display: "grid", gridTemplateColumns: "repeat(6, auto)", gap: "5px" }}>
                    {Object.keys(monthData).map((month) => (
                        <label key={month} style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <input
                                type="checkbox"
                                value={month}
                                checked={selectedMonths.includes(month)}
                                onChange={handleMonthChange}
                            />
                            {month}
                        </label>
                    ))}
                </div>
                <br />
                <label style={{fontWeight: "bold"}}> All hours: </label>
                    <input
                        type="checkbox"
                        checked={allHours}
                        onChange={() => setAllHours(!allHours)}
                    />
            </div>
            <Line
                data={chartData as ChartData<"line">}
                options={options}
                style={{ minHeight: "300px", maxHeight: "300px" }}
            />
        </div>
    );
};

export default LineChartday;


