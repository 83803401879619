import React, {useState} from "react";
import { Popover } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';

interface PopupPros{
    title: string;
    imagePath: string;
    imagePath2?: string;
}

const Popup: React.FC<PopupPros> = ({
    title,
    imagePath,
    imagePath2
}) =>{
    const [isPopupVisisble, setIsPopupVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
    const open = Boolean(anchorEl);

    const togglePopup = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget);
        setIsPopupVisible(!isPopupVisisble)
    }
    const handleClose = () => {
        setAnchorEl(null);
        if (anchorEl) {
            anchorEl.focus(); // Return focus to the InfoIcon
        }
    };

    return(
        <div>
            <InfoIcon style={{fontSize: "1.5rem", cursor: "pointer"}} onClick={togglePopup} />
            <Popover
            id={anchorEl ? "simple-popover" : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            disableAutoFocus
            disableEnforceFocus
            >
                <Typography sx={{ padding: 2, display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem",  fontSize: "1rem", fontFamily: "Basic"}}>
                    {title}
					<img src={String(imagePath)} alt="Preview" />
                    {imagePath2 && <img src={String(imagePath2)} alt="Preview" />}
                </Typography>
            </Popover>
      </div>
    )
}

export default Popup;