import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {styles} from "../../components/utils/Styles";
import { UsageRecord, UsageData, ProgProdData } from "../../layout/solar-battery/batteryInterfaces";
import StackedBarChartWithLine from "../../components/plots/StackedBarLine";
import LineChartProdProg from "../../components/plots/LineChartProdProg";
import { getDownloadURL, ref } from "firebase/storage";
import {storage} from "../../config/firebase";

interface UsageGraphProps {
    usageChartData: UsageData;
    production: number;
    chartUrl?: string | null;
    progProdData?: ProgProdData[];
}

const UsageGraphs: React.FC<UsageGraphProps> = ({ usageChartData, production, chartUrl, progProdData }) => {
    const [gtvData, setGtvData] = useState<string | null>(null);
    const [collapseGtvGraph, setcollapseGtvGraph] = useState(false);
    const [simultaneousData, setSimultaneousData] = useState<UsageRecord[]>(usageChartData?.usage_data);
    const [collapseSimultaneousData, setCollapseSimultaneousData] = useState(false);
    const [progProductionData, setProgProductionData] = useState<ProgProdData[]>(progProdData || []);
    const [collapseProgProductionData, setCollapseProgProductionData] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (!chartUrl) return;
        const fetchImage = async () => {
            const storageRef = ref(storage, chartUrl);
            try {
                const imageUrl = await getDownloadURL(storageRef);
                setGtvData(imageUrl);
            }
            catch (error) {
                console.log("Could not fetch image", error);
            }
        };
        fetchImage();
    }, [chartUrl]);


return (
    <div>
        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
            {t('gtvGraph')} 
            <button 
            style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
            onClick={() => setcollapseGtvGraph(!collapseGtvGraph)} >
            Toggle graph
            </button>
        </h3>
        {gtvData && !collapseGtvGraph && (
            <div style={{
                ...styles.rightPanelStyle,
                maxWidth: "500px",
                marginTop: "10px",
                marginLeft: "-10px",
                }}
            >
                <img src={gtvData} alt="Chart" style={{ 
                maxWidth: "100%",  
                height: "auto"
                }} />
            </div>
        )}
        
        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
			{t('usageProdGraphGen')} 
            <button 
            style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
            onClick={() => setCollapseSimultaneousData(!collapseSimultaneousData)} >
            Toggle graph
            </button>
        </h3>
        <div>
            {simultaneousData && !collapseSimultaneousData && (
                <div style={{
                    ...styles.rightPanelStyle,
                    maxWidth: "500px",
                    marginTop: "10px",
                    marginLeft: "-10px",
                    }}
                >
                    <StackedBarChartWithLine
                        data={simultaneousData}
                        production={production}
                    />	
                    
                </div>
            )}
        </div>
        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
			{t('prodVsProgGraph')} 
            <button 
            style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
            onClick={() => setCollapseProgProductionData(!collapseProgProductionData)} >
            Toggle graph
            </button>
        </h3>
        <div>
            {progProductionData && !collapseProgProductionData && (
                <div style={{
                    ...styles.rightPanelStyle,
                    maxWidth: "500px",
                    marginTop: "10px",
                    marginLeft: "-10px",
                    }}
                >
                    <LineChartProdProg
                        data={progProductionData}
                    />	
                    
                </div>
            )}
        </div>
    </div>
)
}

export default UsageGraphs;