import React from "react";
import { Doughnut } from "react-chartjs-2";
import { styles } from "../utils/Styles";
import type { ChartData, ChartOptions, LegendItem } from "chart.js";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useTranslation } from "react-i18next";
Chart.register(CategoryScale);

interface PieData {
    name_clean: string;
    name: string;
    value: number;
}

interface PieChartProps {
    data: PieData[];
}

const DoughnutChart: React.FC<PieChartProps> = ({
    data
}) => {
    const {t} = useTranslation();
    const values = data.map((record) => record.value);
    const total = values.reduce((acc, curr) => acc + curr, 0).toLocaleString("nl-NL") + " kWh";
    const labels = data.map((record) => record.name);
    const ids = data.map((record) => record.name_clean);
    const graphTitle = ids.includes("usage_prod") ? "Verdeling opwek" : "Verdeling verbruik";

    const chartData: ChartData<"doughnut"> = {
        labels: labels,
        datasets: [
            {
                backgroundColor: ["#36a2eb80", "#ff9f4080", "#FF6384"],
                borderColor: "#ffffff",
                borderWidth: 2,
                hoverBorderWidth: 3,
                data: values,
            },
        ],
    };

    const options: ChartOptions<"doughnut"> = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "60%", // Adjust this value to make the doughnut bar thinner
        plugins: {
            legend: {
                display: true,
                position: "right",
                labels: {
                    color: "#333",
                    font: {
                        size: 14,
                        family: styles.label.fontFamily,
                        weight: "normal",
                    },
                    padding: 10,
                    boxWidth: 10,
                    generateLabels: (chart: Chart): LegendItem[] => {
                        const labels = chart.data.labels || []; // Fallback to empty array
                        const dataset = chart.data.datasets[0];
                        const backgroundColors = Array.isArray(dataset.backgroundColor) 
                            ? dataset.backgroundColor 
                            : [];
    
                        return labels.map((label, i) => ({
                            text: `${label}: ${(dataset.data[i] as number).toLocaleString("nl-NL")}`,
                            fillStyle: backgroundColors[i] || "#000", // Ensure valid color
                            hidden: false,
                            index: i,
                        }));
                    },
                },
            },
        },
    };


    return (
        <div style={{ width: "100%", height: "20vh", marginBottom: "50px" }}>
            <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>{graphTitle} - {total}</h3>
            <Doughnut
                data={chartData as ChartData<"doughnut">}
                options={options}
            />
        </div>
    );
};

export default DoughnutChart;
