import React, {useEffect, useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import {mergeDoc} from "../../services/firebaseFunctions";
import { Template } from "../../general-interfaces";
import {styles} from "../../components/utils/Styles";
import SimpleMaterialUITable from "../../components/simple-table";
import ExcelUpload from "../../components/excel-upload/ExcelUpload";
import { fetchQuickrJson, fetchProductionJson } from "../../services/apiFunctions";
import renderInputFields from "../../components/utils/renderInputFields";
import {options} from "../../config/options";

interface EnergyProductionProps {
    updateId: string;
    selectedInteraction: string;
    interactionData: any[]; 
    pageReady: boolean;
    template: any;
}

const ProductionInput: React.FC<EnergyProductionProps> = ({
    updateId,
    selectedInteraction,
    interactionData,
    pageReady,
    template
}) => {
    const {t} = useTranslation();
    const computationType = "solar_battery_computations";
    const [productionY1, setProductionY1] = useState(interactionData.find((interaction) => interaction.id === selectedInteraction)?.fields?.["5m50idGbxXVinxyCqxQx"] || "");
    const [calculationId, setCalculationId] = useState(interactionData.find((interaction) => interaction.id === selectedInteraction)?.fields?.fbzu2wmWI3MdG2BcWnIs || "");
    const [error, setError] = useState<boolean>(false);
    const [existingImage, setExistingImage] = useState<any>(null); 
    const [existingExcel, setExistingExcel] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [productionInputType, setProductionInputType] = useState<string | null>(interactionData.find((interaction) => interaction.id === selectedInteraction)?.fields?.["nANu1fal7jCXYmj7MrF6"] || ""); 

    const [productionDataType, setProductionDataType] = useState<string>(interactionData.find((interaction) => interaction.id === selectedInteraction)?.fields?.["ixxxzMYNGUUEiAxjgXFo"] || ""); 
    const [progProductionY1, setProgProductionY1] = useState(interactionData.find((interaction) => interaction.id === selectedInteraction)?.fields?.["DbyxcVkZAzC8K2z8TsMG"] || "");
    const [progCalculationId, setProgCalculationId] = useState(interactionData.find((interaction) => interaction.id === selectedInteraction)?.fields?.["w9ncJuJO8u59OG4k8yC3"] || "");
    const [progProductionInputType, setProgProductionInputType] = useState<string | null>(null); 

    const [progProduction, setProgProduction] = useState<any[]>(interactionData.find((interaction) => interaction.id === selectedInteraction)?.prog_production || []);
    const [production, setProduction] = useState<any[]>(interactionData.find((interaction) => interaction.id === selectedInteraction)?.production || []);

    // I'm trying
    const [formData, setFormData] = useState<Template | undefined>(undefined);
    const [change, setChange] = useState<boolean>(false);
    const [prodChanged, setProdChanged] = useState<boolean>(false);
    const [progProdChanged, setProgProdChanged] = useState<boolean>(false);
    const [fuckerChanged, setFuckerChanged] = useState<boolean>(false);

    useEffect(() => {
        if (change && formData !== undefined) {
            mergeDoc(`${computationType}/${updateId}/computation_inputs`, selectedInteraction, { fields: formData });
            setFuckerChanged(true);
        }
        setChange(false);
    }, [change, setChange]);

    useEffect(() => {
        const selectedInteractionData = interactionData.find(
            (interaction) => interaction.id === selectedInteraction
        );
        if (selectedInteractionData) {
            const dataFields = selectedInteractionData.fields;
            if (dataFields !== undefined && dataFields !== null) {
                setFormData(dataFields);
            }
        }
    }, [selectedInteraction, interactionData]);

    useEffect(() => {
        if (production && production.length > 0) {
            mergeDoc(computationType, updateId, {
                progress: {
                    2: {
                        done: true,
                        name: "Productie gegevens",
                    },
                },
            });
        }
    }, [production, pageReady, existingImage, updateId, loading]);

    useEffect(() => {
        if (formData && fuckerChanged) {
            console.log("We komen der wel")
            // Actual data
            setProductionInputType(
                formData["nANu1fal7jCXYmj7MrF6"] || ""
            );
            setCalculationId(
                formData["Dv65fxujlWFdz0faaQoX"] || ""
            );
            setProductionY1(
                formData["36PccuMw4zeDngIYMf78"] || ""
            );

            // Forecast data
            setProductionDataType(
                formData["ixxxzMYNGUUEiAxjgXFo"] || ""
            );
            setProgProductionInputType(
                formData["53KJu66ICMAnPr6ieWju"] || ""
            );
            setProgCalculationId(
                formData["w9ncJuJO8u59OG4k8yC3"] || ""
            );
            setProgProductionY1(
                formData["DbyxcVkZAzC8K2z8TsMG"] || ""
            );
            
            setFuckerChanged(false);
        }
    }, [fuckerChanged]);

    useEffect(() => {
        const selectedInteractionData = interactionData.find(
            (interaction) => interaction.id === selectedInteraction
        );
        if (selectedInteractionData && prodChanged) {
            // Actual production data
            setProduction(selectedInteractionData.production || []);
            setProdChanged(false);
        }

    }, [prodChanged]);

    useEffect(() => {
        const selectedInteractionData = interactionData.find(
            (interaction) => interaction.id === selectedInteraction
        );
        if (selectedInteractionData && progProdChanged) {
            // Actual production data
            setProgProduction(selectedInteractionData.prog_production || []);
            setProgProdChanged(false);
        }

    }, [progProdChanged]);

    useEffect(() => {

        const fetchProductionDataQuickr = async () => {
            if (calculationId && !loading) {
                try {
                    const response = await fetchQuickrJson(updateId, calculationId, existingExcel);
                    const productionData = response?.data?.length ? response.data : [];
                    await mergeDoc(`${computationType}/${updateId}/computation_inputs`, selectedInteraction, { production: productionData, computation_present: false });
    
                    if (!productionData.length) {
                        console.log(selectedInteraction, "wuth");
                        await mergeDoc(computationType, updateId, { progress: { 2: { done: false } } });
                        setError(true);
                    } else {
                        setProdChanged(true);
                    }
                } catch (err) {
                    console.error("Error fetching Quickr data:", err);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            }
        };
    
        const fetchProductionDataY1 = async () => {
            if (productionY1 > 0 && !loading) {
                try {
                    const response = await fetchProductionJson(updateId, productionY1, existingExcel);
                    const productionData = response?.data?.length ? response.data : [];
                    await mergeDoc(`${computationType}/${updateId}/computation_inputs`, selectedInteraction, { production: productionData, computation_present: false });
    
                    if (!productionData.length) {
                        await mergeDoc(computationType, updateId, { progress: { 2: { done: false } } });
                        setError(true);
                    } else {
                        setProdChanged(true);
                    }
                } catch (err) {
                    console.error("Error fetching Y1 data:", err);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            }
        };

        if (productionInputType === options.production_type_quickr) {
            fetchProductionDataQuickr();
        } else if (productionInputType === options.production_type_year_1) {
            fetchProductionDataY1();
        }

    }, [productionInputType, productionY1, calculationId]);

    useEffect(() => {

        const fetchProductionDataQuickr = async () => {
            if (progCalculationId && !loading) {
                try {
                    const response = await fetchQuickrJson(updateId, progCalculationId, existingExcel);
                    console.log(response?.data?.length);
    
                    const productionData = response?.data?.length ? response.data : [];
                    await mergeDoc(`${computationType}/${updateId}/computation_inputs`, selectedInteraction, { prog_production: productionData, computation_present: false });
                    
                    if (productionData.length > 0) {
                        setProgProdChanged(true);
                    }
                } catch (err) {
                    console.error("Error fetching Quickr data:", err);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            }
        };
    
        const fetchProductionDataY1 = async () => {
            if (progProductionY1 > 0 && !loading) {
                try {
                    const response = await fetchProductionJson(updateId, progProductionY1, existingExcel);
    
                    const productionData = response?.data?.length ? response.data : [];
                    await mergeDoc(`${computationType}/${updateId}/computation_inputs`, selectedInteraction, { prog_production: productionData, computation_present: false });

                    if (productionData.length > 0) {
                        setProgProdChanged(true);
                    }
                } catch (err) {
                    console.error("Error fetching Y1 data:", err);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            }
        };

        if (progProductionInputType === options.production_type_quickr) {
            fetchProductionDataQuickr();
        } else if (progProductionInputType === options.production_type_year_1) {
            fetchProductionDataY1();
        }

    }, [progProductionInputType, progProductionY1, progCalculationId]);

    return (
        <div style={{...styles.container, marginLeft: "20px"}}>
            {selectedInteraction !== "" && (
                <div>
                    <div
                        style={{
                            ...styles.rightPanelStyle,
                            marginLeft: "-10px",
                            minWidth: "300px",
                            maxHeight: "65vh",
                            minHeight: "30vh",
                        }}
                    >
                        <div style={{maxWidth: "70%"}}>
                        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
                            {t("energyProduction")}
                        </h3>
                        {formData !== undefined && template !== undefined && (
                            renderInputFields({
                                template: template,
                                formData: formData,
                                setFormData: setFormData,
                                setChange: setChange,
                                t: t,
                                filter: "energy_production",
                            })
                        )}
                        </div>
                        {productionDataType === "i0OJ1spyht6iylLtnUZS" && (
                        <div style={{maxWidth: "80%"}}>
                            <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
                                {t("energyProductionForecast")}
                            </h3>
                            {formData !== undefined && template !== undefined && (
                            renderInputFields({
                                template: template,
                                formData: formData,
                                setFormData: setFormData,
                                setChange: setChange,
                                t: t,
                                filter: "forecast_energy_production",
                            })
                            )}
                        </div>
                        )}
                    </div>
                    <div
                        style={{
                            marginTop: "10px",
                            marginLeft: "-10px",
                            minWidth: "500px",
                            maxWidth: "500px",
                        }}
                    >
                        <ExcelUpload 
                            path={`${computationType}/${updateId}/${selectedInteraction}/simul_data/production_data`}
                            existingExcel={existingExcel}
                            setExistingExcel={setExistingExcel} 
                        />
                    </div>
                </div>
            )}
            {productionInputType !== "RolPEHlwgPFcTxvRpXtX" && (
            <div
            style={{
                ...styles.rightPanelStyle,
                maxHeight: "87.5vh",
                minWidth: "300px",
                marginLeft: "10px",
            }}>
                <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
                    {t("actualGeneration")}{" "}
                    {/* Translation key for "Voorspelde productie" */}
                </h3>
                {production && Object.keys(production).length > 0 && (
                    <SimpleMaterialUITable data={production} />
                )}
            </div>
            )}
            {productionDataType === "i0OJ1spyht6iylLtnUZS" && progProduction && Object.keys(progProduction).length > 0 && (
            <div
            style={{
                ...styles.rightPanelStyle,
                maxHeight: "87.5vh",
                minWidth: "300px",
                marginLeft: "10px",
            }}>
                <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
                    {t("forecastGeneration")}{" "}
                    {/* Translation key for "Voorspelde productie" */}
                </h3>
                <SimpleMaterialUITable data={progProduction} />
            </div>
            )}
            
        </div>
    );
};

export default ProductionInput;
