import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchDocsData, fetchDocData } from "../../services/firebaseFunctions";

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

interface InteractionData {
	id: string;
	simultaneous?: {
		usage_data: any[];
		monthly_data: any[];
		generation_perc: number;
		percentage_full?: number;
		percentage_empty?: number;
		type?: string;
	};
	fields?: {
		[key: string]: any;
	};
	production?: any[];
}

interface TableItem {
    name: string;
    name_clean: string;
    value: number | string;
    formatted_value?: string;
} 

interface OutputTableProps {
    title: string;
    data: TableItem[];
    loading: boolean;
    suffix?: string; // Optional suffix
}

const getAllOptions = async () => {
	const options = await fetchDocsData("options");
	return options.map((option: any) => {
		return option; // Return each individual option object
	});
};

const formatOptionFields = (clientInputs: { [key: string]: any }, options: Option[]) => {
    if (Object.keys(clientInputs).length > 0 && options.length > 0) {
            const updatedFields = Object.entries(clientInputs).reduce(
                (acc: { [key: string]: any }, [fieldId, fieldValue]) => {
                    const matchingOption = options.find(
                        (option) => option.id === fieldValue
                    );
                    if (matchingOption) {
                        acc[fieldId] = matchingOption; 
                    } else {
                        acc[fieldId] = fieldValue; 
                    }

                    return acc;
                },
                {}
            );
            return updatedFields;
        }
    return clientInputs;
};

const formatClientInputs = async (data: any) => {
    const outputData: any = {}

    for (const key in data.fields) {
        if (data.fields.hasOwnProperty(key)) {
            const fieldValue = data.fields[key];
            try {
                const docData = await fetchDocData(`client_inputs`, key);
                if (docData) {
                    outputData[docData.name_clean] = fieldValue;
                } else {
                    console.log(`Document with key ${key} not found.`);
                }
            } catch (error) {
                console.error(`Error fetching document with key ${key}:`, error);
            }
        }
    }
    return outputData;
}

const getPrepPayloadIds = async (currentInteraction: InteractionData) => {
    const payloadIds: any = {};
    const requiredFields = ["consumption_input_type", "contracted_power", "production_data_type"];

    for (const key in currentInteraction.fields) {
        try {
            const docData = await fetchDocData(`client_inputs`, key);
            if (docData) {
                if (requiredFields.includes(docData.name_clean)) {
                    payloadIds[docData.name_clean] = key;
                }
            } else {
                console.log(`Document with key ${key} not found.`);
            }
        } catch (error) {
            console.error(`Error fetching document with key ${key}:`, error);
        } 
    }
    return payloadIds;
}

const getSimulPayloadIds = async (currentInteraction: InteractionData) => {
    const payloadIds: any = {};
    const requiredFields = ["battery_calculation_type", "battery_max_capacity", "battery_max_power", 
                            "current_connection", "required_connection", "period_peakusage_daily", 
                            "discharge_depth"];

    for (const key in currentInteraction.fields) {
        try {
            const docData = await fetchDocData(`client_inputs`, key);
            if (docData) {
                if (requiredFields.includes(docData.name_clean)) {
                    payloadIds[docData.name_clean] = key;
                }
            } else {
                console.log(`Document with key ${key} not found.`);
            }
        } catch (error) {
            console.error(`Error fetching document with key ${key}:`, error);
        } 
    }
    return payloadIds;
}

const OutputTable: React.FC<OutputTableProps> = ({ title, data, loading, suffix = "" }) => {
    return (
        <div style={{ marginTop: "20px", marginBottom: "5px", fontWeight: "bold", fontSize: "0.95rem" }}>
            {title}:
            {data && (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.name_clean}>
                                <td style={{ fontWeight: "normal", padding: "5px 0" }}>{item.name}:</td>
                                <td style={{ fontWeight: "normal", padding: "5px 0" }}>
                                    {loading ? (
                                        <CircularProgress size={15} />
                                    ) : (
                                        item.formatted_value !== undefined ? (
                                            item.formatted_value + " " + suffix
                                        ) : (
                                            typeof item.value === 'number' ? Math.round(item.value) + " " + suffix : item.value + " " + suffix
                                        )
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export { 
    getAllOptions,
    formatOptionFields, 
    formatClientInputs,
    getSimulPayloadIds,
    getPrepPayloadIds,
    OutputTable  
};