import React, { useEffect, useState } from "react";
import { Select, MenuItem, Button, TextField, SelectChangeEvent } from "@mui/material";
import { ProductVersion, Product_group, ProductTimes, ProductVersionFieldConfig } from "../types/ProductInterface";
import { mergeDoc, createDoc, removeDoc } from "../../../services/firebaseFunctions";
import { auth } from "../../../config/firebase";
import { match } from "assert";
import {formatDate} from "../config/tarifConfig"
import {postPortfolioData} from "../../../services/apiFunctions"

interface VersionDetailsProps {
    allProductVersions: ProductVersion[];
    selectedProduct: Product_group;
    selectedVersion: ProductVersion | null;
    setSelectedVersion: (value: ProductVersion | null) => void;
    setProductVersions: (versions: ProductVersion[]) => void;
    productVersions: ProductVersion[];
    allProductTimes: ProductTimes[];
    productVersionFieldConfigs: ProductVersionFieldConfig[];
}

const VersionDetails: React.FC<VersionDetailsProps> = ({
    allProductVersions,
    selectedProduct,
    selectedVersion,
    setSelectedVersion,
    setProductVersions,
    productVersions,
    allProductTimes,
    productVersionFieldConfigs,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedVersion, setEditedVersion] = useState<ProductVersion | null>(null);
    const [minErpSum, setMinErpSum] = useState<number | null>(null);
    const [minErsSum, setMinErsSum] = useState<number | null>(null);
    const [minTotSum, setMinTotSum] = useState<number | null>(null);
    const editableFields = productVersionFieldConfigs.filter((config) => !config.readonly);

    // Tariff values for calculations
    const tarifErp = 104;
    const tarifErs = 104;

    useEffect(() => {
        //console.log (productVersions)
    }, [productVersions]);

    const handleVersionSelect = (event: SelectChangeEvent<number>) => {
        const versionNumber = Number(event.target.value);
        const selected = productVersions.find((version) => version.version_number === versionNumber && version.product_number === selectedProduct.product_number) || null;

        if (selected) {
            const matchingProductTimes = allProductTimes.filter((time) => {
                console.log("Checking time:", time);
                console.log("Matching with version_number:", selected.product_group_version_number, "and prefix:", selected.prefix);
                return (
                    time.version_number === selected.product_group_version_number &&
                    time.prefix === selected.prefix
                );
            });
            
            console.log("Filterd Times", matchingProductTimes);
            console.log("Product number", selectedProduct.product_number);
            

            const minErp = matchingProductTimes.reduce((sum, time) => sum + (time.min_erp || 0), 0);
            const minErs = matchingProductTimes.reduce((sum, time) => sum + (time.min_ers || 0), 0);
            const minTot = matchingProductTimes.reduce(
                (sum, time) => sum + (time.min_erp || 0) + (time.min_ers || 0),
                0
            );
            console.log("Selected Version Details:", selected);
            console.log("Matching Prefix:", selected.prefix);
            console.log("Matching Version Number:", selected.product_group_version_number);
            console.log("All Product Times:", allProductTimes);


            setSelectedVersion(selected);
            console.log("Selected", selected);
            setMinErpSum(minErp);
            setMinErsSum(minErs);
            setMinTotSum(minTot);
        } else {
            setSelectedVersion(null);
            setMinErpSum(null);
            setMinErsSum(null);
            setMinTotSum(null);
        }
    };

    const kostprijsErp = minErpSum !== null ? (minErpSum * tarifErp) / 60 : 0;
    const kostprijsErs = minErsSum !== null ? (minErsSum * tarifErs) / 60 : 0;
    const kostprijs3e = selectedVersion?.cost_price_3rd || 0;
    const verkoopPrijs = selectedVersion?.price || 0;
    const marge = verkoopPrijs - kostprijsErp - kostprijsErs - kostprijs3e;
    const marge2 = marge / 2;
    const totPriceErp = kostprijsErp + marge2;

    const handleCreateVersion = async () => {
        if (!selectedProduct) {
            console.error("No product selected.");
            return;
        }
    
        // Filter versions for the selected product
        const filteredVersions = productVersions.filter(
            (version) =>
                version.product_number === selectedProduct.product_number &&
                version.prefix === selectedProduct.prefix
        );
    
        // Determine the highest version number
        const maxVersionNumber =
            filteredVersions.length > 0
                ? Math.max(...filteredVersions.map((version) => version.version_number))
                : 0;
    
        // Generate a new version number
        const newVersionNumber = maxVersionNumber + 1;
        const customId = `${selectedProduct.prefix}_${selectedProduct.product_number}.${newVersionNumber}`;
        const customPrNr = `${selectedProduct.product_number}.${newVersionNumber}`;
        console.log(`New version number: ${newVersionNumber}`);
    
        // Create a new version object
        const newVersion: Omit<ProductVersion, "id"> = {
            type: "",
            product_number: selectedProduct.product_number,
            version_number: newVersionNumber,
            prefix: selectedProduct.prefix,
            cost_price_3rd: null,
            created_by: auth.currentUser?.displayName || "Unknown User",
            created_on: formatDate(new Date()),
            description_if_required: "",
            modified_by: auth.currentUser?.displayName || "Unknown User",
            modified_on: formatDate(new Date()),
            owner: auth.currentUser?.displayName || "Unknown User",
            price: 0,
            product_group_version_number: customPrNr,
        };
    
        const apiPayload = {
            ...newVersion,
            rowkey: customId,
            collection: "product_version",
            action: "created",
        };
    
        try {
            // Save the new version to the database
            const createdDocRef = await createDoc("product_version", newVersion, customId);
    
            if (!createdDocRef) {
                throw new Error("Failed to create document.");
            }
    
            // Add the new version to the state
            const versionWithId = { ...newVersion, id: customId };
            setProductVersions([...productVersions, versionWithId]);
            setSelectedVersion(versionWithId);
            setEditedVersion(versionWithId);
            setIsEditing(true);
    
            console.log("New version created successfully:", versionWithId);
        } catch (error) {
            console.error("Failed to create a new version:", error);
        }
    };
    

    const handleDeleteVersion = async () => {
        if (selectedVersion) {
            const confirmDelete = window.confirm("Are you sure you want to delete this version?");
            if (confirmDelete) {
                await removeDoc("product_version", selectedVersion.id);
                const apiPayload = {...selectedVersion, rowkey: selectedVersion.id, collection: "product_version", action: "deleted"};
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
                setProductVersions(productVersions.filter((version) => version.id !== selectedVersion.id));
                setSelectedVersion(null);
                setIsEditing(false);
            }
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
        setEditedVersion(selectedVersion);
    };

    const handleSaveVersion = async () => {
        if (editedVersion) {
            console.log("Saving edited product Version:", editedVersion);

            // Add `modified_on` and `modified_by` fields
            const { id, ...versionDataWithoutId } = editedVersion;
            const updatedVersion = {
                ...versionDataWithoutId,
                modified_on: formatDate(new Date()), // Current date and time
                modified_by: auth.currentUser?.displayName || "Unknown User", // Current user
            };

            const apiPayload = {...updatedVersion, rowkey: id, collection: "product_version", action: "updated"};
    
            try {
                // Save the updated version to Firestore
                await mergeDoc("product_version", id, updatedVersion);
    
                // Send updated product Version data to the API
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);


                // Update local state
                setSelectedVersion({ ...editedVersion, ...updatedVersion });
                setIsEditing(false); // Exit edit mode
    
                console.log("Version saved successfully:", updatedVersion);
            } catch (error) {
                console.error("Failed to save version:", error);
            }
        } else {
            console.error("No version selected to save.");
        }
    };
    


        return (
        <div>
            

            {/* Dropdown */}
            <Select
                value={selectedVersion?.version_number || ""}
                onChange={handleVersionSelect} // Correctly typed event handler
                displayEmpty
                fullWidth
                style={{ marginBottom: "20px" }}
            >
                <MenuItem value="" disabled>
                    Select a Version
                </MenuItem>
                {allProductVersions
                    .filter(
                        (version) =>
                            version.product_number === selectedProduct.product_number &&
                            version.prefix === selectedProduct.prefix
                    )
                    .map((version) => (
                        <MenuItem key={version.id} value={version.version_number}>
                            # {version.version_number}: {version.type}
                        </MenuItem>
                    ))}
            </Select>

            {/* Display Box */}
            <div style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "20px", display: "flex" }}>
                <div style={{ flex: "1", padding: "10px" }}>
                    <p><strong>min ERP:</strong> {minErpSum !== null ? minErpSum : "N/A"}</p>
                    <p><strong>min ERS:</strong> {minErsSum !== null ? minErsSum : "N/A"}</p>
                    <p><strong>totaal min:</strong> {minTotSum !== null ? minTotSum : "N/A"}</p>
                    <p><strong>Verkoop prijs:</strong> € {verkoopPrijs.toFixed(2)}</p>
                </div>
                <div style={{ flex: "1", padding: "10px" }}>
                    <p><strong>Kostprijs ERP:</strong> € {kostprijsErp.toFixed(2)}</p>
                    <p><strong>Kostprijs ERS:</strong> € {kostprijsErs.toFixed(2)}</p>
                    <p><strong>Kostprijs 3e:</strong> € {kostprijs3e.toFixed(2)}</p>
                    <p><strong>Marge:</strong> € {marge.toFixed(2)}</p>
                </div>
            </div>

            {/* Buttons */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <Button variant="contained" color="secondary" onClick={handleCreateVersion}>
                    Create New
                </Button>
                <Button variant="outlined" color="error" onClick={handleDeleteVersion} disabled={!selectedVersion}>
                    Delete
                </Button>
                <Button variant="outlined" color="primary" onClick={handleEditToggle} disabled={!selectedVersion}>
                    {isEditing ? "Cancel Edit" : "Edit"}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSaveVersion} disabled={!isEditing || !editedVersion}>
                    Save
                </Button>
            </div>
             {/* Selected Version Details */}
             {selectedProduct && selectedVersion && !isEditing && (
                <div>
                    {editableFields.map((config) => (
                        <p key={config.key} style={{ margin: "5px 0" }}>
                            <strong>{config.label}:</strong> {selectedVersion[config.key] ?? "N/A"}
                        </p>
                    ))}
                </div>
            )}

            {/* Edit Mode */}
            {isEditing && editedVersion && (
                <div>
                    {editableFields.map((config) => (
                        <TextField
                            key={config.key}
                            label={config.label}
                            type={config.type}
                            value={editedVersion[config.key] ?? ""}
                            onChange={(e) =>
                                setEditedVersion({
                                    ...editedVersion,
                                    [config.key]: config.type === "number" ? parseFloat(e.target.value) : e.target.value,
                                })
                            }
                            fullWidth
                            margin="normal"
                            disabled={config.readonly}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default VersionDetails;

