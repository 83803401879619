import {
	mergeDoc,
	createDoc,
	fetchDocsData,
	fetchDocData,
} from "../../services/firebaseFunctions";
import {auth} from "../../config/firebase";
import { info } from "console";
import { initial } from "lodash";

const fetchComputation = async (
	clientId: string,
	computationType: string,
	setUpdateId: (updateId: string) => void,
	setComputationData: (computationData: Record<string, any>) => void,
	setShowScreens: (showScreens: boolean) => void
) => {
	const computation = await fetchDocData(computationType, clientId);
	const computationData2 = await fetchDocData(
		`${computationType}/${clientId}/general_inputs`,
		"all"
	);

	if (
		Object.keys(computation).length === 0 &&
		Object.keys(computationData2).length === 0
	) {
		setUpdateId("");
		setComputationData({});
		setShowScreens(false);
	} else if (!(Object.keys(computationData2).length > 0)) {
		setUpdateId(clientId || "");
		setComputationData({});
		setShowScreens(true);
	} else {
		setUpdateId(clientId === null ? "" : clientId);
		if (computationData2?.fields) {
			setComputationData(computationData2?.fields);
			setShowScreens(true);
		} else {
			setComputationData({});
			setShowScreens(true);
		}
	}
};

const updateFilteredSteps = (
	progress: any,
	steps: string[],
	showScreens: boolean,
	updateId: string,
	setFilteredSteps: (filteredSteps: []) => void
) => {
	const filteredSteps: any = steps.filter((step, index) => {
		const allPreviousStepsDone = Array.from({length: index}).every((_, i) => {
			return progress?.[i.toString()]?.done;
		});
		if (index > 0) {
			return allPreviousStepsDone;
		}
		if (showScreens && updateId && index === 0) {
			return true;
		}
		return false;
	});
	if (updateId === "") {
		setFilteredSteps([]);
		return;
	}
	setFilteredSteps(filteredSteps);
};

const isValidDataFormat = (data: any): boolean => {
	return (
		typeof data === 'object' &&
		data !== null &&
		'data_files' in data &&
		Array.isArray(data.data_files) &&
		data.data_files.every((file: { id: string; data_present: boolean; data_processed: boolean }) =>
			typeof file === 'object' &&
			'id' in file &&
			'data_present' in file &&
			'data_processed' in file &&
			typeof file.id === 'string' &&
			typeof file.data_present === 'boolean' &&
			typeof file.data_processed === 'boolean'
		) &&
		'name' in data &&
		typeof data.name === 'string' &&
		'name_en' in data &&
		typeof data.name_en === 'string' &&
		'rank' in data &&
		typeof data.rank === 'number' &&
		'all_fields' in data &&
		typeof data.all_fields === 'object' &&
		'info_fields' in data &&
		typeof data.info_fields === 'object' &&
		'data_fields' in data &&
		typeof data.data_fields === 'object'
	);
};

const addDocumentGeneralComputation = async (updateId: string, computationType: string) => {

	const initialData = {	
		all_fields: {},
		info_fields: {},
		data_fields: {},
		data_files: [
			{"id": "usage_data", "data_present": false, "data_processed": false},
			{"id": "production_data", "data_present": false, "data_processed": false},
			{"id": "placeholder_data", "data_present": false, "data_processed": false},
		],
		name: "Alle data",
		rank: 0,
		name_en: "All data",
	};
	const checkExistingData = await fetchDocsData(`${computationType}/${updateId}/computation_inputs`);
	if (checkExistingData.length > 0 && isValidDataFormat(checkExistingData[0])) {
		return checkExistingData[0]
	} else {
		try {
			await mergeDoc(`${computationType}`, updateId, { created_by: auth.currentUser?.uid });
			await mergeDoc(`${computationType}/${updateId}/computation_inputs`, "all", initialData);
			return initialData;
		} catch (error) {
			console.error("Error in addDocumentGeneralComputation:", error);
			throw error; // Rethrow so the caller knows an error occurred
		}
	}
};

const addDocumentComputation = async (
	updateId: string,
	computationType: string
): Promise<void> => {
	await mergeDoc(`${computationType}`, updateId, {
		created_by: auth.currentUser?.uid,
	});
	await mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
		name: "Algemene berekening",
		name_en: "General computation",
	});
	const listRooms = await fetchDocsData(`${computationType}/${updateId}/rooms`);
	const allRoom = listRooms.filter((room: any) => room.name_clean === "all");
	const interactionDataUpdate = await fetchDocsData(
		`${computationType}/${updateId}/computation_inputs`
	);
	if (allRoom.length > 0 && interactionDataUpdate.length === 0) {
		await mergeDoc(
			`${computationType}/${updateId}/computation_inputs`,
			allRoom[0].id,
			{
				fields: {},
				name: "Algemene berekening",
				rooms: [],
				rank: 0,
				name_en: "General computation",
			}
		);
	} else if (allRoom.length === 0) {
		const roomIdUpdate = await createDoc(
			`${computationType}/${updateId}/rooms`,
			{name: "Alle ruimten", name_clean: "all"}
		);
		if (roomIdUpdate) {
			await mergeDoc(
				`${computationType}/${updateId}/computation_inputs`,
				roomIdUpdate.id,
				{
					fields: {},
					name: "Algemene berekening",
					name_en: "General computation",
					rooms: [],
					rank: 0,
				}
			);
		} else {
			console.error("Failed to create room ID");
		}
	}
};

async function fetchInputFieldsData(
	loadedClientInputs: Record<string, any>,
	inputIds: string[], 
	inputCategories: any[]
): Promise<any> {
	const templateData: Record<string, any> = {};
	// const loadedClientInputs: Record<string, any> = {}; // Simulating a cache for already loaded client inputs
	const updatedLoadedClientInputs: Record<string, any> = {
		...loadedClientInputs,
	};
	// Iterate over each inputId in the inputIds list
	for (const inputId of inputIds) {
		// Check if inputId is already loaded, if not, fetch data
		if (!Object.keys(loadedClientInputs).includes(inputId)) {
			// Fetch the document data for this inputId
			const data = await fetchDocData("client_inputs", inputId);

			// Check if the fetched data contains options, and handle options fetching
			if (data?.options?.length > 0) {
				const updatedOptions = await Promise.all(
					data.options.map(async (option: string) => {
						if (option) {
							// Fetch option data and append its id to the result
							const optionData = await fetchDocData("options", option);
							return {...optionData, id: option};
						}
						return option;
					})
				);
				data.options = updatedOptions;
			}
			else {
				data.options = [];
			}
			if (data?.input_categories?.length > 0) {
				const updatedOptions = await Promise.all(
					data.input_categories.map(async (input_category: string) => {
						if (input_category) {
							// Fetch option data and append its id to the result
							const optionData = inputCategories.find((item: any) => item.id === input_category);
							return {...optionData, id: input_category};
						}
						return input_category;
					})
				);
				data.input_categories = updatedOptions;
			}
			// If the data object contains any data, update the templateData and cache it
			if (Object.keys(data).length > 0) {
				templateData[inputId] = data;
				updatedLoadedClientInputs[inputId] = data; // Optionally update the loaded cache
			}
		} else {
			// If inputId is already loaded, use the cached data
			templateData[inputId] = loadedClientInputs[inputId];
		}
	}

	// Return the final template data
	return templateData;
}

export {
	addDocumentComputation,
	addDocumentGeneralComputation,
	fetchComputation,
	updateFilteredSteps,
	fetchInputFieldsData,
};
