import React, {useState, useEffect} from "react";
import { mergeDoc } from "../../services/firebaseFunctions";
import GeneralProjectInput from "../../components/general-project-input/GeneralProjectInput";
import {useTranslation} from "react-i18next";
import GeneralInputPipedrive from "../../components/general-input-pipedrive/GeneralInputPipedrive";

interface FieldInfo {
	input_categories: string[];
	data_type: string;
}

interface TemplateUnfiltered {
	fields: Record<string, FieldInfo>;
}

interface Template {
	[key: string]: any;
}

interface Props {
	updateId: string;
	crmDetails: any;
	computationData: any;
	generalData: any[];
	setComputationData: React.Dispatch<React.SetStateAction<any>>;
	roomId: string;
	setRoomId: React.Dispatch<React.SetStateAction<string>>;
	pageReady: boolean;
	computationType: string;
	templateInitialize: TemplateUnfiltered;
    template: Template;
}

function areAllFieldsFilled(template: TemplateUnfiltered,data: Record<string, string>): boolean {
	const requiredCategories = ["BAT gegevens"];
	if (template?.fields === undefined) {
		return false;
	}
	const requiredFields = Object.keys(template.fields).reduce(
		(filteredRecords, key) => {
			const record = template.fields[key];
			if (
				Array.isArray(record.input_categories) &&
				record.input_categories.some((category) =>
					requiredCategories.includes(category)
				)
			) {
				filteredRecords[key] = record;
			}
			return filteredRecords;
		},
		{} as Record<string, FieldInfo>
	);
	if (requiredFields === undefined) {
		return false;
	}
	return Object.keys(requiredFields).every((field) => {
		// Check if the field exists in data and it's not empty
		return data?.[field] && data[field] !== "";
	});
}

const BatteryInitialize: React.FC<Props> = ({
	updateId,
	crmDetails,
	computationData,
	generalData,
	setComputationData,
	roomId,
	setRoomId,
	pageReady,
	computationType,
	templateInitialize,
    template
}) => {
	const [formData, setFormData] = useState<any>(generalData.length > 0 ? generalData[0]?.fields : {});
	const [change, setChange] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const {t} = useTranslation();

	useEffect(() => {
		if (computationData) {
			let status = loading;
			if (generalData.length > 0 && Object.keys(templateInitialize.fields).length > 0) {
				if (status === false) {
					status = areAllFieldsFilled(templateInitialize, generalData[0]?.fields);
				}
				if (!(status === pageReady)) {
					mergeDoc(computationType, updateId, {
						progress: {
							0: {
								done: status,
								name: "General information",
							},
						},
						approved: false,
					});
				}
			}
		}
	}, [templateInitialize,
		pageReady,
		generalData,
		updateId,
		computationType,
		computationData,
		loading,
	]);

	useEffect(() => {
		const handleChange = async () => {
			if (change === true && Object.keys(formData).length > 0) {
				await mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {fields: formData, report_ready: false});
				setChange(false);
			}
		};
		handleChange();
	}, [change, formData, computationType, updateId]);


	return (
		<div>
			{updateId && updateId !== undefined && (
				<div
					style={{
						overflowY: "auto",
						marginLeft: "10px",
						display: "flex",
						
					}}
				>
					{template &&
						<GeneralInputPipedrive
							updateId={updateId}
							crmDetails={crmDetails}
							computationType={computationType}
							generalData={generalData}
							formData={computationData}
							setFormData={setComputationData}
							roomId={roomId}
							setRoomId={setRoomId}
							template={template}
						/>
					}

                    <div
						style={{
							// flex: 1,
							maxHeight: "80vh",
							textAlign: "left",
							display: "flex",
							flexDirection: "column",
							marginLeft: "10px",
						}}
					>
						<GeneralProjectInput
							updateId={updateId}
							formData={computationData}
							setFormData={setComputationData}
							generalData={generalData}
							roomId={roomId}
							setRoomId={setRoomId}
							template={templateInitialize.fields}
							computationType={computationType}
							filter={"data_project"}
						/>
					</div>
					<div
						style={{
							// flex: 1,
							maxHeight: "80vh",
							maxWidth: "450px",
							textAlign: "left",
							display: "flex",
							flexDirection: "column",
							marginLeft: "10px",
						}}
					>
						<GeneralProjectInput
							updateId={updateId}
							formData={computationData}
							setFormData={setComputationData}
							generalData={generalData}
							roomId={roomId}
							setRoomId={setRoomId}
							template={templateInitialize.fields}
							computationType={computationType}
							filter={"client_data"}
							title="Gegevens klant"
						/>
					</div>
					{/* <div> 
						<h3 style={{fontSize: "1.5rem", fontFamily: "Basic", marginLeft: "10px", overflowY: "hidden", minWidth:"250px"}} >
							Product still in development, please check back later.
						</h3>
					</div> */}
				</div>
			)}
		</div>
	);
};

export default BatteryInitialize;
