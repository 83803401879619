import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {styles} from "../../components/utils/Styles";
import { BatteryRecord, QuarterlyGraphData, DayData } from "../../layout/solar-battery/batteryInterfaces";
import LineChart from "../../components/plots/LineChart";
import LineChartday from "../../components/plots/LineChartDay";

interface QuarterlyGraphsProps {
    quarterlyGraphData: QuarterlyGraphData;
}

const QuarterlyGraphs: React.FC<QuarterlyGraphsProps> = ({ quarterlyGraphData }) => {
	const [monthlyData, setMonthlyData] = useState<BatteryRecord[]>(quarterlyGraphData.monthly_percentages);
	const [collapseMontlyData, setCollapseMontlyData] = useState(false);
    const [dayData, setDayData] = useState<DayData>(quarterlyGraphData.daily_data.day_data);
    const [collapseDayData, setCollapseDayData] = useState(false);
    const [weekendData, setWeekendData] = useState<DayData>(quarterlyGraphData.daily_data.weekend_data);
    const [collapseWeekendData, setCollapseWeekendData] = useState(false);
    const { t } = useTranslation();

return (
    <div>
        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
            {t('MonthlyGraph')} 
            <button 
                style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
                onClick={() => setCollapseMontlyData(!collapseMontlyData)} >
                Toggle graph
            </button>
        </h3>
        {monthlyData && !collapseMontlyData && (
            <div style={{
                ...styles.rightPanelStyle,
                maxWidth: "500px",
                marginTop: "10px",
                marginLeft: "-10px",
                }}
            >
                <LineChart
                    data={monthlyData}
                />
            </div>
        )}
        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
            {t('DailyAverageGraph')} 
            <button 
                style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
                onClick={() => setCollapseDayData(!collapseDayData)} >
                Toggle graph
            </button>
        </h3>
        {dayData && !collapseDayData && (
            <div style={{
                ...styles.rightPanelStyle,
                maxWidth: "500px",
                marginTop: "10px",
                marginLeft: "-10px",
                }}
            >
                <LineChartday
                    jan={dayData.jan}
                    feb={dayData.feb}
                    mrt={dayData.mrt}
                    apr={dayData.apr}
                    mei={dayData.mei}
                    jun={dayData.jun}
                    jul={dayData.jul}
                    aug={dayData.aug}
                    sep={dayData.sep}
                    okt={dayData.okt}
                    nov={dayData.nov}
                    dec={dayData.dec}
                />
            </div>
        )}
        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
            {t('DailyAverageWeekendGraph')} 
            <button 
                style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
                onClick={() => setCollapseWeekendData(!collapseWeekendData)} >
                Toggle graph
            </button>
        </h3>
        {weekendData && !collapseWeekendData && (
            <div style={{
                ...styles.rightPanelStyle,
                maxWidth: "500px",
                marginTop: "10px",
                marginLeft: "-10px",
                }}
            >
                <LineChartday
                    jan={weekendData.jan}
                    feb={weekendData.feb}
                    mrt={weekendData.mrt}
                    apr={weekendData.apr}
                    mei={weekendData.mei}
                    jun={weekendData.jun}
                    jul={weekendData.jul}
                    aug={weekendData.aug}
                    sep={weekendData.sep}
                    okt={weekendData.okt}
                    nov={weekendData.nov}
                    dec={weekendData.dec}
                />
            </div>
        )}
    </div>
)
}

export default QuarterlyGraphs;