import React, {useState, useEffect} from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Grid,
	MenuItem,
	Select,
} from "@mui/material";
import {SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next"; // Import useTranslation hook
import {styles} from "../utils/Styles";
import {DisplayData, FinancialData} from "../../types"; 

// FinancialElement component
const FinancialElement: React.FC<{
	title: string;
	value: string | number;
}> = ({title, value}) => (
	<Grid
		item
		xs={12}
		sm={6}
		md={3}
		lg={3}
		style={{
			marginBottom: "0px",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		}}
	>
		<div>
			<Typography
				className="label-lower"
				variant="body1"
				style={{fontSize: "12px"}}
			>
				{title}
			</Typography>
		</div>
		<div>
			<Typography
				className="label-lower"
				variant="body1"
				style={{textAlign: "right", fontSize: "12px"}}
			>
				{value}
			</Typography>
		</div>
	</Grid>
);

// FinancialOverview component
const FinancialOverview: React.FC<{
	displayData: DisplayData;
	computationType: string;
}> = ({displayData, computationType}) => {
	const {t} = useTranslation(); // Hook to get translation function
	const financial: FinancialData = displayData.financial;
	const income: FinancialData = displayData.income;
	const costs: FinancialData = displayData.costs;
	const comparisonData: DisplayData = displayData;
	const [yearHeaders, setYearHeaders] = useState<string[]>([]);
	const [maxLength, setMaxLength] = useState(0);
	const [selectedYears, setSelectedYears] = useState<string[]>([]);

	useEffect(() => {
		if (
			computationType === "solar_energy_computations" ||
			computationType === "solar_rental_computations"
		) {
			const lastIndex = costs.onetime.length - 1;
			setSelectedYears(["0", "1", "5", "12", lastIndex.toString()]);
			if (Array.isArray(costs.onetime)) {
				setMaxLength(costs.onetime.length);
				setYearHeaders(generateYearHeaders(costs.onetime.length));
			}
		} else if (computationType === "energy_saving_computations") {
			setSelectedYears(["0", "1", "2", "3", "4"]);
			if (Array.isArray(costs.one_time_costs)) {
				setMaxLength(costs.one_time_costs.length);
				setYearHeaders(generateYearHeaders(costs.one_time_costs.length));
			}
		} else if (computationType === "solar_battery_computations") {
			const lastIndex = comparisonData.totals.profit.length - 1;
			setSelectedYears(["0", "1", "5", "10", lastIndex.toString()]);
			if (Array.isArray(comparisonData.totals.profit)){
				setMaxLength(comparisonData.totals.profit.length);
				setYearHeaders(generateYearHeaders(comparisonData.totals.profit.length));
			}
		}
	}, [computationType, displayData, costs]);

	const generateYearHeaders = (length: number): string[] => {
		return Array.from({length}, (_, index) => `${index}`);
	};

	const generateYearKeys = (
		array: string[] | undefined,
		length: number
	): string[] => {
		if (!Array.isArray(array) || array.length === 0) {
			return Array(length).fill("€0");
		}

		return array.map((value) => {
			const roundedValue: number | any = parseFloat(value).toFixed(0);
			const formattedValue = new Intl.NumberFormat("nl-NL").format(
				Math.abs(roundedValue)
			);
			return roundedValue >= 0 ? `€${formattedValue}` : `€(${formattedValue})`;
		});
	};

	// Financial data
	let financialData: FinancialData = {};
	let costDetails: FinancialData = {};
	let incomeDetails: FinancialData = {};
	let financialMetrics: FinancialData = {};
	if (
		computationType === "solar_energy_computations" ||
		computationType === "solar_rental_computations"
	) {
		financialData = {
			[t("panelCount")]: comparisonData.pv_count,
			[t("equityPercentage")]: comparisonData.perc_ev * 100 + "%",
			[t("paybackPeriod")]: Math.round(comparisonData.payback_period) + " jaar",
			[t("irr")]: `${(Math.round(comparisonData.irr * 100 * 100) / 100).toFixed(
				2
			)} %`,
			[t("rev")]: `${(
				Math.round(comparisonData.irr_ev * 100 * 100) / 100
			).toFixed(2)} %`,
			[t("npv")]: `€${Math.round(comparisonData.npv)}`,
			[t("totalInvestment")]: `€${Math.round(comparisonData.invest_tot)}`,
			[t("investmentPerWp")]: `€${
				Math.round(comparisonData.investment_per_wp * 1000) / 1000
			}`,
		};
		costDetails = {
			[t("oneTimeCosts")]: generateYearKeys(costs.onetime, maxLength),
			[t("operationalCosts")]: generateYearKeys(costs.yearly, maxLength),
			[t("total")]: generateYearKeys(costs.total, maxLength),
		};

		incomeDetails = {
			[t("directSavings")]: generateYearKeys(income.direct_usage, maxLength),
			[t("returnDelivery")]: generateYearKeys(income.net, maxLength),
			[t("gvo")]: generateYearKeys(income.gvo, maxLength),
			[t("salderen")]: generateYearKeys(income.saldation, maxLength),
			[t("privateWire")]: generateYearKeys(income.privatewire, maxLength),
			[t("total")]: generateYearKeys(income.total, maxLength),
		};
		financialMetrics = {
			[t("revenue")]: generateYearKeys(financial.revenue, maxLength),
			[t("depreciation")]: generateYearKeys(financial.depreciation, maxLength),
			[t("ebit")]: generateYearKeys(financial.ebit, maxLength),
			[t("interest")]: generateYearKeys(financial.interest, maxLength),
			[t("profitBeforeTax")]: generateYearKeys(financial.pbt, maxLength),
			[t("tax")]: generateYearKeys(financial.tax, maxLength),
			[t("profitAfterTax")]: generateYearKeys(financial.pat, maxLength),
		};

		if (isSumZero(income.saldation)) {
			delete incomeDetails[t("salderen")];
		}
		if (isSumZero(income.privatewire)) {
			delete incomeDetails[t("privateWire")];
		}
		if (isSumZero(income.direct_usage)) {
			delete incomeDetails[t("directSavings")];
		}
		if (isSumZero(income.net)) {
			delete incomeDetails[t("returnDelivery")];
		}
		if (isSumZero(income.gvo)) {
			delete incomeDetails[t("gvo")];
		}
		if (isSumZero(income.total)) {
			delete incomeDetails[t("total")];
		}
	} else if (computationType === "energy_saving_computations") {
		financialData = {
			[t("investment")]: "€" + -comparisonData.investment,
			[t("calculationTerm")]: +comparisonData.term + " jaar",
			[t("paybackPeriod")]: Math.round(comparisonData.payback_period) + " jaar",
			[t("irr")]:
				Math.round(comparisonData.internal_rate_return * 100) / 100 + " %",
			[t("npv")]: "€" + Math.round(comparisonData.net_present_value),
			[t("co2Savings")]: `${Math.round(
				comparisonData.reductions?.co2
					.slice(1)
					.reduce((acc: number, val: number) => acc + val, 0) /
					(comparisonData.reductions?.co2.length || 1)
			)} kg/jaar`,
			[t("gasSavings")]: `${Math.round(
				comparisonData.reductions?.gas
					.slice(1)
					.reduce((acc: number, val: number) => acc + val, 0) /
					(comparisonData.reductions?.co2.length || 1)
			)} m3/jaar`,
			[t("electricitySavings")]: `${Math.round(
				comparisonData.reductions?.electricity
					.slice(1)
					.reduce((acc: number, val: number) => acc + val, 0) /
					(comparisonData.reductions?.co2.length || 1)
			)} kWh/jaar`,
		};
		costDetails = {
			[t("oneTimeCosts")]: generateYearKeys(costs.one_time_costs, maxLength),
			[t("operationalCosts")]: generateYearKeys(
				costs.recurring_costs,
				maxLength
			),
			[t("reinvestmentCosts")]: generateYearKeys(
				costs.reinvestment_costs,
				maxLength
			),
			[t("total")]: generateYearKeys(costs.total_costs, maxLength),
		};
		incomeDetails = {
			[t("gasSavings")]: generateYearKeys(income.gas, maxLength),
			[t("electricitySavings")]: generateYearKeys(
				income.electricity,
				maxLength
			),
			[t("costSavings")]: generateYearKeys(income.costs, maxLength),
			[t("subsidy")]: generateYearKeys(income.subsidies, maxLength),
			[t("total")]: generateYearKeys(income.total_savings, maxLength),
		};
		financialMetrics = {
			[t("revenue")]: generateYearKeys(financial.revenue, maxLength),
			[t("cashflowInvestment")]: generateYearKeys(
				financial.cashflow_investment,
				maxLength
			),
			[t("netCashflow")]: generateYearKeys(financial.net_cashflow, maxLength),
		};
	}
	else if (computationType === "solar_battery_computations"){
		financialData = {
			[t("paybackPeriod")]: comparisonData.financial.tvt + " jaar",
			[t("irr")]: comparisonData.financial.irr,
			[t("npv")]: comparisonData.financial.npv,
		};
		costDetails = {
			[t("costsInvestmentBattery")]: generateYearKeys(comparisonData.costs.investment_battery, maxLength),
			[t("costsEIA")]: generateYearKeys(comparisonData.costs.EIA, maxLength),
			[t("costsInvestment")]: generateYearKeys(comparisonData.costs.investment_costs, maxLength),
			...(comparisonData.costs.investment_grid_reinforcement && {
				[t("costsGridReinforcement")]: generateYearKeys(comparisonData.costs.investment_grid_reinforcement, maxLength),
			  }),
			[t("costsMaintenance")]: generateYearKeys(comparisonData.costs.maintenance, maxLength),
			...(comparisonData.costs.extra_yearly_standing_costs && {
				[t("costsStanding")]: generateYearKeys(comparisonData.costs.extra_yearly_standing_costs, maxLength),
			  }),
		};
		incomeDetails = {
			[t("savingsStorage")]: generateYearKeys(comparisonData.savings.storage_savings, maxLength),
			[t("savingsEnergyTrading")]: generateYearKeys(comparisonData.savings.energy_trading, maxLength),
		};
		financialMetrics = {
			[t("totalsSavings")]: generateYearKeys(comparisonData.totals.savings, maxLength),
			[t("totalsCosts")]: generateYearKeys(comparisonData.totals.costs, maxLength),
			[t("totalsProfit")]: generateYearKeys(comparisonData.totals.profit, maxLength),
		}

	}

	function sum(arr: number[] | undefined): number {
		return Array.isArray(arr) ? arr.reduce((acc, val) => acc + val, 0) : 0;
	}

	function isSumZero(arr: number[] | undefined): boolean {
		return sum(arr) === 0;
	}

	const renderTable = (data: FinancialData): JSX.Element => {
		const filteredData: FinancialData = {};
		Object.keys(data).forEach((key) => {
			const filteredValues = selectedYears.map((year) => data[key][year]);
			filteredData[key] = filteredValues;
		});
		const rows = Object.keys(filteredData);

		return (
			<TableContainer
				component={Paper}
				style={{marginBottom: "10px", marginTop: "-30px", padding: "0px"}}
			>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell />
							{selectedYears
								.sort((a, b) => parseInt(a) - parseInt(b))
								.map((year) => (
									<TableCell
										className="label-lower"
										key={year}
										align="right"
										style={{
											maxHeight: "4px",
											// ...styles.labelLower,
											fontSize: "12px",
										}}
									>
										{year}
									</TableCell>
								))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((rowTitle) => (
							<TableRow
								className="label-lower"
								key={rowTitle}
								style={{
									maxHeight: "4px",
									// ...styles.labelLower,
									fontSize: "12px",
								}}
							>
								<TableCell
									className="label-lower"
									style={{
										maxHeight: "4px",
										fontSize: "12px",
										minWidth: "200px",
										padding: "1px",
										fontWeight: [
											"totaal",
											"total",
											"profit after tax",
											"winst na belasting",
										].includes(rowTitle.toLowerCase())
											? "bold"
											: "normal",
									}}
								>
									{rowTitle}
								</TableCell>
								{selectedYears
									.sort((a, b) => parseInt(a) - parseInt(b))
									.map((year) => (
										<TableCell
											className="label-lower"
											key={year}
											align="right"
											style={{
												maxHeight: "4px",
												fontSize: "12px",
												fontWeight: ["totaal", "winst na belasting"].includes(
													rowTitle.toLowerCase()
												)
													? "bold"
													: "normal",
											}}
										>
											{data[rowTitle]?.[year] || "-"}
										</TableCell>
									))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	const handleChange = (
		event: SelectChangeEvent<string[]>,
		child: React.ReactNode
	) => {
		setSelectedYears(event.target.value as string[]);
	};

	return (
		<div style={{display: "flex", marginLeft: "10px", maxHeight: "90vh"}}>
			<div
				className="right-panel"
				style={{
					maxWidth: "26vw",
				}}
			>
				<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>{t("summary")}</h3>
				<div>
					{Object.entries(financialData).map(([title, value]) => (
						<FinancialElement key={title} title={t(title)} value={value} />
					))}
				</div>
			</div>

			<div style={{flex: 1, marginLeft: "10px", minHeight: "90vh"}}>
				<div
					style={{
						...styles.rightPanelStyle,
						minWidth: "600px",
						maxWidth: "800px",
						minHeight: "86vh",
						position: "relative",
					}}
				>
					<Select
						multiple
						value={selectedYears}
						onChange={handleChange}
						renderValue={(selected) => selected.join(", ")}
						style={{
							maxHeight: "20px",
							fontFamily: "basic",
							fontSize: "12px",
							position: "absolute",
							top: 10,
							right: 30,
						}}
					>
						{yearHeaders.map((year) => (
							<MenuItem
								key={year}
								value={year}
								style={{
									minHeight: "10px",
									lineHeight: "15px",
									padding: "0 10px",
									fontFamily: "basic",
									fontSize: "14px",
								}}
							>
								{year}
							</MenuItem>
						))}
					</Select>
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Barlow",
							marginTop: "0px",
						}}
					>
						{t("income")}
					</h6>
					{renderTable(incomeDetails)}
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Barlow",
							marginTop: "0px",
						}}
					>
						{t("costs")}
					</h6>
					{renderTable(costDetails)}
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Barlow",
							marginTop: "0px",
						}}
					>
						{t("financialData")}
					</h6>
					{renderTable(financialMetrics)}
				</div>
			</div>
		</div>
	);
};

export default FinancialOverview;
