import React, {useEffect, useState} from "react";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import {fetchBatterySimultaneous} from "../../services/apiFunctions";
import InputSpec from "../../components/computation-input-specific/InputSpecific";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from 'react-i18next'; // Import for translations
import { getSimulPayloadIds, formatOptionFields, getAllOptions, OutputTable} from "../../layout/solar-battery/batteryFunctions";
import { ComputationData, Option, InteractionData, BatteryParamIds, QuarterlyGraphData } from "../../layout/solar-battery/batteryInterfaces";
import QuarterlyGraphs from "./graphs";
import "./battery_simul.css";

interface BatteryQuarterlyProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionData[];
	generalData: any; // Replace 'any' with the correct type
	template: any;
	pageReady: boolean;
}

const SolarBatterySimultaneous: React.FC<BatteryQuarterlyProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	generalData,
	template,
	pageReady
}) => {
	const computationType = "solar_battery_computations";
	const { t } = useTranslation(); // Translation hook
	const [production, setProduction] = useState<number>(0);
	const [dataChanged, setDataChanged] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [computationOutput, setComputationOutput] = useState<ComputationData>();
	const [allOptions, setAllOptions] = useState<Option[]>([]);
	const [payloadIds, setPayloadIds] = useState<BatteryParamIds>();
	const [quarterlyGraphData, setQuarterlyGraphData] = useState<QuarterlyGraphData | null>(null);
	const [presentingMode, setPresentingMode] = useState<"values" | "graphs">("values");

	const checkBatteryInputs = async () => {
		const currentInteraction = interactionData.find((interaction) => interaction.id === selectedInteraction);
		if (currentInteraction && Object.keys(payloadIds || {}).length !== 7){
			const ids = await getSimulPayloadIds(currentInteraction);
			setPayloadIds(ids);
		}
		if (currentInteraction && currentInteraction.fields && payloadIds){
			let calcType = payloadIds["battery_calculation_type"];
			let capacity = payloadIds["battery_max_capacity"];
			let power = payloadIds["battery_max_power"];
			let current = payloadIds["current_connection"];
			let required = payloadIds["required_connection"];
			let peak = payloadIds["period_peakusage_daily"];
			let discharge = payloadIds["discharge_depth"];

			return {
				battery_calculation_type: currentInteraction.fields[calcType] || "",
				battery_max_capacity: currentInteraction.fields[capacity] || 0,
				battery_max_power: currentInteraction.fields[power] || 0,
				current_connection: currentInteraction.fields[current] || 0,
				required_connection: currentInteraction.fields[required] || 0,
				period_peakusage_daily: currentInteraction.fields[peak] || 0,
				discharge_depth: currentInteraction.fields[discharge] || 0,
			}
		}
	};

	useEffect(() => {
		const currentInteraction = interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		);
		if (!currentInteraction) return;
		
		const status = currentInteraction.simultaneous !== undefined;
		if (status !== undefined && status !== pageReady) {
			mergeDoc(computationType, updateId, {
				progress: {
					4: {
						done: status,
						name: "Simultaneous",
					},
				},
			});
		}
	}, [interactionData, selectedInteraction, pageReady, updateId]);

	useEffect(() => {
		if (dataChanged === true) {
			mergeDoc(
				`${computationType}/${updateId}/computation_inputs`,
				selectedInteraction,
				{
					computation_present: false,
					pdf_present: false,
				}
			);
		}
	}, [dataChanged, selectedInteraction, updateId]);

	useEffect(() => {
		const currentInteraction = interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		);
		if (!currentInteraction) return;
		
		if (currentInteraction.simultaneous?.monthly_data && currentInteraction.simultaneous?.usage_data && currentInteraction.daily_data){
			const { monthly_data, usage_data } = currentInteraction.simultaneous;
			const monthly_day_data = currentInteraction.daily_data;
			const dataHasChanged =  monthly_data !== quarterlyGraphData?.monthly_percentages || 
									usage_data !== quarterlyGraphData?.simultaneous || 
									monthly_day_data !== quarterlyGraphData?.daily_data;
		
			if (dataHasChanged) {
				const data: QuarterlyGraphData = {
						simultaneous: usage_data,
						monthly_percentages: monthly_data,
						daily_data: monthly_day_data
				};
				setQuarterlyGraphData(data);
			}
		}

		const updates = [
			[currentInteraction.production?.[0]?.kwh_production, production, setProduction, true],
			[currentInteraction.quarterly, computationOutput, setComputationOutput]
		];
	
		updates.forEach(([newValue, stateValue, setter, markChanged]) => {
			if (newValue !== undefined && newValue !== stateValue) {
				setter(newValue);
				if (markChanged){
					setDataChanged(true);
				}
			}
		});
	}, [selectedInteraction, interactionData]);

	function handleForceButtonClick(){
		if (loading === false){
			calculateSimultaneous();
		}else{
			setErrorMessage("Please wait for the current calculation to finish");
		}
	}

	const calculateSimultaneous = async () => {
		try {
			const selectedInteractionData: InteractionData | undefined =
				interactionData.find(
					(interaction) => interaction.id === selectedInteraction
				);
			// Fetch Battery Simultaneous data
			setLoading(true);
			setErrorMessage("");

			const batteryParams = await checkBatteryInputs();
			const options = await getAllOptions();
			setAllOptions(options);
			if (batteryParams){
				const updatedBatParams = formatOptionFields(batteryParams, allOptions);
				
				const payload = {
					production: production,
					client_id: updateId,
					computation_id: selectedInteraction,
					language_version: generalData[0].fields?.["QQCT34RNyYnuu1UdpV9j"],
					battery_calculation_type: updatedBatParams?.battery_calculation_type,
					battery_max_capacity: updatedBatParams?.battery_max_capacity,
					battery_max_power: updatedBatParams.battery_max_power,
					current_connection: updatedBatParams.current_connection,
					required_connection: updatedBatParams.required_connection,
					period_peakusage_daily: updatedBatParams.period_peakusage_daily,
					discharge_depth: updatedBatParams.discharge_depth,
				}
				setPresentingMode("values");
				const summary_simultaneous = await fetchBatterySimultaneous(payload);
				setLoading(false);

				if (summary_simultaneous?.simul_data.usage_perc && summary_simultaneous?.simul_data.usage_data && 
					summary_simultaneous?.simul_data.monthly_data && production > 0 && summary_simultaneous?.daily_data){
					const data: QuarterlyGraphData = {
							simultaneous: summary_simultaneous.simul_data.usage_data,
							monthly_percentages: summary_simultaneous.simul_data.monthly_data,
							daily_data: summary_simultaneous.daily_data
					};
					setQuarterlyGraphData(data);
				}

				if (summary_simultaneous?.calc_data){
					setComputationOutput(summary_simultaneous.calc_data);
				}

				// Merge computed data to Firestore
				if (selectedInteractionData?.fields) {
					if (summary_simultaneous?.simul_data?.usage_perc && summary_simultaneous?.calc_data && summary_simultaneous?.daily_data) {
						mergeDoc(
							`${computationType}/${updateId}/computation_inputs`,
							`${selectedInteraction}`,
							{
								daily_data: summary_simultaneous.daily_data,
								quarterly: summary_simultaneous.calc_data,
								simultaneous: summary_simultaneous.simul_data,
							}
						);
					}
				}

				if (summary_simultaneous?.simul_data?.Error) {
					setErrorMessage(summary_simultaneous.simul_data.Error);
				}
				else{
					setErrorMessage("");
				}
				setDataChanged(false);
			}
			else{
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.error("Error fetching or merging data:", error);
		}
	};
	// quarter_value_specs

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			<div style={{display: "flex", flexDirection: "row"}}>
				<div>
				{selectedInteraction !== "" && (
					<div
						style={{
							...styles.rightPanelStyle,
							minWidth: "400px",
							minHeight: "80vh",
							maxHeight: "80vh",
							marginLeft: "-10px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between"
						}}
					>
						<div>
							<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
								{" "}
								{t('quarterlyEnergySpecs')}{" "}
							</h3>
							<InputSpec
								updateId={updateId}
								interactionId={selectedInteraction}
								interactionData={interactionData}
								filter={"simultaneous"}
								templateUnfiltered={template}
								computationType={computationType}
							/>
							<div style={{marginTop: "100px"}}>
							<div className="sidebar-divider"></div>
							<p	style={{
								marginBottom: "10px",
								tabSize: 4,
								fontSize:"0.8rem"
								}}>
									<button onClick={handleForceButtonClick}>Run calculation</button>
									<span style={{marginLeft: "8px"}}>{loading ? (<CircularProgress size={15} />) : errorMessage}</span>
							</p>
							</div>				
						</div>
					</div>
				)}
				</div>
				<div style={{
					...styles.rightPanelStyle,
					minWidth: "500px",
					maxWidth: "70vw",
					minHeight: "80vh",
					maxHeight: "80vh",
					marginLeft: "10px",
					}}
				>
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
						{" "}
						{t('simultaneousView')} 
						<button 
							style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
							onClick={() => setPresentingMode(presentingMode == "graphs" ? "values" : "graphs")} >
							{presentingMode == "graphs" ? "Variabelen" : "Grafieken"}
						</button>
					</h3>
						<div style={{
								...styles.rightPanelStyle,
								// maxWidth: "70vw",
								marginTop: "10px",
								marginLeft: "-10px",
								}}
							>
							{presentingMode == "values" ? (
								<div>
									<div>
									{computationOutput && (
										<OutputTable title={t("batteryFillPerc")} data={computationOutput.percentage_data} loading={loading} suffix="%" />
									)}
									</div>
									<div>
									{computationOutput && (
										<OutputTable title={t("batteryValues")} data={computationOutput.calculated_data} loading={loading} />
									)}
									</div>
									<div>
									{computationOutput && (
										<OutputTable title={t("batteryCalcs")} data={computationOutput.output_data} loading={loading} suffix="kWh" />
									)}
									</div>
								</div>
								) : (
								<div>
									{quarterlyGraphData && production && (
										<QuarterlyGraphs 
										quarterlyGraphData={quarterlyGraphData} 
										/>
									)}
								</div>
							)}
						</div>
				</div>
			</div>
		</div>
	);
};

export default SolarBatterySimultaneous;
