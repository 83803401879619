import React, {useState, useEffect} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../config/firebase";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import { fetchBatteryComparison, fetchPDFTemplate, saveToSharepoint } from "../../services/apiFunctions";
import { mergeDoc } from "../../services/firebaseFunctions";
import { getAllOptions, formatOptionFields, formatClientInputs } from "../../layout/solar-battery/batteryFunctions";
import {removeFolder} from "../../components/utils/s3ImageOperations";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import SaveIcon from "@mui/icons-material/Save";
import {CircularProgress, IconButton} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import GetAppIcon from "@mui/icons-material/GetApp";
import {downloadMultipleFiles} from "../../components/pdf-viewer/PDFViewer";
import {useTranslation} from "react-i18next";
import { GeneralData, InteractionDataRep } from "../../layout/solar-battery/batteryInterfaces";

interface ContentProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionDataRep[];
	generalData: GeneralData[];
}

const BatteryReport: React.FC<ContentProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	generalData,
}) => {
	const [downloadURL, setDownloadURL] = useState("");
	const [downloadURLDocx, setDownloadURLDocx] = useState("");
	const {t} = useTranslation();
	const [scale, setScale] = useState(1.5);
	const [loadingReport, setLoadingReport] = useState(false);
	const [savingFile, setSavingFile] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		interactionData.map(async (interaction) => {
			if (
				interaction.computation_present === true &&
				interaction.pdf_present === false
			) {
				await fetchPDFTemplate(
					updateId,
					interaction.id,
					"solar_battery_computations"
				);
				await mergeDoc(
					`solar_battery_computations/${updateId}/computation_inputs`,
					`${interaction.id}`,
					{computation_present: true, pdf_present: true}
				);
			}
		});
	}, [interactionData, updateId]);

	useEffect(() => {
		let newUrl = "";
		if (selectedInteraction === "comparison") {
			newUrl = `gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_battery_computations/${updateId}/all/output/comparison_bat.pdf`;
		} else if (selectedInteraction === "mwa_bat") {
			newUrl = `gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_battery_computations/${updateId}/all/output/mwa_bat.pdf`;
		} else {
			newUrl = `gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_battery_computations/${updateId}/${selectedInteraction}/output/output_basic.pdf`;
		}

		const fetchDownloadURL = async () => {
			try {
				const download = await getDownloadURL(ref(storage, newUrl));
				setDownloadURL(download);
				const downloadDocx = await getDownloadURL(
					ref(storage, newUrl.replace(".pdf", ".docx"))
				);
				setDownloadURLDocx(downloadDocx);
			} catch (error) {
				console.log("Document not found:", error);
				setDownloadURL("");
			}
		};
		fetchDownloadURL();

	}, [selectedInteraction, loadingReport, updateId]);

	const handleSaveAction = async () => {
		setSavingFile(true);
		let pathPrefix = "02 Energieke Regio";
		if (generalData[0].fields?.eLL3U03GcWipjoW0DkzU === "7GJQkNfGzczJmD34tvvg") {
			pathPrefix = "01 Praeter";
		}
		const companyId = generalData[0].fields?.["5xrjtBgD8t4DQsKCjOwW"];
		const companyName = generalData[0].fields?.["YfRokLRi9yJOY7TMKo0k"];
		const cleanedCompanyName = companyName.replace(/[^a-zA-Z0-9\s]/g, "");
		const pathPrefixShort = pathPrefix === "01 Praeter" ? "PR" : "ER";
		const filesToSave = [
			"comparison_bat.pdf", 
			"comparison_bat.docx", 
			"mwa_bat.pdf",
			"mwa_bat.docx",
		];

		Promise.all(
			filesToSave.map((file) =>
				saveToSharepoint(
					updateId,
					"solar_battery_computations",
					"all", 
					`${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/MWA.BAT/${updateId}`,
					file
				)
			)
		)
			.then(() => {
				console.log("saved");
			})
			.catch((error) => {
				console.error("Failed to save files:", error);
			})
			.finally(() => {
				console.log(
					`https://praeter.sharepoint.com/Gedeelde documenten/02 Klanten/${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/MWA.BAT/${updateId}`
				);
				mergeDoc(
					`solar_battery_computations/${updateId}/general_inputs`,
					"all",
					{
						report_link: `https://praeter.sharepoint.com/Gedeelde documenten/02 Klanten/${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/MWA.BAT/${updateId}`,
					}
				);
				setSavingFile(false); // This ensures it's set to false regardless of success or failure
			});
	};

	useEffect(() => {
		const fetchData = async () => {
			const allOptions = await getAllOptions();
			if (
				generalData &&
				generalData[0]?.fields &&
				generalData[0]?.report_ready !== true &&
				loadingReport === false &&
				interactionData.filter((item) => item.computation_present === false).length === 0 &&
				interactionData.filter((item) => item.name !== "Algemene berekening").length > 0 &&
				error === false
			) {
				try {
					await removeFolder(
						`/solar_battery_computations/${updateId}/all/output`
					);
				} catch {
					console.log("Folder not found");
				}

				setLoadingReport(true);
				const genData = await formatClientInputs(generalData[0]);
				// const rawData = formatOptionFields(generalData[0].fields, allOptions);
				const formattedData = formatOptionFields(genData, allOptions);

				const payload = {
					Computations: interactionData.filter(
							(item) =>
								[1, 2, 3].includes(item.rank) &&
								item.computation_present === true
						).sort((a, b) => a.rank - b.rank).map((item) => item.id),
					Layout: generalData[0].fields?.eLL3U03GcWipjoW0DkzU,
					mwa_id: interactionData.find((item) => item.name === "Algemene berekening")?.id,
					mwa_data: formattedData
				};
				
				payload.Layout = formatOptionFields({Layout: payload.Layout}, allOptions).Layout;
				const result = await fetchBatteryComparison(
					updateId,
					generalData[0].id,
					payload
				);
				console.log(result);
				if (result.success === true) {
					const response = await fetchPDFTemplate(
						updateId,
						"all",
						"solar_battery_computations"
					);
					console.log(response);
					await mergeDoc(
						`solar_battery_computations/${updateId}/general_inputs`,
						`all`,
						{
							report_ready: true,
						}
					);
					setLoadingReport(false);
				} else {
					setLoadingReport(false);
					setError(true);
				}
			}
		};

		fetchData();
	}, [interactionData, generalData, loadingReport, updateId]);

	const handleZoomIn = () => {
		setScale(scale + 0.1);
	};

	const handleZoomOut = () => {
		if (scale > 0.1) {
			setScale(scale - 0.1);
		}
	};

	return (
		<div
			style={{
				position: "relative",
				overflowY: "auto",
				maxWidth: "65vw",
				maxHeight: "95vh",
				minHeight: "95vh",
				minWidth: "65vw",
				marginLeft: "10px",
			}}
		>
			<div
				style={{
					position: "absolute",
					top: 10,
					right: 10,
					marginRight: "10px",
					marginTop: "10px",
					zIndex: 1,
				}}
			>
				<button
					onClick={handleZoomIn}
					style={{
						color: "#267FB8",
						fontSize: "35px",
						background: "transparent",
						border: "none",
					}}
				>
					{" "}
					+{" "}
				</button>
				<button
					onClick={handleZoomOut}
					style={{
						color: "#267FB8",
						fontSize: "40px",
						background: "transparent",
						border: "none",
					}}
				>
					-{" "}
				</button>
				<a
					href={generalData[0]?.report_link}
					target="_blank"
					rel="noreferrer"
					style={{
						textDecoration: "none",
						display: "flex",
						alignItems: "center",
						color: generalData[0]?.report_link
							? "#267FB8"
							: "rgba(0, 0, 0, 0.26)",
					}}
				>
					<LinkIcon
						style={{
							fontSize: "24px",
							marginRight: "4px",
						}}
					/>
					<span style={{fontSize: "16px"}}>Link</span>
				</a>
				<IconButton
					onClick={handleSaveAction}
					disabled={savingFile || generalData[0].report_ready === false}
					style={{
						color: savingFile === true ? "white" : "#267FB8",
						fontSize: "45px",
						top: -4,
						right: 60,
						position: "absolute",
						background: "transparent",
						border: "none",
						marginLeft: "10px",
						marginTop: "10px",
					}}
				>
					{" "}
					<SaveIcon />
				</IconButton>
				<IconButton
					onClick={() => downloadMultipleFiles([downloadURL, downloadURLDocx])}
					disabled={
						isDownloading === true || generalData[0]?.report_ready === false
					}
					style={{
						color: isDownloading === true ? "white" : "#267FB8",
						fontSize: "45px",
						top: 28,
						right: 60,
						position: "absolute",
						background: "transparent",
						border: "none",
						marginLeft: "10px",
						marginTop: "10px",
					}}
				>
					<GetAppIcon />
				</IconButton>
			</div>
			{loadingReport === true &&
				["mwa_bat", "comparison"].includes(selectedInteraction) && (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<h6
							style={{
								fontSize: "0.9rem",
								fontFamily: "Barlow",
								marginTop: "150px",
							}}
						>
							{t("computationLoading")}
						</h6>
						<CircularProgress />
					</div>
				)}
			{!(
				loadingReport === true &&
				["mwa_bat", "comparison"].includes(selectedInteraction)
			) &&
				downloadURL && <PDFViewer downloadURL={downloadURL} scale={scale} />}
		</div>
	);
};

export default BatteryReport;
