import React, {useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import "./ExcelUpload.css"; // Import your CSS file for styling
import {styles} from "../utils/Styles";
import "firebase/storage";
import {
	getDownloadURL,
	ref,
	listAll,
	uploadBytes,
	deleteObject,
	StorageReference,
	UploadTaskSnapshot,
} from "firebase/storage";
import {storage} from "../../config/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Popup from "../popup";

interface ExcelUploadProps {
    path: string;
	existingExcel: boolean;
	setExistingExcel: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExcelUpload: React.FC<ExcelUploadProps> = ({
    path, 
    existingExcel, 
    setExistingExcel
    }) => {

	const {t} = useTranslation();
	const [selectedExcel, setSelectedExcel] = useState<{
		[key: string]: any;
	} | null>(null);
	const [filePath, setFilePath] = useState<string | null>(null);
    const [displayName, setDisplayName] = useState<string | null>(null);
	const isConsumption = path.endsWith("consumption_data");	
	const imagePathUsage = require('../../assets/images/columnNames.png');
	const imagePathProd1 = require('../../assets/images/columnNamesProd1.png');
	const imagePathProd2 = require('../../assets/images/columnNamesProd2.png');

    useEffect(() => {
        if (selectedExcel) {
            setDisplayName(selectedExcel.name);
        }
    }, [selectedExcel]);

	const uploadExcel = async (
		storageRefExcel: StorageReference,
		selectedExcel: File,
		blob: Blob
	) => {
		if (!selectedExcel) {
			console.log("No excel selected");
			return;
		}
		console.log("Uploading excel...");

		// Create a reference to the file
		const excelRef = ref(storageRefExcel, selectedExcel.name);
		// Upload the file
		try {
			console.log("Uploading file...");
			const snapshot: UploadTaskSnapshot | any = await uploadBytes(
				excelRef,
				blob
			);
			setExistingExcel(true);
			console.log("Uploaded a blob or file!", snapshot);
		} catch (error) {
			console.error("Error uploading file", error);
		}
	};

	const listFiles = useCallback(
		async (storageRefExcel: StorageReference) => {
			try {
				const res: any = await listAll(storageRefExcel);
				if (res.items.length > 0 && res.items[0]?._location.path_) {
					const url = await getDownloadURL(res.items[0]);
					setFilePath(url);
					setSelectedExcel({
						name: res.items[0]._location.path_.split("/").slice(-1)[0],
						// Add other properties as needed
					});
					setExistingExcel(true);
				}
				else{
					setExistingExcel(false);
				}
			} catch (error) {
				console.error("Error listing files", error);
			}
		},
		[setFilePath, setSelectedExcel, setExistingExcel] // Dependencies
	);

	useEffect(() => {
		// Call the function with the path to your folder
		try {
			listFiles(ref(storage, path)).catch((error) => {
				console.log(error);
			});
		} catch (error) {
			console.log(error);
		}
	}, [path, listFiles]);

	const dataURLtoBlob = (dataURL: string) => {
		const arr = dataURL.split(",");
		if (arr && arr.length > 1) {
			// Check if arr is not null and has at least two parts
			const mime: string = arr[0].match(/:(.*?);/)?.[1] || ""; // Use optional chaining and nullish coalescing
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);

			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}

			return new Blob([u8arr], {type: mime});
		}
		return null; // Return null if dataURL is invalid
	};

	const handleExcelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {

            const validTypes = [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
                "application/vnd.ms-excel", // .xls
                "text/csv", // .csv
            ];
    
            if (!validTypes.includes(file.type)) {
                alert("Please upload a valid Excel file (.xlsx, .xls, .csv).");
                return;
            }

			const reader = new FileReader();

			reader.onloadend = () => {
				// Set the selected excel and update the state
				setSelectedExcel(file);
				setFilePath(reader.result as string);
				const blob: any = dataURLtoBlob(reader.result as string);
				uploadExcel(ref(storage, path), file, blob);
			};

			// Read the excel as a data URL
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveExcel = () => {
		// Remove the selected excel
		const desertRef = ref(ref(storage, path), selectedExcel?.name || "");
		// Delete the file
		deleteObject(desertRef)
			.then(() => {
				// File deleted successfully
			})
			.catch((error) => {
				// Uh-oh, an error occurred!
				console.error("Error deleting file", error);
			});

		setSelectedExcel(null);
		setFilePath(null);
		setExistingExcel(false);
	};

	return (
		<div
			    style={{
				...styles.rightPanelStyle,
				marginTop: "20px",
				padding: "10px",
				maxHeight: "170px",
				position: "relative",
			    }} >

			<h3 style={{fontSize: "1rem", fontFamily: "Basic", marginTop: "5px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
				{path.endsWith("consumption_data") ? t("excel_consumption") : t("excel_production")}
				{isConsumption && <Popup title={t("RequiredColumnNamesCons")} imagePath={String(imagePathUsage)}/>}
				{!isConsumption && <Popup title={t("RequiredColumnNamesProd")} imagePath={String(imagePathProd1)} imagePath2={String(imagePathProd2)}/>}
			</h3>

			{!existingExcel && (
				<input type="file" accept=".xlsx,.xls,.csv" onChange={handleExcelChange} />
			)}

			{filePath && (
				<div>
					{existingExcel && (
                        <div> 
                        <div className="span-excel-selected">
                            <span>{t("selected_excel")}</span>
                            {displayName}
                        </div>
						<IconButton
							onClick={handleRemoveExcel}
							style={{position: "absolute", top: 50, right: 20}}
							color="primary"
							aria-label="delete"
						>
							<DeleteIcon />
						</IconButton>
                        </div>
					)}
				</div>
			)}
		</div>
	);
};

export default ExcelUpload;
