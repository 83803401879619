import React from "react";
import {Line} from "react-chartjs-2";
import {styles} from "../utils/Styles";
import type {ChartData, ChartOptions} from "chart.js";
import Chart from "chart.js/auto";
import {CategoryScale} from "chart.js";
import {useTranslation} from "react-i18next";

Chart.register(CategoryScale);

interface Record {
    month: string;
    prod: number;
    prog_prod: number;
}

interface LineChartProps {
    data: Record[];
}

const LineChartProdProg: React.FC<LineChartProps> = ({
    data,
}) => {
    const {t} = useTranslation();
    const monthList = data.map((record) => t(`months.${record.month}`));
    const prodList = data.map((record) => record.prod);
    const prodProgList = data.map((record) => record.prog_prod);

    // Combine bar and line datasets
    const chartData = {
        labels: monthList,
        datasets: [
            {
                label: "Productie",
                borderColor: "rgba(54, 162, 235, 0.5)",
                data: prodList,
                borderWidth: 2,
                fill: false,
                type: "line", // Explicitly set type to bar for this dataset
            },
            {
                label: "Prog. Productie",
                borderColor: "rgba(255, 159, 64, 0.5)",
                data: prodProgList,
                borderWidth: 2,
                fill: false,
                type: "line", // Explicitly set type to bar for this dataset
            }
        ],
    };

    const options: ChartOptions<"line"> = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false, // Hide vertical grid lines
                },
                ticks: {
                    font: {
                        family: styles.label.fontFamily,
                    },
                },
            },
            y: {
                grid: {
                    color: "rgba(200, 200, 200, 0.2)", // Light gray grid lines
                },
                ticks: {
                    font: {
                        family: styles.label.fontFamily,
                    },
                },
            },
        },
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: styles.label.fontFamily,
                    },
                },
            },
        },
    };

    return (
        <div>
            <Line
                data={chartData as ChartData<"line">}
                options={options}
                style={{minHeight: "300px"}}
            />
        </div>
    );
};

export default LineChartProdProg;
