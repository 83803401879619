import React, {useState, useEffect, useRef} from "react";
import {styles} from "../../components/utils/Styles";
import {useParams, useNavigate} from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { fetchDocsDataStream, fetchDocData, fetchDocDataStream } from "../../services/firebaseFunctions";
import { fetchInputFieldsData, updateFilteredSteps, fetchComputation } from "../../components/utils/computationInitialization";
import { fetchAll, fetchInteractions, fetchRooms } from "../../services/streamingFunctions";
import {removeDoc} from "../../services/firebaseFunctions";
import { getAllOptions } from "./batteryFunctions";
import SolarBatteryPrep from "../../screens/solar-battery-values-prep";
import SolarBatterySimultaneous from "../../screens/solar-battery-simultaneous";
import ProductionInput from "../../screens/production-input/index-new";
import SolarBatteryComputationSpecs from "../../screens/solar-battery-computation-specs";
import SolarBatteryCosts from "../../screens/solar-battery-costs";
import SolarBatteryResultSummary from "../../screens/solar-battery-result";
import BatteryReport from "../../screens/solar-battery-report";
import BatteryInitialize from "../../screens/solar-battery-initialize";
import Main from "../main/Main";
import UnauthorizedPage from "../../screens/unauthorized";
import useCustomClaims from "../../hooks/useCustomClaims";
import useFetchCRMData from "../../hooks/useFetchCRMData";
import SelectableProgressBar from "../../components/selectable-progressbar";
import StageStepper from "../../components/stage-stepper";
import DealSelector from "../../components/layout/deal-selector";

interface initializeTemplate{
	fields: {};
}
interface Template {
	[key: string]: any;
}

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

const SolarBattery: React.FC = () => {
    const {t} = useTranslation();
    const customClaims = useCustomClaims();
    const computationType = "solar_battery_computations";
    const navigate = useNavigate();

    const {dealId, selectedPageName} = useParams();
    const [clientId, setClientId] = useState(dealId || "");
    const [updateId, setUpdateId] = useState("");
    const [showScreens, setShowScreens] = useState(false);
    const [crmDetails, setCRMDetails] = useFetchCRMData(clientId);
    const [filteredSteps, setFilteredSteps] = useState<string[]>([]);
	const [allOptions, setAllOptions] = useState<Option[]>([]);

    const [progress, setProgress] = useState<any>({});
    const [roomId, setRoomId] = useState("");
    const [listRooms, setListRooms] = useState<any[]>([]);

    const [generalData, setGeneralData] = useState<any[]>([]);
    const [inputCategories, setInputCategories] = useState<any[]>([]);
    const [interactionData, setInteractionData] = useState<any[]>([]);
	const [selectedInteraction, setSelectedInteraction] = useState<string>("");
	const [differences, setDifferences] = useState<any[]>([]);
    const [computationData, setComputationData] = useState<any>({});

    const [pageReady0, setPageReady0] = useState(false);
    const [pageReady1, setPageReady1] = useState(false);
    const [pageReady2, setPageReady2] = useState(false);
    const [pageReady3, setPageReady3] = useState(false);
    const [pageReady4, setPageReady4] = useState(false);
    const [pageReady5, setPageReady5] = useState(false);
	const [pageReady6, setPageReady6] = useState(false);

    const [template, setTemplate] = useState<Template>({});
	const [initializeTemplate, setInitializeTemplate] = useState<initializeTemplate>({fields: {}});
    const templateRef = useRef(template);

    const steps = [t("steps.generalInfo"), t("steps.calculationDetails"), t("steps.productionSpecs"), t("steps.quarterPrep"), t("steps.quarterCalc"), t("steps.costs"), t("steps.resultSummary"), t("steps.reporting")];
	const pages: any = {
		initialize: 0,
		"computation details": 1,
		building: 2,
		dataprep: 3,
		simultaneous: 4,
		costs: 5,
		result: 6,
		report: 7,
	};
	const [activeStep, setActiveStep] = useState<number>(selectedPageName ? pages[selectedPageName] : 0);

    useEffect(() => {
        fetchInteractions(updateId, computationType, setInteractionData);
        fetchRooms(updateId, computationType, setListRooms);
        fetchAll(updateId, computationType, setGeneralData);
    }, [updateId]);

	useEffect(() => {
		const retrieveAllOptions = async () => {
			const options = await getAllOptions();
			setAllOptions(options);
		}
		if (allOptions.length === 0) {
			retrieveAllOptions();
		}
	}, [allOptions, clientId])

    useEffect(() => {
        if (showScreens === true) {
            setActiveStep(0);
            setUpdateId(clientId);
        }
    }, [showScreens, clientId]);

    useEffect(() => {
		fetchDocsDataStream("input_categories", setInputCategories as any);
	}, [computationType]);

    useEffect(() => {
		const fetchComputationData = async () => {
			try {
				if (clientId.trim() !== "") {
					setUpdateId("");
					setComputationData({});
					setShowScreens(false);
					setProgress({});
					await fetchComputation(
						clientId,
						computationType,
						setUpdateId,
						setComputationData,
						setShowScreens
					);
					navigate(`/solar-battery/${clientId}/initialize`);
					setActiveStep(0);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchComputationData();
	}, [clientId, computationType]);

	useEffect(() => {
		const fetchClientData = async () => {
			try {
				if (updateId) {
					const unsubscribe = fetchDocDataStream(
						"solar_battery_computations",
						updateId,
						setProgress
					);
					return () => {
						unsubscribe();
					};
				}
			} catch (error) {
				console.error("Error fetching progress data:", error);
			}
		};
		fetchClientData();
	}, [updateId]);

    useEffect(() => {
		updateFilteredSteps(
			progress?.progress,
			steps,
			showScreens,
			updateId,
			setFilteredSteps
		);
		const pageReadyList = [
			setPageReady0,
			setPageReady1,
			setPageReady2,
			setPageReady3,
			setPageReady4,
			setPageReady5,
			setPageReady6,
		];
		if (progress?.progress) {
			for (const key in progress.progress) {
				if (progress.progress[key].done) {
					pageReadyList[parseInt(key)](true);
				} else {
					pageReadyList[parseInt(key)](false);
				}
			}
		}
	}, [progress, showScreens, updateId, t]);

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                let GeneralData: any;
                const result = await fetchDocData("templates", "pv_battery");
                const generalFieldList: string[] = result?.fields;
                // Fetch the input fields data and update loadedClientInputs
                GeneralData = await fetchInputFieldsData(
                    templateRef.current, // Use ref's current value
                    generalFieldList,
                    inputCategories
                );
                setTemplate(GeneralData as any);
				setInitializeTemplate({fields: GeneralData});
            } catch (error) {
                console.error("Error fetching template: ", error);
            }
        };
        if (inputCategories.length > 0) {
            fetchTemplate();
        }
    }, [computationType, inputCategories]);

	useEffect(() => {
		if (interactionData.length > 0 && selectedInteraction === "") {
			interactionData.map((interaction: any) => {
				if (interaction.name === "Algemene berekening") {
					setSelectedInteraction(interaction.id);
				}
			});
		}
	}, [interactionData]);

	const updatedInteractionData = [
		...interactionData,
		{name: t("mwa_bat"), id: "mwa_bat", rank: -1},
		{name: t("comparisonBAT"), id: "comparison", rank: -2},
	];

	useEffect(() => {
		interactionData.map(async (interaction) => {
			if (!interaction.name) {
				await removeDoc(
					`solar_battery_computations/${updateId}/computation_inputs`,
					`${interaction.id}`
				);
			}
		});
	}, [interactionData, updatedInteractionData, updateId]);

	// SolarBatteryPrep

    return (
        customClaims &&
		(customClaims?.["praeter-advisor"] ||
			customClaims?.["admin"] ||
			customClaims?.["battery-advisor"]) ? (
		<Main>
        <div
				style={{
					backgroundColor: "#ff0000",
					width: "100vw",
					padding: "10px",
					height: "10px",
				}}
			>
				<h2
					style={{
						textAlign: "center",
						marginTop: -9,
						color: "white",
						fontSize: "18px",
						fontFamily: "basic",
						fontWeight: "bold",
					}}
				>
					{t("solarBattery")}
				</h2>
			</div>
            <div
				style={{
					display: "flex",
					backgroundColor: "#f0f0f0",
					minHeight: "100vh",
				}}
			>
				<div style={{display: "flex"}}>
					<div style={{flex: 1, maxWidth: "250px"}}>
						<DealSelector
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={setShowScreens}
							crmDetails={crmDetails}
							computationType={computationType}
						/>
						<StageStepper
							steps={steps}
							activeStep={activeStep}
							handleStepChange={setActiveStep}
							filteredSteps={filteredSteps}
						/>
					</div>
                    <div style={{flex: 2}}>
						{activeStep === 0 && Object.keys(template).length > 0 && selectedInteraction !== "" && (
							<BatteryInitialize
								updateId={updateId}
								crmDetails={crmDetails}
								computationData={computationData}
								generalData={generalData}
								setComputationData={setComputationData}
								roomId={roomId}
								setRoomId={setRoomId}
								pageReady={pageReady0}
								computationType={computationType}
								templateInitialize={initializeTemplate as any}
								template={template}
							/>
						)}
						{activeStep === 1 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_battery_computations"
								/>
								<SolarBatteryComputationSpecs
									updateId={updateId}
									generalData={generalData}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									pageReady={pageReady1}
									template={template}
								/>
							</div>
						)}
						{activeStep === 2 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_battery_computations"
								/>
								<ProductionInput
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									pageReady={pageReady2}
									template={template}
								/>
							</div>
						)}
						{activeStep === 3 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_battery_computations"
								/>
								<SolarBatteryPrep
									updateId={updateId}
									selectedInteraction={selectedInteraction || roomId}
									interactionData={interactionData}
									generalData={generalData}
									template={template}
									pageReady={pageReady3}		
								/>
							</div>
						)}
						{activeStep === 4 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_battery_computations"
								/>
								<SolarBatterySimultaneous
									updateId={updateId}
									selectedInteraction={selectedInteraction || roomId}
									interactionData={interactionData}
									generalData={generalData}
									template={template}
									pageReady={pageReady4}		
								/>
							</div>
						)}
						{activeStep === 5 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_battery_computations"
								/>
								<SolarBatteryCosts
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									generalData={generalData}
									pageReady={pageReady5}
								/>
							</div>
						)}
						{activeStep === 6 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={interactionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_battery_computations"
								/>
								<SolarBatteryResultSummary
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									template={template}
									generalData={generalData}
									pageReady={pageReady6}
								/>
							</div>
						)}
						{activeStep === 7 && interactionData.length > 0 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									// interactionData={
									// 	interactionData.filter(
									// 		(item) => item.name !== "Algemene berekening"
									// 	).length > 0
									// 		? updatedInteractionData
									// 		: interactionData
									// }
									interactionData={updatedInteractionData}
									selectedInteraction={selectedInteraction}
									setSelectedInteraction={setSelectedInteraction}
									differences={differences}
									computationType="solar_battery_computations"
								/>

								<BatteryReport
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={updatedInteractionData}
									generalData={generalData}
								/>
							</div>
						)}
                    </div>
					<div
						style={{
							position: "absolute",
							bottom: 50,
							right: 80,
						}}
					>
						<IconButton
							onClick={() => setActiveStep((prevStep) => prevStep + 1)}
							disabled={
								activeStep === steps.length - 1 ||
								showScreens === false ||
								filteredSteps.slice(activeStep + 1).length === 0
							}
							color="primary"
						>
							<ArrowForwardIcon style={{fontSize: "40px"}} />
						</IconButton>
					</div>
                </div>
            </div>
        </Main>) : (<UnauthorizedPage />)
    )
};

export default SolarBattery;