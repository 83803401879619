// src/screens/portfolio-screen/utils/fetchFunctions.ts
import { fetchDocsData } from "../../../services/firebaseFunctions";

export const getAllCollections = async (collection: string) => {
    const options = await fetchDocsData(collection);
    return options.map((option: any) => option);
};



export const getAllTemplates = async (collectionName: string) => {
    // Construct the Firestore path dynamically
    const path = `templates/${collectionName}/columns`;
    const options = await fetchDocsData(path); // Reuse the fetchDocsData function
    return options.map((option: any) => option); // Map and return the template data
};

export const getAllOptions = async () => {
	const options = await fetchDocsData("options");
	return options.map((option: any) => {
		return option; // Return each individual option object
	});
};