import React, {useEffect, useState, useReducer } from "react";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import EditableTable from "../../components/simultaneous-edit-table";
import StackedBarChartWithLinePv from "../../components/plots/StackedBarLinePv";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchPVSimultaneous} from "../../services/apiFunctions";
import InputSpec from "../../components/computation-input-specific/InputSpecific";
import { useTranslation } from 'react-i18next'; // Import for translations
import ExcelUpload from "../../components/excel-upload/ExcelUpload";
import { Option, InteractionData, SimulCalcType, SimulGraphData, SolarParamIds, SimulState, SimulAction } from "../../layout/solar-energy/solarEnergyInterfaces";
import { getSimulPayloadIds, getAllOptions, formatOptionFields, months } from "../../layout/solar-energy/solarEnergyFunctions";

interface SolarEnergyProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionData[];
	generalData: any; // Replace 'any' with the correct type
	pageReady: boolean;
	template: any; // Replace 'any' with the correct type
}

function GenerateInitialData(selectedInteractionData: InteractionData | undefined): SimulState {
	return {
        data: selectedInteractionData?.simultaneous?.usage_data ?? months.map((month) => ({
            month,
            peak: 0,
            non_peak: 0,
            total: 0,
        }))
    };
}

function simultaneousReducer(state: SimulState, action: SimulAction) {
	switch (action.type) {
	  case 'update': {
		return {
		  data: [...action.payload],
		};
	  }
	}
	throw Error('Unknown action: ' + action.type);
}

// Kleine work-around om de tabel te initialiseren
const temporaryIdMapping = {
	"8kkjhZqYAGJhNLNOVWZR": "Maandverbruik dal en piek",
	"onPWeg5mPk2qagcuOLo2": "Maandverbruik",
	"dUGGTplWlrZNpQ56B4OS": "Alleen totaalverbruik",
}
const temporaryInputTypeId = "XygNtUDvVNRMT1AnTC3A"

const SolarEnergySimultaneous: React.FC<SolarEnergyProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	generalData,
	pageReady,
	template,
}) => {
	const computationType = "solar_energy_computations";
	const { t } = useTranslation(); // Translation hook
	const [production, setProduction] = useState<number>(0);
	const [dataChanged, setDataChanged] = useState(false);
	const [existingExcel, setExistingExcel] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [calculationType, setCalculationType] = useState<string>("Standard");
	const [payloadIds, setPayloadIds] = useState<SolarParamIds>();
	const [allOptions, setAllOptions] = useState<Option[]>([]);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [graphData, setGraphData] = useState<SimulGraphData | null>(null);
	const selectedInteractionData = interactionData.find((interaction) => interaction.id === selectedInteraction);
	const [simultaneousState, simultaneousDispatch] = useReducer(simultaneousReducer, selectedInteractionData, GenerateInitialData);
	// Tijdelijke initializer voor simulCalc om de tabel te initialiseren
	const [simulCalcType, setSimulCalcType] = useState<SimulCalcType>(selectedInteractionData?.fields ? {type: temporaryIdMapping[selectedInteractionData.fields[temporaryInputTypeId] as keyof typeof temporaryIdMapping] as "Maandverbruik dal en piek" | "Maandverbruik" | "Alleen totaalverbruik"} : {type: "Alleen totaalverbruik"});

	const checkSimulInputs = async () => {
		const currentInteraction = interactionData.find((interaction) => interaction.id === selectedInteraction);
		
		if (currentInteraction && Object.keys(payloadIds || {}).length !== 6){
			const ids = await getSimulPayloadIds(currentInteraction);
			setPayloadIds(ids);
		}
		if (currentInteraction && currentInteraction.fields && payloadIds){
			let calcType = payloadIds["pv_simultaneous_input"];
			let elecUsage = payloadIds["electricity_usage"];
			let weekendDays = payloadIds["weekend_days"];
			let weekendDaysPerc = payloadIds["weekend_days_percentage"];
			let maxSimulPerc = payloadIds["max_simultanious_percentage"];
			let simulDays = payloadIds["pv_simultaneous_days"];

			return {
				pv_simultaneous_input: currentInteraction.fields[calcType],
				electricity_usage: currentInteraction.fields[elecUsage],
				weekend_days: currentInteraction.fields[weekendDays],
				weekend_days_percentage: currentInteraction.fields[weekendDaysPerc],
				max_simultanious_percentage: currentInteraction.fields[maxSimulPerc],
				pv_simultaneous_days: currentInteraction.fields[simulDays]
			}
		}
	};

	useEffect(() => {
		const currentInteraction = interactionData.find((interaction) => interaction.id === selectedInteraction);
		if (!currentInteraction) return;

		const status = currentInteraction.simultaneous && currentInteraction.simultaneous.usage_data && currentInteraction.simultaneous.usage_data.length > 0;

		if (status !== undefined && status !== pageReady) {
			mergeDoc(computationType, updateId, {
				progress: {
					3: {
						done: status,
						name: "Simultaneous",
					},
				},
			});
		}
	}, [interactionData, selectedInteraction, pageReady, updateId]);

	useEffect(() => {
		if (dataChanged === true) {
			mergeDoc(
				`${computationType}/${updateId}/computation_inputs`,
				selectedInteraction,
				{
					computation_present: false,
					pdf_present: false,
				}
			);
		}
	}, [dataChanged, selectedInteraction, updateId]);

	useEffect(() => {
		const initializeStates = async () => {
			const selectedInteractionData = interactionData.find((interaction) => interaction.id === selectedInteraction);
			if (!selectedInteractionData) return;

			if (!(graphData)){
				if (selectedInteractionData.simultaneous?.usage_data){
					const data: SimulGraphData = {
						simultaneous: selectedInteractionData.simultaneous.usage_data,
					};
					setGraphData(data);
				}
			}

			if (selectedInteractionData?.production && selectedInteractionData?.production.length > 0) {
				setProduction(selectedInteractionData?.production[0]?.kwh_production ?? 0);
			}

			const solarParams = await checkSimulInputs();
			if (allOptions.length === 0){
				const options = await getAllOptions();
				setAllOptions(options);
			}

			if (solarParams && allOptions){
				const updatedSolarParams = formatOptionFields(solarParams, allOptions);
				if (updatedSolarParams.pv_simultaneous_input){
					let calcType = updatedSolarParams.pv_simultaneous_input["name"];
					setSimulCalcType({type: calcType});
				}
			}
		};

		initializeStates();
	}, [interactionData, selectedInteraction]);

	function handleForceButtonClick(){
		if (loading === false){
			setDataChanged(true);
			calculateSimultaneous();
		}else{
			setErrorMessage("Please wait for the current calculation to finish");
		}
	}

	useEffect(() => {
		if (graphData?.simultaneous !== simultaneousState.data){
			setGraphData({simultaneous: simultaneousState.data});
		}
	}, [simultaneousState])

	const calculateSimultaneous = async () => {
		const solarParams = await checkSimulInputs();
		if (allOptions.length === 0){
			const options = await getAllOptions();
			setAllOptions(options);
		}
		if (solarParams && allOptions && simultaneousState) {
			try {
				const selectedInteractionData: InteractionData | undefined = interactionData.find((interaction) => interaction.id === selectedInteraction);
				const updatedSolarParams = formatOptionFields(solarParams, allOptions);

				// Fetch PV Simultaneous data
				setLoading(true);
				const summary_simultaneous = await fetchPVSimultaneous({
					computation_id: selectedInteraction,
					client_id: updateId,
					calculation_type: updatedSolarParams.pv_simultaneous_input,
					days: updatedSolarParams.pv_simultaneous_days,
					usage: simultaneousState.data,
					total_usage: updatedSolarParams.electricity_usage,
					production: production,
					weekend_days: updatedSolarParams.weekend_days,
					weekend_perc: updatedSolarParams.weekend_days_percentage,
					max_perc: updatedSolarParams.max_simultanious_percentage,
					language_version: generalData[0].fields?.["QQCT34RNyYnuu1UdpV9j"],
				});
				setLoading(false);

				if (summary_simultaneous?.data.usage_data) {
					simultaneousDispatch({type: 'update', payload: summary_simultaneous.data.usage_data});
				}

				// Merge computed data to Firestore
				if (selectedInteractionData?.fields) {
					if (summary_simultaneous?.data.generation_perc) {
						mergeDoc(
							`${computationType}/${updateId}/computation_inputs`,
							`${selectedInteraction}`,
							{
								simultaneous: summary_simultaneous.data,
								fields: {
									"9UFkhbqlhCGpHNiteEeA": summary_simultaneous.data.generation_perc,
								},
							}
						);
					}
				}
				setDataChanged(false);
			} catch (error) {
				console.error("Error fetching or merging data:", error);
			}
		} else {
			console.log("Invalid inputs");
		}
	};

	useEffect(() => {
		const selectedInteractionData: InteractionData | undefined =interactionData.find((interaction) => interaction.id === selectedInteraction);

		if (selectedInteractionData?.simultaneous && selectedInteractionData.simultaneous?.type) {
			setCalculationType(selectedInteractionData.simultaneous?.type);
		}
		else {
			setCalculationType("Standard");
		}

	}, [selectedInteraction, interactionData]);

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			<div>
				{selectedInteraction !== "" && (
					<div
						style={{
							...styles.rightPanelStyle,
							maxWidth: "400px",
							minHeight: "86vh",
							maxHeight: "90vh",
							marginLeft: "-10px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{" "}
							{t('simultaneous')} 
						</h3>
							<InputSpec
								updateId={updateId}
								interactionId={selectedInteraction}
								interactionData={interactionData}
								filter={"simultaneous"}
								templateUnfiltered={template}
								computationType={computationType}
							/>
																		
						<p
							style={{
								fontSize: "0.9rem",
								fontFamily: "basic",
								fontWeight: "bold",
							}}
						>
							{/* Replace 'value' with the actual value you want to display */}
							{t('calculatedSimultaneousPerc')}:{" "}
							{loading ? (<CircularProgress size={15}/>) : (
							Math.round(
								(interactionData.find(
									(interaction) => interaction.id === selectedInteraction
								)?.simultaneous?.generation_perc || 0) * 100
							) + "%")}
							<br />
							<br />
							{t("calculationType")}
							{calculationType === "Standard" ? t("simul_standard") : t("simul_quarter")}
						</p>
						<div style={{marginTop: "10px"}}>
							<p	style={{
								marginBottom: "10px",
								tabSize: 4,
								fontSize:"0.8rem"
								}}>
									<button onClick={handleForceButtonClick}>Run calculation</button>
									<span style={{marginLeft: "8px"}}>{loading ? (<CircularProgress size={15} />) : errorMessage}</span>
							</p>
						</div>				
					</div>
				)}
			</div>
			<div style={{marginLeft: "10px", minWidth: "400px"}}>
				<div
					style={{
						...styles.rightPanelStyle,
						minHeight: "86vh",
						maxHeight: "90vh",
						position: "relative",
					}}
				>
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{t('usageInput')} {/* Translated "Simultaneous Input" */}
					</h3>
						<>
						<EditableTable
							state={simultaneousState}
							dispatch={simultaneousDispatch}
							editType={simulCalcType}
						/>
						</>
					
						<ExcelUpload 
							path={`${computationType}/${updateId}/${selectedInteraction}/simul_data/consumption_data`}
							existingExcel={existingExcel}
							setExistingExcel={setExistingExcel} 
						/>
				</div>
			</div>
			<div
				style={{
					...styles.rightPanelStyle,
					minWidth: "400px",
					minHeight: "84vh",
					maxHeight: "86vh",
					marginLeft: "10px",
				}}
			>	
				<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{" "}
					{t('simulGraph')} 
				</h3>
				{graphData && production && (
					<div
						style={{
							...styles.rightPanelStyle,
							maxWidth: "400px",
							marginTop: "10px",
							marginLeft: "-10px",
						}}
					>
						<StackedBarChartWithLinePv
							data={graphData.simultaneous}
							production={production}
						/>
					</div>
					)}
			</div>
		</div>
	);
};

export default SolarEnergySimultaneous;
