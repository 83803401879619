import { fetchDocsData, fetchDocData } from "../../services/firebaseFunctions";
import { InteractionData, Option } from "./solarEnergyInterfaces";

const getAllOptions = async () => {
	const options = await fetchDocsData("options");
	return options.map((option: any) => {
		return option; // Return each individual option object
	});
};

const formatOptionFields = (clientInputs: { [key: string]: any }, options: Option[]) => {
    if (Object.keys(clientInputs).length > 0 && options.length > 0) {
            const updatedFields = Object.entries(clientInputs).reduce(
                (acc: { [key: string]: any }, [fieldId, fieldValue]) => {
                    const matchingOption = options.find(
                        (option) => option.id === fieldValue
                    );
                    if (matchingOption) {
                        acc[fieldId] = matchingOption; 
                    } else {
                        acc[fieldId] = fieldValue; 
                    }

                    return acc;
                },
                {}
            );
            return updatedFields;
        }
    return clientInputs;
};


const getSimulPayloadIds = async (currentInteraction: InteractionData) => {
    const payloadIds: any = {};
    const requiredFields = ["weekend_days", "weekend_days_percentage", "pv_simultaneous_input", 
                            "max_simultanious_percentage", "pv_simultaneous_days", "electricity_usage"];

    for (const key in currentInteraction.fields) {
        try {
            const docData = await fetchDocData(`client_inputs`, key);
            if (docData) {
                if (requiredFields.includes(docData.name_clean)) {
                    payloadIds[docData.name_clean] = key;
                }
            } else {
                console.log(`Document with key ${key} not found.`);
            }
        } catch (error) {
            console.error(`Error fetching document with key ${key}:`, error);
        } 
    }
    return payloadIds;
}

const months: string[] = [
	"jan",
	"feb",
	"mrt",
	"apr",
	"mei",
	"jun",
	"jul",
	"aug",
	"sep",
	"okt",
	"nov",
	"dec",
];

export { 
    getAllOptions,
    formatOptionFields,
    getSimulPayloadIds,
    months
};