import React, { useState, useEffect } from "react";
import { auth } from "../../../config/firebase";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  ProjectBdttabel,
  Project,
  ProjectBdttabelFieldConfig,
} from "../types/ProjectInterface";
import { mergeDoc, createDoc, removeDoc } from "../../../services/firebaseFunctions";
import { formatDate } from "../config/tarifConfig";
import { postPortfolioData } from "../../../services/apiFunctions";

interface BdtTabelProps {
  allProjectsBdt: ProjectBdttabel[];
  setAllProjectsBdt: (products: ProjectBdttabel[]) => void;
  selectedProject: Project | null;
  selectedProjectBdt: ProjectBdttabel | null;
  onSelectProjectBdt: (product: ProjectBdttabel | null) => void;
  projectBdttabelFieldConfigs: ProjectBdttabelFieldConfig[];
}

const BdtTabel: React.FC<BdtTabelProps> = ({
  allProjectsBdt,
  setAllProjectsBdt,
  selectedProject,
  selectedProjectBdt,
  onSelectProjectBdt,
  projectBdttabelFieldConfigs,
}) => {
  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [editedRow, setEditedRow] = useState<ProjectBdttabel | null>(null);
  const [newRow, setNewRow] = useState<ProjectBdttabel | null>(null);
  const [checkedRows, setCheckedRows] = useState<ProjectBdttabel[]>([]);

  // State for the copy dialog
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [copySource, setCopySource] = useState<string>("");

  // Filter out readonly fields
  const editableFields = projectBdttabelFieldConfigs.filter((config) => !config.readonly);
  const sortedEditableFields = editableFields.sort((a, b) => a.order_number - b.order_number);

  const filteredBdtRows = allProjectsBdt
    .filter(
      (row) =>
        row.project_number === selectedProject?.project_number && row.prefix === selectedProject?.prefix
    )
    .sort((a, b) => Number(a.fase_number) - Number(b.fase_number));

    useEffect(() => {
      if (!selectedProject || selectedProject.sales_tarrif === undefined) {
        console.error("Sales_tarrif is undefined of project:", selectedProject?.project_number);
      }
      
      recalculateCheckedRows();
    }, [allProjectsBdt, selectedProject]);
    

  const recalculateCheckedRows = () => {
    const filteredRows = allProjectsBdt.filter(
      (row) =>
        row.project_number === selectedProject?.project_number &&
        row.prefix === selectedProject?.prefix
    );
  
    const newCheckedRows = filteredRows.map((row) => {
      const hourCheck = Number(
        (
          Number(row.hour_total || 0) -
          (Number(row.hour_erp || 0) + Number(row.hour_ers || 0) + Number(row.hour_margin || 0)) -
          Number(row.price_3e || 0) / (selectedProject?.sales_tarrif || 1)
        ).toFixed(6)
      );
      return { ...row, hourCheck };
    });
  
    setCheckedRows(newCheckedRows);
  };
  


  // Calculate totals for hours and prices
  const erpTarrif = selectedProject?.erp_tarrif || 0;
  const ersTarrif = selectedProject?.ers_tarrif || 0;
  const salesTarif = selectedProject?.sales_tarrif || 0;

// Calculate each hour total separately.
const totalHourErp = filteredBdtRows.reduce((sum, row) => sum + (Number(row.hour_erp) || 0), 0);
const totalHourErs = filteredBdtRows.reduce((sum, row) => sum + (Number(row.hour_ers) || 0), 0);
const totalHourMargin = filteredBdtRows.reduce((sum, row) => sum + (Number(row.hour_margin) || 0), 0);
const totalHourTotal = filteredBdtRows.reduce((sum, row) => sum + (Number(row.hour_total) || 0), 0);
const totalPrice3e = filteredBdtRows.reduce((sum, row) => sum + (Number(row.price_3e) || 0), 0);

// Then calculate hour_check as the difference:
const hour_check = Number(
    (
      totalHourTotal -
      (totalHourErp + totalHourErs + totalHourMargin) -
      totalPrice3e / salesTarif
    ).toFixed(6)
  );



// Now build your totals object:
const totals = {
  hour_erp: totalHourErp,
  hour_ers: totalHourErs,
  price_3e: totalPrice3e,
  hour_total: totalHourTotal,
  hour_margin: totalHourMargin,
  hour_check: hour_check,
  price_erp: totalHourErp * erpTarrif,
  price_ers: totalHourErs * ersTarrif,
  price_total: totalHourTotal * salesTarif,

};
const totalsPrice = {
    total_erp: ((salesTarif-erpTarrif)*totalHourErp+(salesTarif-ersTarrif)*totalHourErs+totalHourMargin * salesTarif)/2+totals.price_erp ,
    total_ers: ((salesTarif-erpTarrif)*totalHourErp+(salesTarif-ersTarrif)*totalHourErs+totalHourMargin * salesTarif)/2+totals.price_ers,
    total_margin: (salesTarif-erpTarrif)*totalHourErp+(salesTarif-ersTarrif)*totalHourErs+totalHourMargin * salesTarif,
    price_margin: totalHourMargin * salesTarif,
    marge_hour_erp: (salesTarif-erpTarrif)*totalHourErp,
    marge_hour_ers: (salesTarif-ersTarrif)*totalHourErs,
    }

    const check_totals = Number(
        (
          totals.price_total -
          totalsPrice.total_erp -
          totalsPrice.total_ers -
          totals.price_3e
        ).toFixed(6)
      );

  const handleEditClick = (row: ProjectBdttabel) => {
    setEditingRowId(row.id);
    setEditedRow({ ...row });
    //console.log("Edit ",row.id)
  };

  // Open the copy dialog when the Copy button is clicked
  const handleCopyClick = () => {
    setOpenCopyDialog(true);
  };

  // Confirm the copy action from the selected project
  const handleConfirmCopy = async () => {
    if (!selectedProject) {
      alert("No project selected.");
      return;
    }
  
    if (!copySource) {
      alert("Please select a project.");
      return;
    }
  
    // Filter source rows based on the selected value.
    // Here copySource is a string in the format "prefix_projectNumber"
    const sourceRows = allProjectsBdt.filter(
      (row) => `${row.prefix}_${row.project_number}` === copySource
    );
  
    if (sourceRows.length === 0) {
      alert("No fases found for the selected project.");
      return;
    }
    
    const newRows: ProjectBdttabel[] = allProjectsBdt

    for (const sourceRow of sourceRows) {
      const newFaseNumber = sourceRow.fase_number;
      const newId = `${selectedProject.prefix}_${selectedProject.project_number}.${newFaseNumber}`;
      //console.log("newid", newId);
  
      const currentUser = auth?.currentUser?.displayName || "Unknown User";
  
      // Create a new row with the newId included for state purposes
      const newRowToAdd: ProjectBdttabel = {
        ...sourceRow,
        id: newId, // Explicitly set the new ID
        project_number: selectedProject.project_number,
        prefix: selectedProject.prefix,
        created_on: formatDate(new Date()),
        modified_on: formatDate(new Date()),
        modified_by: currentUser,
        owner: currentUser,
      };
  
      try {
        // Save the document to the database
        await createDoc("project_bdt_fases", newRowToAdd, newId);
  
        // Prepare payload for API posting
        const apiPayload = {
          ...newRowToAdd,
          rowkey: newId,
          collection: "project_bdt_fases",
          action: "created",
        };
  
        await postPortfolioData(apiPayload);
        newRows.push(newRowToAdd);
      } catch (error) {
        console.error("Error copying fase:", error);
      }
    }

    // Update the state with the new rows
    setAllProjectsBdt(newRows);
  
    // Close the dialog and reset the source selection
    setOpenCopyDialog(false);
    setCopySource("");
  };
  

  const handleCloseCopyDialog = () => {
    setOpenCopyDialog(false);
    setCopySource("");
  };

  const handleSaveClick = async () => {
    if (editedRow) {
        console.log("Edited Row. id", editedRow.id);
        console.log(selectedProject?.sales_tarrif);
        // Check if sales_tarrif is undefined and show an alert
        if (
          selectedProject?.sales_tarrif === undefined || 
          selectedProject.sales_tarrif === null || 
          isNaN(Number(selectedProject.sales_tarrif)) || 
          String(selectedProject.sales_tarrif).trim() === ""
          ) {
          alert("⚠️ Warning: 'Verkoop tarief' is niet ingevuld in dit project. Controleer de projectinstellingen.");
          }


        try {
            const updatedRow = {
                ...editedRow,
                modified_on: formatDate(new Date()),
                modified_by: auth.currentUser?.displayName || "Unknown User",
            };

            const apiPayload = {
                ...updatedRow,
                rowkey: updatedRow.id,
                collection: "project_bdt_fases",
                action: "updated",
            };

            await mergeDoc("project_bdt_fases", updatedRow.id, updatedRow);
            const apiResponse = await postPortfolioData(apiPayload);
            console.log("API Response:", apiResponse);

            const updatedBdt = allProjectsBdt.map((product) =>
                product.id === updatedRow.id ? updatedRow : product
            );

            setAllProjectsBdt(updatedBdt);
            setEditingRowId(null);
            setEditedRow(null);
        } catch (error) {
            console.error("Failed to save changes:", error);
        }
    }
};


  const handleCancelClick = () => {
    setEditingRowId(null);
    setEditedRow(null);
  };

  const handleDeleteClick = async (id: string) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this item?");
      if (confirmDelete) {
        await removeDoc("project_bdt_fases", id);
        const apiPayload = {
          rowkey: id,
          collection: "project_bdt_fases",
          action: "deleted",
          data: "test",
        };
        const apiResponse = await postPortfolioData(apiPayload);
        console.log("API Response:", apiResponse);
        const updatedBdt = allProjectsBdt.filter((product) => product.id !== id);
        setAllProjectsBdt(updatedBdt);
        onSelectProjectBdt(null);
      }
    } catch (error) {
      console.error("Failed to delete row:", error);
    }
  };

  const handleAddNewRow = async () => {
    if (!newRow || !selectedProject) {
      console.error("Cannot add row: Ensure a project is selected and all required fields are filled.");
      return;
    }
    const newFaseNumber = newRow.fase_number || 0;
    const newId = `${selectedProject.prefix}_${selectedProject.project_number}.${newFaseNumber}`;
    console.log(`Adding new row with ID: ${newId}`);
    const currentUser = auth?.currentUser?.displayName || "Unknown User";
    const rowToAdd: Omit<ProjectBdttabel, "id"> = {
      ...newRow,
      prefix: selectedProject.prefix,
      owner: currentUser,
      modified_by: currentUser,
      modified_on: formatDate(new Date()),
      created_on: formatDate(new Date()),
      project_number: selectedProject.project_number,
    };
    const apiPayload = {
      ...rowToAdd,
      rowkey: newId,
      collection: "project_bdt_fases",
      action: "created",
    };
    try {
      await createDoc("project_bdt_fases", rowToAdd, newId);
      const apiResponse = await postPortfolioData(apiPayload);
      console.log("API Response:", apiResponse);
      const newRowWithId = { ...rowToAdd, id: newId };
      setAllProjectsBdt([...allProjectsBdt, newRowWithId]);
      setNewRow(null);
      console.log("New row added successfully:", newRowWithId);
    } catch (error) {
      console.error("Failed to add new row:", error);
    }
  };

  const handleInputChange = (field: keyof ProjectBdttabel, value: string | number) => {
    if (editedRow) {
      setEditedRow({ ...editedRow, [field]: value });
    }
  };

  const handleNewRowInputChange = (field: keyof ProjectBdttabel, value: string | number) => {
    setNewRow((prev) => ({ ...prev, [field]: value } as ProjectBdttabel));
  };

  // Derive a unique list of projects from allProjectsBdt.
  // Each option is a string in the format "prefix_projectNumber".
  const uniqueProjects = Array.from(
    new Set(allProjectsBdt.map((row) => `${row.prefix}_${row.project_number}`))
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {sortedEditableFields.map((config) => (
                <TableCell key={config.key}>{config.label}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
              <TableCell>
                {totals.hour_check !== undefined && (totals.hour_check > 0.1 || totals.hour_check < -0.1) ? (
                <span style={{ color: "red", fontWeight: "bold" }}>Verschil</span>
                ) : null}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {/* Render the Copy button row when no filtered rows exist */}
            {filteredBdtRows.length === 0 && (
              <TableRow>
                <TableCell colSpan={sortedEditableFields.length + 1} align="center">
                  <Button variant="contained" color="primary" onClick={handleCopyClick}>
                    Kopieren van andere BDT
                  </Button>
                </TableCell>
              </TableRow>
            )}
            {checkedRows.map((row) => (
              <TableRow
                key={row.id}
                style={{
                   backgroundColor: row.hourCheck == undefined ? "inherit":  row.hourCheck > 0.1 || row.hourCheck < -0.1 ? "#ffcccc" : "inherit",
                }}
                onClick={() => onSelectProjectBdt(row)}
              >
                {editableFields.map((config) => (
                  <TableCell key={config.key}>
                    {editingRowId === row.id && !config.readonly ? (
                      <TextField
                        value={(editedRow as any)[config.key] || ""}
                        onChange={(e) => handleInputChange(config.key, e.target.value)}
                        type={config.type}
                      />
                    ) : (
                      row[config.key as keyof ProjectBdttabel] || 0
                    )}
                  </TableCell>
                ))}
                
                <TableCell>
                  {editingRowId === row.id ? (
                    <>
                      <Button onClick={handleSaveClick} color="primary">
                        Save
                      </Button>
                      <Button onClick={handleCancelClick} color="secondary">
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button onClick={() => handleEditClick(row)} color="primary">
                        Edit
                      </Button>
                      <Button onClick={() => handleDeleteClick(row.id)} color="error">
                        Delete
                      </Button>
                    </>
                  )}
                </TableCell>
                
                <TableCell>
                    {row.hourCheck !== undefined && (row.hourCheck > 0.1 || row.hourCheck < -0.1) && (
                      <span style={{ color: "red", fontWeight: "bold" }}>{row.hourCheck}</span>
                    )}
                </TableCell>
              </TableRow>
            ))}

            {/* Totals row */}
            <TableRow style={{ backgroundColor: "#f9f9f9", fontWeight: "bold" }}>
                {editableFields.map((config) => (
                    <TableCell key={config.key}>
                    {config.key === "hour_erp"
                        ? totals.hour_erp
                        : config.key === "hour_ers"
                        ? totals.hour_ers
                        : config.key === "hour_total"
                        ? totals.hour_total
                        : config.key === "hour_margin"
                        ? totals.hour_margin
                        : config.key === "description"
                        ? "Total uren"
                        : ""}
                    </TableCell>
                ))}
                <TableCell>
                {totals.hour_check !== undefined && (totals.hour_check > 0.1 || totals.hour_check < -0.1) ? (
                <span style={{ color: "red", fontWeight: "bold" }}>Verschil (h)</span>
                ) : null}</TableCell>
                <TableCell>
                {totals.hour_check !== undefined && (totals.hour_check > 0.1 || totals.hour_check < -0.1) ? (
                <span style={{ color: "red", fontWeight: "bold" }}>{totals.hour_check}</span>
                ) : null}
                </TableCell>
                </TableRow>


            <TableRow style={{ backgroundColor: "#f9f9f9", fontWeight: "bold" }}>
              {editableFields.map((config) => (
                <TableCell key={config.key}>
                  {config.key === "hour_erp"
                    ? `€ ${totals.price_erp.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "hour_ers"                    
                    ? `€ ${totals.price_ers.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "price_3e"
                    ? `€ ${totals.price_3e.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "description"
                    ? "Total kostprijs (€)"
                    : ""}
                </TableCell>
              ))}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: "#f9f9f9", fontWeight: "bold" }}>
              {editableFields.map((config) => (
                <TableCell key={config.key}>
                  {config.key === "hour_erp"
                    ? `€ ${totalsPrice.marge_hour_erp.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "hour_ers"
                    ? `€ ${totalsPrice.marge_hour_ers.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "hour_total"
                    ? `€ ${totalsPrice.total_margin.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "hour_margin"
                    ? `€ ${totalsPrice.price_margin.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    
                    : config.key === "description"
                    ? "Total marge (€)"
                    : ""}
                </TableCell>
              ))}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: "#f9f9f9", fontWeight: "bold" }}>
              {editableFields.map((config) => (
                <TableCell key={config.key}>
                  {config.key === "hour_erp"
                    ? `€ ${totalsPrice.total_erp.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "hour_ers"
                    ? `€ ${totalsPrice.total_ers.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "hour_total"
                    ? `€ ${totals.price_total.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`

                    : config.key === "price_3e"
                    ? `€ ${totals.price_3e.toLocaleString("nl-NL", { minimumFractionDigits: 2 })}`
                    : config.key === "description"
                    ? "Total kostprijs + marge (€)"
                    : ""}
                </TableCell>
              ))}
              <TableCell>
                {totals.hour_check !== undefined && (totals.hour_check > 0.1 || totals.hour_check < -0.1) ? (
                <span style={{ color: "red", fontWeight: "bold" }}>Verschil (€)</span>
                ) : null}</TableCell>
              <TableCell>
              {totals.hour_check !== undefined && (totals.hour_check > 0.1 || totals.hour_check < -0.1) ? (
              <span style={{ color: "red", fontWeight: "bold" }}>{check_totals}</span>
              ) : null}
              </TableCell>
              </TableRow>
            

            {/* New row input */}
            <TableRow>
              {editableFields.map((config) => (
                <TableCell key={config.key}>
                  <TextField
                    placeholder={config.label}
                    value={(newRow as any)?.[config.key] || ""}
                    onChange={(e) => handleNewRowInputChange(config.key, e.target.value)}
                    type={config.type}
                  />
                </TableCell>
              ))}
              <TableCell>
                <Button
                  onClick={handleAddNewRow}
                  color="primary"
                  disabled={!newRow || !newRow.fase_number || !newRow.description}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Copy Dialog with a dropdown of all projects */}
      <Dialog open={openCopyDialog} onClose={handleCloseCopyDialog}>
        <DialogTitle>Select project to copy fases from</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="copy-project-select-label">Project</InputLabel>
            <Select
              labelId="copy-project-select-label"
              value={copySource}
              label="Project"
              onChange={(e) => setCopySource(e.target.value as string)}
            >
              {uniqueProjects.map((project) => (
                <MenuItem key={project} value={project}>
                  {project}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCopyDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmCopy} color="primary">
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BdtTabel;
