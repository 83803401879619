import React, { useState, useEffect } from "react";
import Main from "../../layout/main/Main";
import { useTranslation } from "react-i18next";
import UnauthorizedPage from "../../screens/unauthorized";
import { auth } from "../../config/firebase";
import "../../assets/styles/new-styles.css";


// Define the URLs for each iframe first
const iframeUrls = {
	ProjectPortfolio:
		"https://app.powerbi.com/view?r=eyJrIjoiMjA1NDE2ZDAtYzQ2Yy00NTk1LTlhZDctODZmNTg0NTU0NmY1IiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9&pageName=5c80cd8700663f3a248b",
	PraeterReporter:
		"https://app.powerbi.com/view?r=eyJrIjoiYTZjNzI0NmItMTdmZi00OTI5LTg5ZTEtMTM1M2ZhNWE4MzVmIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	
	OHW:
		"https://app.powerbi.com/view?r=eyJrIjoiZjY1NWQxNTEtYmM5MC00NTY2LThkZDMtMzMzOTY2ZTZkNWI1IiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	LocatieKring:
		"https://app.powerbi.com/view?r=eyJrIjoiODczNWJjOTMtNDAzZi00ZGY3LWI5YWUtMTViNzRjZjU1MjcyIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	Inplannen:
		"https://app.powerbi.com/view?r=eyJrIjoiNTY4Mzc2MDAtZjQxMi00ZDkwLTk2MDYtZmQ0MTcxMTcyMzI2IiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	RapportageGemeente:
		"https://app.powerbi.com/view?r=eyJrIjoiMzkwNTFjNTQtMDZhYi00MjRkLWJkNTAtYjgyZTEyMmI3MmNlIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	VerkoperUitvoerder:
		"https://app.powerbi.com/view?r=eyJrIjoiMzdkZTFlNjktNmQ4OS00YWExLWE5MzItNjkxZjkwODE1ZmRlIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	QEM:
		"https://app.powerbi.com/view?r=eyJrIjoiNTdhMmVlYTEtNTUxZC00MjcxLTg0NjUtYWU2MzVkZjcyN2ZjIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	PraeterLtd:
		"https://app.powerbi.com/view?r=eyJrIjoiN2JiNjNjYjUtZTNkNi00MzVlLThlOTEtMzY3NDg2ZjhhYTllIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
};

const iframeUrlsExternal = {
	LocatieKring:
		"https://app.powerbi.com/view?r=eyJrIjoiODczNWJjOTMtNDAzZi00ZGY3LWI5YWUtMTViNzRjZjU1MjcyIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	Inplannen:
		"https://app.powerbi.com/view?r=eyJrIjoiNTY4Mzc2MDAtZjQxMi00ZDkwLTk2MDYtZmQ0MTcxMTcyMzI2IiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
};

const iframeUrlsERS= {
	ProjectPortfolio:
		"https://app.powerbi.com/view?r=eyJrIjoiMjA1NDE2ZDAtYzQ2Yy00NTk1LTlhZDctODZmNTg0NTU0NmY1IiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9&pageName=5c80cd8700663f3a248b",
	LocatieKring:
		"https://app.powerbi.com/view?r=eyJrIjoiODczNWJjOTMtNDAzZi00ZGY3LWI5YWUtMTViNzRjZjU1MjcyIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	Inplannen:
		"https://app.powerbi.com/view?r=eyJrIjoiNTY4Mzc2MDAtZjQxMi00ZDkwLTk2MDYtZmQ0MTcxMTcyMzI2IiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	RapportageGemeente:
		"https://app.powerbi.com/view?r=eyJrIjoiMzkwNTFjNTQtMDZhYi00MjRkLWJkNTAtYjgyZTEyMmI3MmNlIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
	QEM:
		"https://app.powerbi.com/view?r=eyJrIjoiNTdhMmVlYTEtNTUxZC00MjcxLTg0NjUtYWU2MzVkZjcyN2ZjIiwidCI6Ijk1OTNlM2JlLTQ0NmUtNGE4Mi05M2Q1LWI3OGY3MTAyODUxNiIsImMiOjl9",
};



// Now create the type based on the keys of iframeUrls and iframeUrlsExternal
type IframeKey = keyof typeof iframeUrls | keyof typeof iframeUrlsExternal | keyof typeof iframeUrlsERS;

const DashboardPage: React.FC = () => {
	const { t } = useTranslation();
	const [customClaims, setCustomClaims] = useState<{ [key: string]: boolean }>({});

	const buttonStyle = {
		margin: "0 10px",
		padding: "10px 20px",
		backgroundColor: "#007BFF",
		color: "#fff",
		border: "none",
		borderRadius: "4px",
		cursor: "pointer",
		fontSize: "16px",
	};

	useEffect(() => {
		const fetchCustomClaims = async () => {
			if (auth.currentUser) {
				try {
					// Fetch the custom claims
					const idTokenResult = await auth.currentUser.getIdTokenResult();
					const customClaims = idTokenResult.claims;
					setCustomClaims(customClaims); // All claims including custom ones
					console.log(customClaims); // You can use these claims to adjust UI components or logic
				} catch (error) {
					console.error("Error fetching custom claims", error);
				}
			} else {
				console.log("No user is currently signed in.");
			}
		};
		fetchCustomClaims();
	}, []);

	// Determine the iframeUrls to use based on customClaims
	const urlsToUse = 	customClaims?.["praeter-dashboard"] || customClaims?.["admin"] ? iframeUrls:
	 					customClaims?.["dash-ers"] ? iframeUrlsERS
						: iframeUrlsExternal
						
		
	// Set state for the current iframe URL
	const [currentIframe, setCurrentIframe] = useState<string>(
		urlsToUse.LocatieKring || "" // Default iframe URL
	);

	// Button click handler to switch iframe
	const handleIframeChange = (iframeKey: IframeKey) => {
		if (iframeKey in urlsToUse) {
			setCurrentIframe(urlsToUse[iframeKey as keyof typeof urlsToUse]);
		} else {
			console.error(`Iframe URL for ${iframeKey} not found.`);
		}
	};

	return customClaims ? (
		<Main>
			{/* DashboardingQuote section */}
			<div
				className="quote-style-border"
				style={{
					backgroundColor: "#000080",
					}}
			>
				<h2
					className= "quote-style-h2"
				>
					{t("DashboardingQuote")}
				</h2>
			</div>

			{/* Buttons placed under the DashboardingQuote */}
			<div
				className="div-box"
			>
				{/* Dynamically generate buttons for each iframe */}
				{(Object.keys(urlsToUse) as Array<IframeKey>).map((iframeKey) => (
					<button
						key={iframeKey}
						onClick={() => handleIframeChange(iframeKey)}
						className="button-style"
					>
						{t(iframeKey)}
					</button>
				))}
			</div>

			{/* Main content with iframe */}
			<div className="iframe-style">
				{/* Adjust the height based on the height of the header and buttons */}
				<iframe
					src={currentIframe}
					title="Power BI"
					className="full-screen"
					allowFullScreen
				></iframe>
			</div>
		</Main>
	) : (
		<UnauthorizedPage />
	);
};

export default DashboardPage;
