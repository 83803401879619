import React, {useEffect, useState} from "react";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useTranslation} from "react-i18next";

interface TableComponentProps {
	computations: any[];
	title: string;
	path: string;
	columns: {
		id: string;
		label: string;
		accessor: (comp: any) => React.ReactNode;
	}[];
	expanded_default?: boolean;
	stylingOptions?: React.CSSProperties;
	selectedItem?: any; 
	setSelectedItem?: (item: any) => void;
}

const TableComponent: React.FC<TableComponentProps> = ({
	computations,
	title,
	columns,
	expanded_default = false,
	stylingOptions = {},
	selectedItem,
	setSelectedItem,
}) => {
	const {t} = useTranslation(); // Initialize translation hook
	const [expanded, setExpanded] = useState(expanded_default); // Table is collapsed by default
	const [filteredComputations, setFilteredComputations] = useState(computations.slice(0, 10)); // Display only 10 rows by default
	const [pageNumber, setPageNumber] = useState(0); // Initialize page number
	const pages = Math.ceil(computations.length / 10); // Calculate number of pages
	const [currentItems, setCurrentItems] = useState(filteredComputations.length); // Initialize items seen

	useEffect(() => {
		const start = pageNumber === 0 ? 0 : pageNumber * 10;
		const end = Math.min((pageNumber + 1) * 10, computations.length);
		setFilteredComputations(computations.slice(start, end));

		const cumItemsShown = Math.min(filteredComputations.length + start, computations.length);
		setCurrentItems(cumItemsShown);
	}, [pageNumber, computations]);

	const cellStyle = {
		maxWidth: "50px",
		whiteSpace: "nowrap",
		fontFamily: "karla",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};

	const handleRowClick = (item: any) => {
        if (setSelectedItem) {
            setSelectedItem(item);
			setExpanded(false);
        }
    };

	function nextPage() {
		if (pageNumber < pages - 1) {
			setPageNumber(pageNumber + 1);
		}
	}
	function prevPage() {
		if (pageNumber > 0) {
			setPageNumber(pageNumber - 1);
		}
	}

    const rowStyle = (item: any) => ({
        cursor: setSelectedItem ? "pointer" : "default",
        backgroundColor: selectedItem?.id === item.id ? "#e0e0e0" : "white", // Highlight selected row
    });

	return (
		<Accordion
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			style={{
				fontSize: "0.7rem",
				backgroundColor: "white",
				fontFamily: "Basic",
				color: "#595959",
				flex: 1,
				padding: "5px",
				borderRadius: "5px",
				marginBottom: "10px",
				overflow: "auto",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				...stylingOptions,
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography style={{fontFamily: "basic", fontWeight: "bold"}}>
					{title}{" "}
					<span style={{fontWeight: "bold", fontFamily: "basic"}}>
						({currentItems}/{computations.length})
					</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
			{pages > 1 && (<div><Button onClick={prevPage}>Prev page</Button> 
								<Button onClick={nextPage}>Next page</Button>
							</div>)}
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.id} style={cellStyle}>
										{t(column.label)}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredComputations.map((comp) => (
								<TableRow 
								   key={comp.id}
								   style={rowStyle(comp)}
                                   onClick={() => handleRowClick(comp)}
								>
									{columns.map((column) => (
										<TableCell key={column.id} style={cellStyle}>
											{column.accessor(comp)}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</AccordionDetails>
		</Accordion>
	);
};

export default TableComponent;
