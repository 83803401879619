import React, {useEffect, useState} from "react";
import Main from "../../layout/main/Main";
import {auth} from "../../config/firebase";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {Paper, Container, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";
import { fetchDocData, queryCollection } from "../../services/firebaseFunctions";
import TableComponent from "../../components/utils/renderTable";

interface WelcomePageProps {}

const styles: {[key: string]: React.CSSProperties} = {
	container: {
		backgroundColor: "#f0f0f0",
		height: "100%",
		width: "100vw",
	},
	content: {
		textAlign: "center",
		padding: "20px",
		flex: 1,
	},
	description: {
		fontSize: "16px",
		marginBottom: "20px",
		fontFamily: "karla",
	},
};

const WelcomePage: React.FC<WelcomePageProps> = () => {
	const {t} = useTranslation();
	const [loggedInUser, setLoggedInUser] = useState<string | undefined>("");
	const [lastLogin, setLastLogin] = useState<string | undefined>("");
	const [computationsSolarEnergy, setComputationsSolarEnergy] = useState<any[]>(
		[]
	);
	const [computationsEnergySaving, setComputationsEnergySaving] = useState<
		any[]
	>([]);
	const [computationsEnergyScreening, setComputationsEnergyScreening] =
		useState<any[]>([]);
	const [computationsSolarRental, setComputationsSolarRental] = useState<any[]>(
		[]
	);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user: any) => {
			if (user && user.displayName) {
				setLoggedInUser(user.displayName);
				setLastLogin(user.metadata.lastSignInTime);
				fetchComputationsEnergySaving(user.uid);
				fetchComputationsEnergyScreening(user.uid);
				fetchComputationsSolarEnergy(user.uid);
				fetchComputationsSolarRental(user.uid);
			} else {
				setLoggedInUser(undefined);
				setLoading(false);
			}
		});

		return () => unsubscribe();
	}, []);

	const fetchComputations = async (
		collectionName: string,
		queryParams: any,
		setState: any,
		userId: string
	) => {
		setLoading(true);

		try {
			const computationsData = await queryCollection(collectionName, {
				created_by: userId,
				...queryParams,
			});

			if (!computationsData) {
				throw new Error(`No data found in ${collectionName}`);
			}

			const general_inputsPromises = computationsData.map(async (doc) => {
				if (!doc) {
					return null;
				}

				try {
					const general_inputs = await fetchDocData(
						`${collectionName}/${doc.id}/general_inputs`,
						"all"
					);
					return {...doc, general_inputs};
				} catch (error) {
					console.error(
						`Error fetching additional data for document ${doc.id}: `,
						error
					);
					return {...doc, general_inputs: null};
				}
			});

			const computationsWithAdditionalData = await Promise.all(
				general_inputsPromises
			);
			const validComputations = computationsWithAdditionalData.filter(
				(doc) => doc !== null
			);

			setState(validComputations);
		} catch (error) {
			console.error(`Error fetching data from ${collectionName}: `, error);
		} finally {
			setLoading(false);
		}
	};

	const fetchComputationsEnergySaving = (userId: any) => {
		fetchComputations(
			"energy_saving_computations",
			{},
			setComputationsEnergySaving,
			userId
		);
	};

	const fetchComputationsSolarEnergy = (userId: any) => {
		fetchComputations(
			"solar_energy_computations",
			{},
			setComputationsSolarEnergy,
			userId
		);
	};

	const fetchComputationsEnergyScreening = (userId: any) => {
		fetchComputations(
			"energy_screening_computations",
			{},
			setComputationsEnergyScreening,
			userId
		);
	};

	const fetchComputationsSolarRental = (userId: any) => {
		fetchComputations(
			"solar_rental_computations",
			{},
			setComputationsSolarRental,
			userId
		);
	};

	const columns = (path: string) => [
		{
			id: "routeClientId",
			label: "routeClientId",
			accessor: (comp: any) => comp.id,
		},
		{
			id: "organisationName",
			label: "organisationName",
			accessor: (comp: any) =>
				comp.general_inputs?.fields?.YfRokLRi9yJOY7TMKo0k,
		},
		{
			id: "lastModified",
			label: "lastModified",
			accessor: (comp: any) => comp.updated_at?.toDate().toLocaleDateString(),
		},
		{
			id: "reportReady",
			label: "reportReady",
			accessor: (comp: any) =>
				comp.general_inputs?.report_ready ? (
					<CheckCircleIcon color="success" />
				) : (
					<CancelIcon color="error" />
				),
		},
		{
			id: "reportLink",
			label: "reportLink",
			accessor: (comp: any) => (
				<a
					href={comp.general_inputs?.report_link}
					target="_blank"
					rel="noopener noreferrer"
				>
					{comp.general_inputs?.report_link}
				</a>
			),
		},
		{
			id: "open",
			label: "open",
			accessor: (comp: any) => (
				<a
					href={`https://dash.praeter.nl/${path}/${comp.id}/initialize`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{`https://dash.praeter.nl/${path}/${comp.id}/initialize`}
				</a>
			),
		},
	];

	return (
		<Main>
			<Container style={{...styles.container, minWidth: "100vw"}}>
				<Paper
					elevation={3}
					style={{
						padding: "20px",
						width: "90%",
					}}
				>
					<h1 style={{fontSize: "20px"}}>
						{t("welcomeMessage", {user: loggedInUser || t("guest")})}
					</h1>
					<p style={styles.description}>
						{t("lastLogin", {lastLogin: lastLogin || ""})}
					</p>
					<p style={styles.description}>{t("developmentMessage")}</p>
					<p style={styles.description}>
						{t("feedbackMessage")}{" "}
						<a href={`mailto:${t("emailLink")}`}>{t("emailLink")}</a>
					</p>
				</Paper>
				<h2 style={{fontSize: "20px", marginTop: "20px"}}>
					{t("lastCalculationsTitle")}
				</h2>
				{loading ? (
					<CircularProgress />
				) : (
					<div style={{maxWidth: "70vw"}}>
					<>
						{computationsSolarEnergy.length > 0 && (
							<TableComponent
								computations={computationsSolarEnergy}
								title={t("solarEnergyTitle")}
								path="solar-energy"
								columns={columns("solar-energy")}
							/>
						)}
						{computationsSolarRental.length > 0 && (
							<TableComponent
								computations={computationsSolarRental}
								title={t("solarRentalTitle")}
								path="solar-rental"
								columns={columns("solar-rental")}
							/>
						)}
						{computationsEnergySaving.length > 0 && (
							<TableComponent
								computations={computationsEnergySaving}
								title={t("energySavingTitle")}
								path="energy-saving"
								columns={columns("energy-saving")}
							/>
						)}
						{computationsEnergyScreening.length > 0 && (
							<TableComponent
								computations={computationsEnergyScreening}
								title={t("energyScreeningTitle")}
								path="energy-screening"
								columns={columns("energy-screening")}
							/>
						)}
					</>
					</div>
				)}
			</Container>
		</Main>
	);
};

export default WelcomePage;
