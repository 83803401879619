import React, { useEffect, useState } from "react";
import {styled} from "@mui/system";
import {Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TextField } from "@mui/material";
import { useTranslation} from "react-i18next"; 
import { SimulState, SimulAction, UsageRecord, SimulCalcType } from "../../layout/solar-energy/solarEnergyInterfaces";

const StyledTable = styled(Table)({
	maxWidth: 200,
	maxHeight: 20,
});

const StyledTableCell = styled(TableCell)`
	font-size: 14px;
	font-family: Karla;
	padding: 11px; /* Adjust padding if needed */
	& input {
		max-height: 8px;
		font-size: 14px;
		font-family: Karla;
	}
`;

const StyledInput = styled(TextField)({
	minWidth: "80px",
	"& input": {
		maxHeight: "8px",
		fontSize: "14px",
		fontFamily: "Karla",
	},
	marginTop: "-10px",
	marginBottom: "-10px",
	marginLeft: "-20px",
});

// editType: "Alleen totaalverbruik" | "Maandverbruik"  | "Maandverbruik dal en piek";
interface EditableTableProps {
	state: SimulState;
	dispatch: React.Dispatch<SimulAction>;
	editType: SimulCalcType;
}

const EditableTable: React.FC<EditableTableProps> = ({
	state,
	dispatch,
	editType
}) => {
	const {t} = useTranslation(); // Translation hook
	const [data, setData] = useState<UsageRecord[]>(state.data);

	const handleInputChange = (event: any, index: number, key: string) => {

		if (key !== "total") {
			const newData: any = [...data];
			newData[index][key] = Number(event.target.value);
			newData[index]["total"] = newData[index]["non_peak"] + newData[index]["peak"];
			setData(newData);
		} 
		else if (key === "total") {
			const newData = [...data];
			newData[index]["total"] = Number(event.target.value);
			newData[index]["non_peak"] = Math.round(Number(event.target.value) * 0.31);
			newData[index]["peak"] = Math.round(Number(event.target.value) * 0.69);
			setData(newData);
		}
	};

	useEffect(() => {
		const payloadData = {
			type: 'update',
			payload: data
		}
		dispatch(payloadData)
	}, [data])

	const calculateColumnTotals = () => {
		let peakTotal = 0;
		let offPeakTotal = 0;
		let totalTotal = 0;

		data.forEach((row) => {
			peakTotal += parseFloat(row.peak as any);
			offPeakTotal += parseFloat(row.non_peak as any);
			totalTotal += parseFloat(row.total as any);
		});

		return {peakTotal, offPeakTotal, totalTotal};
	};

	const columnTotals = calculateColumnTotals();

	return (
		<>
			<TableContainer component={Paper} style={{boxShadow: "none"}}>
				<StyledTable aria-label="editable table">
					<TableHead>
						<TableRow>
							<StyledTableCell>{t("month")}</StyledTableCell>
							<StyledTableCell align="right">{t("peak")}</StyledTableCell>
							<StyledTableCell align="right">{t("offPeak")}</StyledTableCell>
							<StyledTableCell align="right">{t("total")}</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, index) => (
							<TableRow key={row.month}>
								<StyledTableCell component="th" scope="row">
									{t(`months.${row.month}`)}
								</StyledTableCell>
								<StyledTableCell align="right">
									<StyledInput
										type="number"
										value={row.peak}
										onChange={(event) =>
											handleInputChange(event, index, "peak")
										}
										disabled={[
											"Maandverbruik",
											"Alleen totaalverbruik"
										].includes(editType?.type ?? "Alleen totaalverbruik")}
									/>
								</StyledTableCell>
								<StyledTableCell align="right">
									<StyledInput
										type="number"
										value={row.non_peak}
										onChange={(event) =>
											handleInputChange(event, index, "non_peak")
										}
										disabled={[
											"Maandverbruik",
											"Alleen totaalverbruik"
										].includes(editType?.type ?? "Alleen totaalverbruik")}
									/>
								</StyledTableCell>
								<StyledTableCell align="right">
									<StyledInput
										type="number"
										value={row.total}
										onChange={(event) =>
											handleInputChange(event, index, "total")
										}
										disabled={[
											"Maandverbruik dal en piek",
											"Alleen totaalverbruik"
										].includes(editType?.type ?? "Alleen totaalverbruik")}
									/>
								</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</StyledTable>
			</TableContainer>
			<div
				style={{
					marginTop: "20px",
					fontFamily: "karla",
					fontSize: "14px",
					fontWeight: "bold",
				}}
			>
				<span>
					{t("peakTotal")}: {columnTotals.peakTotal}
				</span>
				<span style={{marginLeft: "20px"}}>
					{t("offPeakTotal")}: {columnTotals.offPeakTotal}
				</span>
				<span style={{marginLeft: "20px"}}>
					{t("totalTotal")}: {columnTotals.totalTotal}
				</span>
			</div>
		</>
	);
};

export default EditableTable;
