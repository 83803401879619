import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InputSpec from "../../components/computation-input-specific/InputSpecific";
import {mergeDoc} from "../../services/firebaseFunctions";
import { fetchSectorOptions } from "../../services/apiFunctions";
import {styles} from "../../components/utils/Styles";
import GeneralProjectInput from "../../components/general-project-input/index-temp";
import { indexOf } from "lodash";

interface Interaction {
	id: string;
	fields: {
		fbzu2wmWI3MdG2BcWnIs: string;
	};
	production?: any; // Update the type according to your data structure
	sector?: {
		sector?: string;
	};
}

interface Template {
	[key: string]: any;
}

interface Props {
	updateId: string;
	generalData: any[];
	selectedInteraction: string;
	interactionData: Interaction[];
	pageReady: boolean;
	template: Template;
}

const SolarBatteryComputationSpecs: React.FC<Props> = ({
	updateId,
	generalData,
	selectedInteraction,
	interactionData,
	pageReady,
	template,
}) => {
	const {t} = useTranslation();
	const [sectorOptions, setSectorOptions] = useState<[]>([]);
	const [selectedSector, setSelectedSector] = useState<string>(generalData[0]?.fields?.["KA8AxfCwvoa6yGaC1DAo"] || "");

	useEffect(() => {
		const fetchData = async () => {
			try {
				fetchSectorOptions().then((data) => {
					setSectorOptions(data?.data);
				});

			} catch (error) {
				console.error("Error fetching sector options:", error);
			}
		};
		fetchData();
	}, []);

	const handleSectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newValue = event.target.value as string;
		if (newValue) {
			mergeDoc(
				`solar_battery_computations/${updateId}/general_inputs`,
				"all",
				{
					fields: {
						KA8AxfCwvoa6yGaC1DAo: newValue,
					}
				}
			);
		}
		setSelectedSector(newValue);
	}

	useEffect(() => {
		if (interactionData) {
			let status = false;
			if (interactionData.length > 0) {
				// status = areAllFieldsFilled(template, interactionData[0]?.fields);
				status = true; // Change this to your logic
			}
			if (!(status === pageReady)) {
				mergeDoc("solar_battery_computations", updateId, {
					progress: {
						1: {
							done: status,
							name: "Computation specs", // Translation key for "Computation specs"
						},
					},
				});
			}
		}
	}, [template, interactionData, updateId, pageReady, t]);

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			{/* Left Panel */}
			{selectedInteraction !== "" && Object.keys(template).length > 0 && (
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "-10px",
							minWidth: "280px",
						}}
					>
                        <h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("BatterySpecs")}{" "}
						</h3>
						<InputSpec
							updateId={updateId}
							interactionId={selectedInteraction}
							interactionData={interactionData}
							templateUnfiltered={template}
							filter={"battery_specs"} 
							computationType={"solar_battery_computations"}
						/>
					</div>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "10px",
							minWidth: "250px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("financialData")} 
						</h3>
						<InputSpec
							updateId={updateId}
							interactionId={selectedInteraction}
							interactionData={interactionData}
							templateUnfiltered={template}
							filter={"financial_data_bat"} 
							computationType={"solar_battery_computations"}
						/>
					</div>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "10px",
							minWidth: "250px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("templateData")} 
						</h3>
						<label
							style={{fontSize: "14px", fontFamily: "Karla", fontWeight: "bold"}}
						>
							{t("companyType")}
						</label>
						{sectorOptions.length > 0 && (
						<div style={{marginBottom: "10px"}}>
						<select
							value={selectedSector || ""}
							onChange={(e) => handleSectorChange(e)}
							style={styles.input}
						>
							<option value="" disabled>
								Select a sector
							</option>
							{sectorOptions.map((sector, index) => (
								<option key={index} value={sector}>
									{sector}
								</option>
							))}
						</select>
						</div>
						)} 
						<GeneralProjectInput
							updateId={updateId}
							generalData={generalData}
							templateUnfiltered={template}
							computationType={"solar_battery_computations"}
							filter={"bat_template_variables"}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default SolarBatteryComputationSpecs;