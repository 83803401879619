import './components/utils/promiseWithResolversPolyfill';
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { ReportHandler, Metric } from 'web-vitals';
import "./i18n"; // Import the i18n configuration

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from './context/Themes/ThemeContext';

i18n
  .use(initReactI18next)
  .init({
    // lng: 'en', // Default language
    // fallbackLng: 'en',
    debug: false, // Disable debug mode (optional)
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      // Log missing keys or handle them here
      // console.warn(`Missing translation for key: "${key}" in namespace "${ns}" for language "${lng}".`);
    },
    returnNull: false, // Return an empty string for missing translations instead of `null`
    saveMissing: true,
    // Alternatively, use returnEmptyString: false to suppress missing keys altogether.
  });

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(handleWebVitals);
