import React, {useEffect, useState} from "react";
import { fetchDocData, mergeDoc, removeDoc } from "../../services/firebaseFunctions";
import FinancialOverview from "../../components/computation-financials/FinancialElement";
import {fetchBatteryComputation, fetchPDFTemplate} from "../../services/apiFunctions";
import { getAllOptions, formatOptionFields, formatClientInputs } from "../../layout/solar-battery/batteryFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import { Option, InteractionDataItem, batCostObject } from "../../layout/solar-battery/batteryInterfaces";

const allInteractionsPresent = (recordList: InteractionDataItem[]): boolean => {
	if (!recordList || recordList.length === 0) {
		return false;
	}
	return recordList.every(
		(record) =>
			record.computation_present === true && record.pdf_present === true
	);
};

interface Props {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionDataItem[];
	template: any; // Update the type accordingly
	generalData: any;
	pageReady: boolean;
}

const SolarBatteryResultSummary: React.FC<Props> = ({
	updateId,
	selectedInteraction,
	interactionData,
	template,
	generalData,
	pageReady,
}) => {
	const [displayData, setDisplayData] = useState<any>({});
	const [loading, setLoading] = useState<boolean>(false); // State to track loading state
	const [error, setError] = useState<boolean>(false); // State to track error state
	const {t} = useTranslation();

	useEffect(() => {
		if (interactionData) {
			if (interactionData.length > 0) {
				let status = allInteractionsPresent(interactionData);

				if (!(status === pageReady)) {
					mergeDoc("solar_battery_computations", updateId, {
						progress: {
							6: {
								done: status,
								name: "Computation results",
							},
						},
					});
				}
			}
		}
	}, [interactionData, updateId]);

	const functionCallResult = async (
		updateId: string,
		interactionId: string,
		requestPayload: any // Update the type accordingly
	) => {
		if (error === false) {
			const result = await fetchBatteryComputation(
				updateId,
				interactionId,
				requestPayload
			);
			if (result.data && result.success && interactionId && interactionId !== "") {
				mergeDoc(
					`solar_battery_computations/${updateId}/results`,
					`${interactionId}`,
					{inputs: requestPayload, outputs: result.data}
				);
				mergeDoc(
					`solar_battery_computations/${updateId}/computation_inputs`,
					`${interactionId}`,
					{computation_present: true, pdf_present: false}
				);
			} else {
				setError(true);
				setDisplayData({});
			}
		}
		return true;
	};

	useEffect(() => {
		const promiseArray = interactionData.map(async (interaction) => {
			if (
				interaction.computation_present === true &&
				interaction.pdf_present === false &&
				interaction.id
			) {
				// Return the Promise.all result for parallel execution of mergeDoc and fetchPDFTemplate
				return Promise.all([
					mergeDoc(
						`solar_battery_computations/${updateId}/computation_inputs`,
						`${interaction.id}`,
						{computation_present: true, pdf_present: true}
					),
					fetchPDFTemplate(
						updateId,
						interaction.id,
						"solar_battery_computations"
					),
				]);
			} else {
				// Return a resolved promise for interactions that do not meet the condition
				return Promise.resolve(null);
			}
		});

		// Use Promise.allSettled to wait for all promises to settle
		Promise.allSettled(promiseArray)
			.then((results) => {
				results.forEach((result, index) => {
					if (result.status === "fulfilled" && result.value) {
						console.log(`Operation ${index + 1} succeeded, pdf`, result);
					}
				});
			})
			.catch((error) => {
				console.error("An error occurred:", error);
			});
	}, [interactionData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (
					interactionData &&
					interactionData.length > 0 &&
					interactionData.find((item) => item.id === selectedInteraction)
						?.computation_present
				) {
					const updateDisplay = await fetchDocData(
						`solar_battery_computations/${updateId}/results`,
						selectedInteraction
					);
					if (updateDisplay) {
						setDisplayData(updateDisplay.outputs);
					}
				} else {
					setDisplayData({});
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [selectedInteraction, updateId, loading, interactionData]);

	useEffect(() => {
		const fetchData = async () => {
			if (interactionData.some((interaction) => !interaction.computation_present) &&!loading) {
				setLoading(true);
				try {
					const allDataFields = await formatClientInputs(generalData[0]);
					const allOptions = await getAllOptions();
					
					await handleInteractionsParallel(interactionData, allDataFields, allOptions);
					console.log("All interactions have been processed.");
				} catch (error) {
					console.error("An error occurred during the processing:", error);
				} finally {
					setLoading(false);
				}
			}
		};
		if (loading === false && interactionData.length > 0) {
			fetchData();
		}
	}, [updateId, interactionData]);

	const isValidCostObject = (cost: any): cost is batCostObject => {
		return (
		  cost !== null &&
		  typeof cost === "object" &&
		  typeof cost.frequency === "string" &&
		  typeof cost.id === "string" &&
		  typeof cost.name === "string" &&
		  typeof cost.name_clean === "string" &&
		  typeof cost.value === "number"
		);
	  };

	async function handleInteractionsParallel(
		interactionData: any,
		allDataFields: any,
		allOptions: Option[]
	) {
		const ph: Record<string, any> = {};
		const interactionPromises = interactionData
			.filter((interaction: any) => !interaction.computation_present)
			.map(async (interaction: any) => {
				const graphData = {
					calc_data: interaction.quarterly,
					daily_data: interaction.daily_data,
					simul_data: interaction.simultaneous
				}
				const requestPayload = {
					client_inputs: {...allDataFields},
					battery_calcs: ph,
					costs: interaction.costs,
					facts: [],
					simultaneous: interaction.simultaneous,
					daily: interaction.daily_data,
					production: interaction.production,
					graph_data: graphData,
					quarterly_prepped: interaction.quarterly_prepped,
				};

				for (const key in interaction.fields) {
					if (interaction.fields.hasOwnProperty(key)) {
						const fieldValue = interaction.fields[key];
						try {
							const docData = template[key]; // Assuming template is available in the scope
							requestPayload.client_inputs[docData.name_clean] = fieldValue;
						} catch (error) {
							const docData = await fetchDocData("client_inputs", key);
							requestPayload.client_inputs[docData.name_clean] = fieldValue;
						}
					}
				}
				if (interaction.quarterly) {
					interaction.quarterly.calculated_data.forEach((item: any) => {
						requestPayload.battery_calcs[item.name_clean] = item.value;
					});
					interaction.quarterly.output_data.forEach((item: any) => {
						requestPayload.battery_calcs[item.name_clean] = item.value;
					});
					interaction.quarterly.percentage_data.forEach((item: any) => {
						requestPayload.battery_calcs[item.name_clean] = item.value;
					});
				}
				if (requestPayload.costs && requestPayload.costs.length > 0) {
					requestPayload.costs = requestPayload.costs.filter(isValidCostObject);
				}
				// Perform operations required before the functionCallResult in sequence
				await removeDoc(
					`solar_battery_computations/${updateId}/results`,
					interaction.id
				);
				await mergeDoc(
					`solar_battery_computations/${updateId}/general_inputs`,
					"all",
					{report_ready: false}
				);
				requestPayload.client_inputs = formatOptionFields(requestPayload.client_inputs, allOptions);
				requestPayload.battery_calcs = formatOptionFields(requestPayload.battery_calcs, allOptions);

				// Then call the main function
				return await functionCallResult(
					updateId,
					interaction.id,
					requestPayload
				);
			});

		const results = await Promise.allSettled(interactionPromises);
		results.forEach((result, index) => {
			if (result.status === "fulfilled") {
				console.log(`Interaction ${index + 1} succeeded:`, result.value);
			} else {
				console.error(`Interaction ${index + 1} failed:`, result.reason);
			}
		});
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				minWidth: "60vw",
			}}
		>
			{!(displayData && Object.keys(displayData).length > 0) &&
			loading === true ? ( // Render loading spinner if loading is true
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Barlow",
							marginTop: "150px",
						}}
					>
						{t("computationLoading")}
					</h6>
					<CircularProgress />
				</div>
			) : (
				displayData &&
				Object.keys(displayData).length > 0 &&
				displayData.financial && (
					<FinancialOverview
						displayData={displayData}
						computationType="solar_battery_computations"
					/>
				)
			)}
		</div>
	);
};

export default SolarBatteryResultSummary;
