import React, { useState } from "react";
import { Button, TextField, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { ProjectProduct, ProjectProductFieldConfig } from "../types/ProjectInterface";
import { Product_group, ProductTimes ,ProductVersion } from "../types/ProductInterface";
import { mergeDoc, createDoc, removeDoc } from "../../../services/firebaseFunctions";
import { auth } from "../../../config/firebase";
import { version } from "os";
import {formatDate} from "../config/tarifConfig"
import {postPortfolioData} from "../../../services/apiFunctions"


interface RelatedProductsDetailsProps {
    selectedProjectProduct: ProjectProduct | null;
    setAllProjectsProducts: (products: ProjectProduct[]) => void;
    allProjectsProducts: ProjectProduct[];
    onSelectProjectProduct: (product: ProjectProduct | null) => void;
    projectPrefix: string;
    selectedProject: number;
    projectnumber: number;
    allProductGroups: Product_group[]; // Available product groups
    allProductVersions: ProductVersion[]; // Available product versions
    allProductTimes: ProductTimes[]; // Available product times
    projectProductFieldConfigs: ProjectProductFieldConfig[];
}

const RelatedProductsDetails: React.FC<RelatedProductsDetailsProps> = ({
    selectedProjectProduct,
    setAllProjectsProducts,
    selectedProject,
    allProjectsProducts,
    onSelectProjectProduct,
    projectPrefix,
    allProductGroups,
    allProductVersions,
    allProductTimes,
    projectnumber,
    projectProductFieldConfigs,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedProduct, setEditedProduct] = useState<ProjectProduct | null>(null);

    const [isDialogOpen, setIsDialogOpen] = useState(false); // Control for the pop-up dialog
    const [selectedProductGroup, setSelectedProductGroup] = useState<string | null>(null);
    const [selectedProductVersion, setSelectedProductVersion] = useState<string | null>(null);
    

    
    
    const openDialog = () => {
        setIsDialogOpen(true);
        
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
        setSelectedProductGroup(null);
        setSelectedProductVersion(null);
    };

    const handleCreateProduct = async () => {
        if (!selectedProductGroup || !selectedProductVersion) {
            alert("Please select both a product group and a product version.");
            return;
        }

        // Find the selected product group object
        const productGroup = allProductGroups.find(
            (group) => group.product_number.toString() === selectedProductGroup.toString()
        );

        if (!productGroup) {
            console.error("Selected product group not found.");
            return;
        }

        // Find the selected product version object
        const productVersion = allProductVersions.find(
            (version) => version.id === selectedProductVersion
        );

        if (!productVersion) {
            console.error("Selected product version not found.");
            return;
        }

        
        // **Filter Product Times for the Selected Version**
        const relatedProductTimes = allProductTimes.filter(
            (time) =>
                time.version_number === productVersion.product_group_version_number &&
                time.prefix === productVersion.prefix
        );

        console.log("Related Product Times:", relatedProductTimes);

        // **Sum min_erp and min_ers**
        const totalMinErp = relatedProductTimes.reduce((sum, time) => sum + (time.min_erp || 0), 0);
        const totalMinErs = relatedProductTimes.reduce((sum, time) => sum + (time.min_ers || 0), 0);

        console.log("Total Min ERP:", totalMinErp);
        console.log("Total Min ERS:", totalMinErs);

        // Filter products related to the selected project
        const relatedProducts = allProjectsProducts.filter(
            (product) =>
                product.project_number === selectedProjectProduct?.project_number &&
                product.prefix === projectPrefix
        );

        // Find the maximum version number within the related products
        const maxVersionNumber =
            relatedProducts.length > 0
                ? Math.max(...relatedProducts.map((product) => product.version_number || 0))
                : 0;

        // Increment the version number
        const newVersionNumber = maxVersionNumber + 1;

        // Construct the custom ID
        const customId = `${projectPrefix}_${projectnumber}.${newVersionNumber}`;

        // Extract fields from the selected group and version
        const productPrice = productVersion.price || 0;
        const productAbbreviation = productGroup.abbreviation || "No Abbreviation";
        const productName = productGroup.product_name || "Unnamed Product";

        // Define the new product
        const newProduct: Omit<ProjectProduct, "id"> = {
            project_number: selectedProject || 0,
            prefix: projectPrefix,
            product_name: productName,
            customization: productVersion.type || "",
            modified_by: auth.currentUser?.displayName || "Unknown User",
            created_on: formatDate(new Date()),
            modified_on: formatDate(new Date()),
            owner: auth.currentUser?.displayName || "Unknown User",
            pipeline_id: 0,
            price: productPrice,
            hour_tariff_3e: 0,
            min_3e_partij: 0,
            min_erp: totalMinErp, // Assign the calculated total min_erp
            min_ers: totalMinErs, // Assign the calculated total min_ers
            version_number: newVersionNumber,
            product: productAbbreviation,
        };

        const apiPayload = {...newProduct, rowkey: customId, collection: "project_product_information", action: "created"};

        try {
            // Create the new document in the database
            const createdDocRef = await createDoc("project_product_information", newProduct, customId);

            const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);

            if (createdDocRef) {
                const productWithId = { ...newProduct, id: customId };
                setAllProjectsProducts([...allProjectsProducts, productWithId]); // Add to the product list
                onSelectProjectProduct(productWithId); // Set the new product as selected
                setEditedProduct(productWithId); // Set as the editable product
                setIsEditing(true); // Enable edit mode
                closeDialog(); // Close the dialog
            }
        } catch (error) {
            console.error("Error creating new product:", error);
        }
    };
    
    
        
        
        
        const filteredVersions = allProductVersions.filter(
            (version) =>
                version.product_number === selectedProductGroup && // Matches product group
                version.prefix === projectPrefix // Matches prefix
        );
        //console.log("Filtered Versions:", filteredVersions);
        

    const handleDeleteProduct = async () => {
        if (selectedProjectProduct) {
            const confirmDelete = window.confirm("Are you sure you want to delete this product?");
            if (confirmDelete) {
                await removeDoc("project_product_information", selectedProjectProduct.id);
                const apiPayload = {...selectedProjectProduct, rowkey: selectedProjectProduct.id, collection: "project_product_information", action: "deleted"};
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
                setAllProjectsProducts(
                    allProjectsProducts.filter((product) => product.id !== selectedProjectProduct.id)
                );
                onSelectProjectProduct(null); // Reset selected product
                setIsEditing(false);
            }
        }
    };

    const handleSaveProduct = async () => {
        if (editedProduct) {
            const { id, ...productDataWithoutId } = editedProduct;
    
            // Add `modified_on` and `modified_by` fields
            const updatedProduct = {
                ...productDataWithoutId,
                modified_on: formatDate(new Date()), // Current date and time
                modified_by: auth.currentUser?.displayName || "Unknown User", // Current user
            };

            const apiPayload = {...updatedProduct, rowkey: id, collection: "project_product_information", action: "updated"};
    
            try {
                // Save the updated product to Firestore
                await mergeDoc("project_product_information", id, updatedProduct);
    
                // Update local state with the saved product
                setAllProjectsProducts(
                    allProjectsProducts.map((product) =>
                        product.id === id ? { ...product, ...updatedProduct } : product
                    )
                );
    
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);

                onSelectProjectProduct({ ...editedProduct, ...updatedProduct }); // Update selected product
                setIsEditing(false); // Exit edit mode
    
                console.log("Product saved successfully:", updatedProduct);
            } catch (error) {
                console.error("Failed to save product:", error);
            }
        } else {
            console.error("No product selected to save.");
        }
    };
    

    const editableFields = projectProductFieldConfigs.filter((config) => !config.readonly);
    const sortedEditableFields = editableFields.sort((a, b) => a.order_number - b.order_number);
    return (
        <div>
            

            {/* Product Actions */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <Button variant="contained" color="secondary" onClick={openDialog} >
                    Create New
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteProduct}
                    disabled={!selectedProjectProduct}
                >
                    Delete
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setIsEditing(!isEditing);
                        setEditedProduct(selectedProjectProduct);
                    }}
                    disabled={!selectedProjectProduct}
                >
                    {isEditing ? "Cancel Edit" : "Edit"}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveProduct}
                    disabled={!isEditing || !editedProduct}
                >
                    Save
                </Button>
            </div>

            {/* Editable Product Details */}
            {isEditing && editedProduct && (
                <div>
                    {sortedEditableFields.map((config) => (
                        <TextField
                            key={config.key}
                            label={config.label}
                            type={config.type}
                            value={editedProduct[config.key] ?? ""}
                            onChange={(e) =>
                                setEditedProduct({
                                    ...editedProduct,
                                    [config.key]:
                                        config.type === "number" ? parseFloat(e.target.value) : e.target.value,
                                })
                            }
                            fullWidth
                            margin="normal"
                            disabled={config.readonly}
                        />
                    ))}
                </div>
            )}

            {/* Display Selected Product Details */}
            {!isEditing && selectedProjectProduct && (
                <div>
                    {sortedEditableFields.map((config) => (
                        <p key={config.key} style={{ margin: "5px 0" }}>
                            <strong>{config.label}:</strong> {selectedProjectProduct[config.key] ?? "N/A"}
                        </p>
                    ))}
                </div>
            )}

            {!isEditing && !selectedProjectProduct && <p>No product selected.</p>}

            {/* Dialog for Creating a New Product */}
            <Dialog open={isDialogOpen} onClose={closeDialog}>
                <DialogTitle>Select Product Group and Version</DialogTitle>
                <DialogContent>
                    {/* Dropdown for Product Group */}
                    <Select
                        value={selectedProductGroup || ""}
                        onChange={(e) => {
                            const selectedGroupId = e.target.value; // Get the selected product group ID
                            setSelectedProductGroup(selectedGroupId); // Update state
                            const selectedGroupDetails = allProductGroups.find(
                                (group) => group.product_number.toString() === selectedGroupId
                            );
                            console.log("Selected Product Group ID:", selectedGroupId);
                            console.log("Selected Product Group Details:", selectedGroupDetails); // Log the full details
                        }}
                        displayEmpty
                        fullWidth
                        style={{ marginBottom: "20px" }}
                        disabled={!projectPrefix} // Disable if no prefix is selected
                    >
                        <MenuItem value="" disabled>
                            Select a Product Group
                        </MenuItem>
                        {allProductGroups
                            .filter((group) => group.prefix === projectPrefix) // Filter by prefix
                            .map((group) => (
                                <MenuItem key={group.product_number} value={group.product_number}>
                                    {group.product_name}
                                </MenuItem>
                            ))}
                    </Select>






                    {/* Dropdown for Product Version */}
                    {/* Dropdown for selecting Product Version */}
                    <Select
                        value={selectedProductVersion || ""} // Use the ID of the selected version
                        onChange={(e) => {
                            // Find the selected version by id
                            const selectedVersion = allProductVersions.find((version) => version.id === e.target.value);
                            setSelectedProductVersion(selectedVersion?.id || ""); // Update state with the selected version's ID
                            console.log("Selected Product Version:", selectedVersion); // Log selected version details
                        }}
                        displayEmpty
                        fullWidth
                        style={{ marginBottom: "20px" }}
                        disabled={!selectedProductGroup} // Disable until a product group is selected
                    >
                        <MenuItem value="" disabled>
                            Select a Product Version
                        </MenuItem>
                        {allProductVersions
                            .filter((version) => {
                                // Ensure product_number matches the selected product group
                                return version.product_number === selectedProductGroup;
                            })
                            .map((version) => (
                                <MenuItem key={version.id} value={version.id}>
                                    {version.version_number} - {version.type} {/* Display version details */}
                                </MenuItem>
                            ))}
                    </Select>





                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateProduct} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RelatedProductsDetails;
