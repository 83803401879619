import React, {useEffect, useState} from "react";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import { fetchBatteryPrep } from "../../services/apiFunctions";
import InputSpec from "../../components/computation-input-specific/InputSpecific";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from 'react-i18next'; // Import for translations
import ExcelUpload from "../../components/excel-upload/ExcelUpload";
import { getPrepPayloadIds, formatOptionFields, getAllOptions, OutputTable} from "../../layout/solar-battery/batteryFunctions";
import { Option, InteractionData, BatteryPrepIds, OutputDataPrep, PieData, UsageData, ProgProdData } from "../../layout/solar-battery/batteryInterfaces";
import UsageGraphs from "./graphs";
import { getDownloadURL, ref } from "firebase/storage";
import {storage} from "../../config/firebase";
import DoughnutChart from "../../components/plots/PieChart";
import { set } from "lodash";

interface BatteryPrepProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionData[];
	generalData: any; // Replace 'any' with the correct type
	template: any;
	pageReady: boolean;
}

const SolarBatteryPrep: React.FC<BatteryPrepProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	generalData,
	template,
	pageReady
}) => {
	const computationType = "solar_battery_computations";
	const { t } = useTranslation(); // Translation hook
	const [production, setProduction] = useState<number>(0);
	const [progProduction, setProgProduction] = useState<number>(0);
	const [dataChanged, setDataChanged] = useState(false);
	const [existingExcel, setExistingExcel] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [computationOutput, setComputationOutput] = useState<OutputDataPrep>();
	const [allOptions, setAllOptions] = useState<Option[]>([]);
	const [payloadIds, setPayloadIds] = useState<BatteryPrepIds>();
	const [prodPie, setProdPie] = useState<PieData[] | null>(null);
	const [usagePie, setUsagePie] = useState<PieData[] | null>(null);
    const [usageGraphData, setUsageGraphData] = useState<UsageData | null>(null);
	const [progProdGraphData, setProgProdGraphData] = useState<ProgProdData[]>();
	const [chartUrl, setChartUrl] = useState<string | null>(`solar_battery_computations/${updateId}/${selectedInteraction}/images/gtv-graph.png`);

    const preppedDataUrl = `solar_battery_computations/${updateId}/${selectedInteraction}/simul_data/prepped_data.parquet`;
	const [presentingMode, setPresentingMode] = useState<"values" | "graphs">("values");

	const checkBatteryInputs = async () => {
		const currentInteraction = interactionData.find((interaction) => interaction.id === selectedInteraction);
		if (currentInteraction && Object.keys(payloadIds || {}).length !== 3){
			const ids = await getPrepPayloadIds(currentInteraction);
			setPayloadIds(ids);
		}
		if (currentInteraction && currentInteraction.fields && payloadIds){
			let consumption = payloadIds["consumption_input_type"];
			let contracted = payloadIds["contracted_power"];
			let prod_data_type = payloadIds["production_data_type"];

			return {
				consumption_input_type: currentInteraction.fields[consumption] || "",
				contracted_power: currentInteraction.fields[contracted] || 0,
				production_data_type: currentInteraction.fields[prod_data_type] || ""
			}
		}
	};

    const checkPrep = async () => {
        const storageRef = ref(storage, preppedDataUrl);
        try {
            const preppedData = await getDownloadURL(storageRef);
            return true;
        } catch (error) {
            console.error("Prepped data not found");
            return false;
        }
    };

	useEffect(() => {
        const updateStatus = async () => {
            const currentInteraction = interactionData.find(
                (interaction) => interaction.id === selectedInteraction
            );
            if (!currentInteraction) return;

            const status = await checkPrep();
            // const status = currentInteraction.simultaneous !== undefined;
            if (status !== undefined && status !== pageReady) {
                mergeDoc(computationType, updateId, {
                    progress: {
                        3: {
                            done: status,
                            name: "Data preparation",
                        },
                    },
                });
            }
        };
        updateStatus();
	}, [interactionData, selectedInteraction, pageReady, updateId]);

	useEffect(() => {
		if (dataChanged === true) {
			mergeDoc(
				`${computationType}/${updateId}/computation_inputs`,
				selectedInteraction,
				{
					computation_present: false,
					pdf_present: false,
				}
			);
		}
	}, [dataChanged, selectedInteraction, updateId]);

	useEffect(() => {
		const currentInteraction = interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		);
		if (!currentInteraction) return;
		
		if (currentInteraction?.quarterly_prepped && currentInteraction?.quarterly_prepped?.usage_data){
			const preppedData = currentInteraction.quarterly_prepped;
            const usage_data = preppedData.usage_data;

			const combData = {
				usage_data: usage_data
			}

			const dataHasChanged = combData !== usageGraphData
			if (dataHasChanged) {
				setUsageGraphData(combData);
			}
		}
		if (currentInteraction?.quarterly_prepped && currentInteraction?.quarterly_prepped?.prog_prod_data){
			const preppedData = currentInteraction.quarterly_prepped;
            const progProdData = preppedData.prog_prod_data;

			const dataHasChanged = progProdData !== progProdGraphData
			if (dataHasChanged) {
				setProgProdGraphData(progProdData);
			}
		}
		if (currentInteraction?.quarterly_prepped && currentInteraction?.quarterly_prepped?.output_values){
			const data = {
				data: currentInteraction.quarterly_prepped.output_values
			}
			if (computationOutput === undefined){
				setComputationOutput(data);
			}
		}
		if (currentInteraction?.quarterly_prepped && currentInteraction?.quarterly_prepped?.pie_data){
			const prodPieData = currentInteraction.quarterly_prepped.pie_data.prod_dist;
			const usagePieData = currentInteraction.quarterly_prepped.pie_data.usage_dist;
			const prodChanged = prodPieData !== prodPie;
			if (prodChanged){
				setProdPie(prodPieData);
			}
			const usageChanged = usagePieData !== usagePie;
			if (usageChanged){
				setUsagePie(usagePieData);
			}
		}

		const updates = [
			[currentInteraction.production?.[0]?.kwh_production, production, setProduction, true],
			[currentInteraction.prog_production?.[0]?.kwh_production, progProduction, setProgProduction, true],
			// [currentInteraction.quarterly_prepped?.output_values, computationOutput, setComputationOutput, false],
		];
		updates.forEach(([newValue, stateValue, setter, markChanged]) => {
			if (newValue !== undefined && newValue !== stateValue) {
				setter(newValue);
				if (markChanged){
					setDataChanged(true);
				}
			}
		});
	}, [selectedInteraction, interactionData]);

	function handleForceButtonClick(){
		if (loading === false){
			prepareQuarterlyValues();
		}else{
			setErrorMessage("Please wait for the current calculation to finish");
		}
	}

	const prepareQuarterlyValues = async () => {
		try {
			const selectedInteractionData: InteractionData | undefined =
				interactionData.find(
					(interaction) => interaction.id === selectedInteraction
				);
			// Fetch Battery Simultaneous data
			setLoading(true);
			setErrorMessage("");

			const batteryParams = await checkBatteryInputs();
			const options = await getAllOptions();
			setAllOptions(options);
			if (batteryParams){
				const updatedBatParams = formatOptionFields(batteryParams, allOptions);
				
				const payload = {
					production: production,
					prog_production: progProduction,
					client_id: updateId,
					computation_id: selectedInteraction,
					language_version: generalData[0].fields?.["QQCT34RNyYnuu1UdpV9j"],
					consumption_input_type: updatedBatParams.consumption_input_type,
					contracted_power: updatedBatParams.contracted_power,
					production_data_type: updatedBatParams.production_data_type,
				}

				setPresentingMode("values");
				const prep_result = await fetchBatteryPrep(payload);
				setLoading(false);

				if (prep_result?.data?.usage_data && prep_result?.data?.image_data && 
					prep_result?.data?.output_values && prep_result?.data?.pie_data &&
					prep_result?.data?.prog_prod_data){

                    const outputData = {data: prep_result.data?.output_values};
					const usageCombined = { usage_data: prep_result.data.usage_data }

                    setComputationOutput(outputData);
                    setUsageGraphData(usageCombined);
                    setChartUrl(prep_result.data.image_data[0].img_path);
					setProdPie(prep_result.data.pie_data?.["prod_dist"]);
					setUsagePie(prep_result.data.pie_data?.["usage_dist"]);
					setProgProdGraphData(prep_result.data.prog_prod_data);
				}

				// Merge computed data to Firestore
				if (selectedInteractionData?.fields) {
					if (prep_result?.data?.usage_data && prep_result?.data?.image_data && prep_result?.data?.output_values){
						mergeDoc(
							`${computationType}/${updateId}/computation_inputs`,
							`${selectedInteraction}`,
							{
								quarterly_prepped: prep_result.data,
							}
						);
					}
				}

				if (prep_result?.data?.Error) {
					setErrorMessage(prep_result.data.Error);
				}
				else{
					setErrorMessage("");
				}
				setDataChanged(false);
			}
			else{
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.error("Error fetching or merging data:", error);
		}
	};

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			<div style={{display: "flex", flexDirection: "row"}}>
				<div>
				{selectedInteraction !== "" && (
					<div
						style={{
							...styles.rightPanelStyle,
							minWidth: "400px",
							minHeight: "80vh",
							maxHeight: "80vh",
							marginLeft: "-10px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between"
						}}
					>
						<div>
							<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
								{" "}
								{t('inputQuarter')}{" "}
							</h3>
							<InputSpec
								updateId={updateId}
								interactionId={selectedInteraction}
								interactionData={interactionData}
								filter={"quarter_value_specs"}
								templateUnfiltered={template}
								computationType={computationType}
							/>
							<ExcelUpload 
								path={`${computationType}/${updateId}/${selectedInteraction}/simul_data/consumption_data`}
								existingExcel={existingExcel}
								setExistingExcel={setExistingExcel} 
							/>
							<div style={{marginTop: "10px"}}>
							<p	style={{
								marginBottom: "10px",
								tabSize: 4,
								fontSize:"0.8rem"
								}}>
									<button onClick={handleForceButtonClick}>Process Data</button>
									<span style={{marginLeft: "8px"}}>{loading ? (<CircularProgress size={15} />) : errorMessage}</span>
							</p>
							</div>				
						</div>
					</div>
				)}
				</div>
				<div style={{
					...styles.rightPanelStyle,
					minWidth: "500px",
					maxWidth: "70vw",
					minHeight: "80vh",
					maxHeight: "80vh",
					marginLeft: "10px",
					}}
				>
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
						{" "}
						{t('simultaneousView')} 
						<button 
							style={{marginLeft: "10px", maxHeight: "40px", maxWidth: "100px"}}
							onClick={() => setPresentingMode(presentingMode == "graphs" ? "values" : "graphs")} >
							{presentingMode == "graphs" ? "Variabelen" : "Grafieken"}
						</button>
					</h3>
						<div style={{
								...styles.rightPanelStyle,
								// maxWidth: "70vw",
								marginTop: "10px",
								marginLeft: "-10px",
								}}
							>
							{presentingMode == "values" ? (
                            <div>
                                {computationOutput && (
                                <OutputTable title={t("prepValues")} data={computationOutput.data} loading={loading} suffix="kWh" />
                                )}
								{prodPie && !loading && (
									<DoughnutChart data={prodPie} />
								)}
								{usagePie && !loading && (
									<DoughnutChart data={usagePie} />
								)}
                            </div>
                            ) : (
                            <div>
                                {usageGraphData && production && (
                                <UsageGraphs
                                usageChartData={usageGraphData}
                                production={production}
                                chartUrl={chartUrl}
								progProdData={progProdGraphData}/>
                                )}
                            </div>
							)}
						</div>
				</div>
			</div>
		</div>
	);
};

export default SolarBatteryPrep;
