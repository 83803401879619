import React, { useEffect, useState, useCallback } from "react";
import MaterialUITable from "../../components/sortable-table";
import { styles } from "../../components/utils/Styles";
import { Switch } from "@mui/material"; // Import the Switch component from Material-UI
import {
	fetchDocsData,
	mergeDoc,
	fetchDocsSnap,
	fetchDocData,
	createDoc,
	fetchDocsDataStream,
} from "../../services/firebaseFunctions";
import MultiDropdownTable from "../../components/data-multi-dropdown/DataMultiDropdown";
import { List } from "lodash";
import { useTranslation } from "react-i18next";

interface DataInputProps {
	collectionName: string;
}
interface Template { }

interface Field {
	// Define the properties of the Field type
}

interface Option {
	id: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
	data_type: string;
}

interface Category {
	id: string;
	name: string;
	name_en: string;
	name_clean: string;
}

interface ClientInput {
	id: string
	conditional_field: string;
	conditional_values: List<string>;
	data_type: string;
	default_value: string;
	description: string;
	input_categories: List<string>;
	name: string;
	name_clean: string;
	name_en: string;
	options: List<string>;
	per_room: boolean;
	rank: number;
	unit: string;
	conditional_field_pv?: string;
	conditional_values_pv?: List<string> | string;
  }


const DataInput: React.FC<DataInputProps> = ({ collectionName }) => {
	const [formData, setFormData] = useState<Record<string, any>>({});
	const [data, setData] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const { t } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState<string>('');
	const [selectedId, setSelectedId] = useState<string | null>(null);
	const [template, setTemplate] = useState<Record<string, any>>({});
	const [dropdownOptionsData, setDropdownOptionsData] = useState<any[]>([]);
	const [showCreateButton, setShowCreateButton] = useState<boolean>(true);
	const [showUpdateButton, setShowUpdateButton] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState<Option | null>(null)
	const [selectedClientOptionValue, setSelectedClientOptionValue] = useState<Option | null>(null)
	const [selectedClientOption, setSelectedClientOption] = useState<ClientInput | null>(null)
	const [options, setOptions] = useState<Option[]>([])
	const [filteredOptions, setFilteredOptions] = useState<Option[]>([])
	const [clientInputsWithOptions, setClientInputsWithOptions] = useState<any[]>([])
	const [categories, setCategories] = useState<Category[]>([])
	const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
	const [dataTransformedOptions, setDataTransformedOptions] = useState<any[]>([]);

	useEffect(() => {
		const checkExistingDocument = async () => {
			try {
				if (formData.name_clean) {
					const nameClean = formData.name_clean;
					const existingDocs = data.find((doc) => doc.name_clean === nameClean);
					if (existingDocs) {
						setShowCreateButton(false);
						setShowUpdateButton(true);
						setSelectedId(existingDocs.id);
					} else {
						setShowCreateButton(true);
						setShowUpdateButton(false);
					}
				}
			} catch (error) {
				console.error("Error checking existing document: ", error);
			}
		};

		checkExistingDocument();
	}, [formData.name_clean, collectionName, data]);

	const fetchTemplate = useCallback(async () => {
		try {
			const querySnapshot = await fetchDocsSnap(
				`templates/${collectionName}/columns`
			);
			const templateData: Record<string, any> = {};
			if (querySnapshot) {
				querySnapshot.docs.forEach((doc) => {
					const { data_type, name, order, mandatory, options } = doc.data();
					templateData[doc.id] = { data_type, name, order, mandatory, options };
				});
			}

			const sortedTemplateData: Template = Object.entries(templateData)
				.sort(([, a], [, b]) => a.order - b.order)
				.reduce((acc: Record<string, Field>, [key, value]) => {
					acc[key] = value;
					return acc;
				}, {});
			return sortedTemplateData;
		} catch (error) {
			console.error("Error fetching template: ", error);
			return null;
		}
	}, [collectionName]);

	const handleInputChange = (fieldId: string, value: any) => {
		if (template[fieldId]?.data_type === "ID") {
			const selectedOption = dropdownOptionsData.find(
				(option) => option.id === value
			);
			setFormData((prevData) => ({
				...prevData,
				[fieldId]: selectedOption?.id || "",
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[fieldId]: value,
			}));
		}
	};

	useEffect(() => {
		fetchDocsDataStream("options", setOptions as any);
	}, []);

	useEffect(() => {
		fetchDocsDataStream("input_categories", setCategories as any);
	}, []);

	useEffect(() => {
		if (t('language') === 'Taal'){
			setSelectedLanguage('nl')
		}
		else{
			setSelectedLanguage('en')
		}
	}, [t]);

	useEffect(() => {
		const filterClientOptions = () => {
			if (collectionName === "client_inputs" && data.length>0 ){
				const updatedData: React.SetStateAction<any[]> = [];

				data.forEach((item) => {
					if (item.options && item.options.length > 0) {
						updatedData.push(item);
					}
				});
				setClientInputsWithOptions(updatedData);
			}
		};
		filterClientOptions()
	}, [data, collectionName]);

	const updateDocument = async () => {
		try {
			if (!selectedId || Object.keys(formData).length === 0) {
				console.log("No document selected or no data to update");
				return;
			}

			Object.entries(template).forEach(([fieldId, fieldInfo]) => {
				if (!(fieldId in formData)) {
					formData[fieldId] = fieldInfo.data_type === "BOOLEAN" ? false : "";
				} else {
					if (
						fieldInfo.data_type === "INTEGER" ||
						fieldInfo.data_type === "DECIMAL"
					) {
						formData[fieldId] = Number(formData[fieldId]);
					}
				}
			});

			await mergeDoc(collectionName, selectedId, formData);
			updateDataWithNames();
		} catch (error) {
			console.error("Error updating document: ", error);
		}
	};

	const fetchDropdownOptions = useCallback(async () => {
		try {
			let optionsData: any = [];
			if (collectionName === "energy_saving_measures") {
				optionsData = await fetchDocsData("energy_saving_categories");
			} else if (collectionName === "energy_screening_measures") {
				optionsData = await fetchDocsData("energy_screening_categories");
			}

			setDropdownOptionsData(optionsData);
		} catch (error) {
			console.error("Error fetching dropdown options: ", error);
		}
	}, [collectionName]);

	const handleAddIdListItem = (fieldId: string, optionId: string) => {
		setFormData((prevData) => {
		  const currentList = prevData[fieldId] || [];
		  if (currentList.includes(optionId)) {
			return prevData;
		  }
	  
		  return {
			...prevData,
			[fieldId]: [...currentList, optionId],
		  };
		});
	  };

	const handleClearIdList = (fieldId: string) => {
		setFormData((prevData) => ({
			...prevData,
			[fieldId]: [], 
		}));
	};

	const filteredOptionsArray = (selected: ClientInput) => { 
		    const optionsArray = Array.from(selected.options);
            const filtered = options.filter(option =>
                optionsArray.includes(option.id))
			
				setFilteredOptions(filtered);
		};

	useEffect(() => {
		if (selectedClientOption) {
			handleClearIdList('conditional_values')
			filteredOptionsArray(selectedClientOption)
		}
	}
	,[selectedClientOption])

	useEffect(() => {
		if (selectedOption) {
			handleAddIdListItem('options', selectedOption.id)
		}
	}
	,[selectedOption])

	useEffect(() => {
		if (selectedCategory) {
			handleAddIdListItem('input_categories', selectedCategory.id)
		}
	}
	,[selectedCategory])

	useEffect(() => {
		if (selectedClientOptionValue) {
			handleAddIdListItem('conditional_values', selectedClientOptionValue.id)
		}
	}
	,[selectedClientOptionValue])

	const updateDataWithNames = () => {
		const updatedData = data.map((item) => {
			let updatedOptions;
			if (item.options) {
			updatedOptions = item.options.map((optionId: string) => {
				const matchedOption = options.find(opt => opt.id === optionId);
				return matchedOption ? matchedOption.name + ", " : optionId;
			});
			}
			let updatedCategories;
			if (item.input_categories) {
			updatedCategories = item.input_categories.map((categoryId: string) => {
				const matchedCategory = categories.find(cat => cat.id === categoryId);
				return matchedCategory ? matchedCategory.name + ", " : categoryId;
			});
			}
			let updatedConditionalValues;
			if (item.conditional_values) {
			updatedConditionalValues = item.conditional_values.map((valueId: string) => {
				const matchedValue = options.find(cat => cat.id === valueId);
				return matchedValue ? matchedValue.name + ", " : valueId;
			});
			}
			let updatedConditionalField;
			if (item.conditional_field) {
			const matchedValue = clientInputsWithOptions.find(cat => cat.id === item.conditional_field);
			updatedConditionalField = matchedValue ? matchedValue.name + ", " : item.conditional_field;
			}

			return {
				...item,
				options: updatedOptions || item.options,
				input_categories: updatedCategories || item.input_categories,
				conditional_values: updatedConditionalValues || item.conditional_values,
				conditional_field: updatedConditionalField || item.conditional_field,
			  };
			});

		setDataTransformedOptions(updatedData);
	};

    useEffect(() => {
		console.log("Update options effect")
        updateDataWithNames();
    }, [options, categories, clientInputsWithOptions, data]);

	const handleRemoveStringListItem = (fieldId: string, index: number) => {
		setFormData((prevData) => {
			const updatedList = [...(prevData[fieldId] || [])];
			updatedList.splice(index, 1);
			return { ...prevData, [fieldId]: updatedList };
		});
	};

	const fetchSelectedDocument = useCallback(async () => {
		if (!selectedId) return;
		setFormData(await fetchDocData(collectionName, selectedId));
	}, [selectedId, collectionName]);

	useEffect(() => {
		fetchSelectedDocument();
	}, [fetchSelectedDocument]);

	const fetchData = (
		collectionName: string,
		setData: React.Dispatch<React.SetStateAction<any[]>>
	) => {
		return fetchDocsDataStream(collectionName, setData);
	};

	useEffect(() => {
		const fetchDataAndDropdown = async () => {
			fetchData(collectionName, setData);
			fetchDropdownOptions();
			const templateData = await fetchTemplate();
			if (templateData) {
				setTemplate(templateData);
			}
			setLoading(false);
		};
		fetchDataAndDropdown();
	}, [collectionName]);

	const addDocument = useCallback(async () => {
		try {
			const documentData = {...formData};
			let existingDocs = null;
			if (collectionName === "options") {
				existingDocs = data.find((doc) => doc.id === selectedId);
			} else {
				const nameClean = formData.name_clean;
				existingDocs = data.find((doc) => doc.name_clean === nameClean);
			}
			if (existingDocs !== null && existingDocs !== undefined && collectionName !== "options") {
				const existingDocId = existingDocs.id;
				mergeDoc(collectionName, existingDocId, documentData);
			} else {
				Object.entries(template).forEach(([fieldId, fieldInfo]) => {
					if (!(fieldId in documentData)) {
						documentData[fieldId] =
							fieldInfo.data_type === "BOOLEAN" ? false : "";
					} else {
						if (
							fieldInfo.data_type === "INTEGER" ||
							fieldInfo.data_type === "DECIMAL"
						) {
							documentData[fieldId] = Number(documentData[fieldId]);
						}
					}
				});
				await createDoc(collectionName, documentData);
			}
		} catch (error) {
			console.error("Error adding/updating document: ", error);
		}
	}, [formData, template, collectionName, data]);

	const handleStringListChange = (
		fieldId: string,
		index: number,
		value: any
	) => {
		setFormData((prevData) => {
			const updatedList = [...(prevData[fieldId] || [])];
			updatedList[index] = value;
			return {...prevData, [fieldId]: updatedList};
		});
	};

	const handleAddStringListItem = (fieldId: string) => {
		setFormData((prevData) => ({
			...prevData,
			[fieldId]: [...(prevData[fieldId] || []), ""],
		}));
	};

	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			if (e.key === "Enter") {
				addDocument();
			}
		};
		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [addDocument]);

	const renderInputFields = () => {
		return Object.entries(template).map(([fieldId, fieldInfo]) => (
			<div key={fieldId} style={styles.inputWrapper}>
				<label>
					{fieldInfo.mandatory && <span style={{ color: 'red' }}>*</span>}
					{fieldInfo.name}
				</label>
				{fieldInfo.data_type === 'INTEGER' && (
					<input
						type="number"
						value={formData[fieldId] || ''}
						onChange={(e) => handleInputChange(fieldId, e.target.value)}
						style={styles.input}
					/>
				)}
				{fieldInfo.data_type === 'DECIMAL' && (
					<input
						type="number"
						step="any"
						value={formData[fieldId] || ''}
						onChange={(e) => handleInputChange(fieldId, e.target.value)}
						style={styles.input}
					/>
				)}
				{fieldInfo.data_type === 'BOOLEAN' && (
					<Switch
						checked={!!formData[fieldId]}
						onChange={(e) => handleInputChange(fieldId, !formData[fieldId])}
						color="primary"
					/>
				)}
				{fieldInfo.data_type === 'ID' && (
					renderDropdown(fieldId)
				)}
				{fieldInfo.data_type === 'ID_LIST' && fieldId === 'options' && (
					<>
						< MultiDropdownTable 
						options = {options as any}
						selectedOption={selectedOption}
						setSelectedOption={setSelectedOption}
						type='option'
					 /> 
						{(formData[fieldId] || []).map((item: any, index: any) => (
							<div
								key={index}
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: '4px',
								}}
							>
								<input
									value={selectedLanguage === 'en' ? options.find((option: any) => option.id === item)?.name_en : options.find((option: any) => option.id === item)?.name || ''}
									style={{ ...styles.input, marginRight: '8px' }}
									readOnly
								/>
								<button
									onClick={() => handleRemoveStringListItem(fieldId, index)}
									style={{ marginRight: '8px' }}
								>
									x
								</button>
							</div>
						))}
					</>
				)}
				{fieldInfo.data_type === 'ID_LIST' && fieldId === 'conditional_field' && (
					<>
						< MultiDropdownTable
						options = {clientInputsWithOptions as any} 
						selectedOption={selectedClientOption}
						setSelectedOption={setSelectedClientOption}
						type='client_inputs'
					    /> 
						
					</>
				)}
				{fieldInfo.data_type === 'ID_LIST' && fieldId === 'conditional_values' &&(
					<>
						< MultiDropdownTable
						options = {filteredOptions as any}
						selectedOption={selectedClientOptionValue}
						setSelectedOption={setSelectedClientOptionValue}
						type='client_options'
					    /> 
						{(formData[fieldId] || []).map((item: any, index: any) => (
							<div
								key={index}
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: '4px',
								}}
							>
								<input
									value={selectedLanguage === 'en' ? options.find((option: any) => option.id === item)?.name_en : options.find((option: any) => option.id === item)?.name || ''}
									style={{ ...styles.input, marginRight: '8px' }}
									readOnly
								/>
								<button
									onClick={() => handleRemoveStringListItem(fieldId, index)}
									style={{ marginRight: '8px' }}
								>
									x
								</button>
							</div>
						))}
					</>
				)}
				{fieldInfo.data_type === "ID_LIST" && fieldId === 'input_categories' &&(
					<>
					< MultiDropdownTable 
					options = {categories as any}
					selectedOption={selectedCategory}
					setSelectedOption={setSelectedCategory}
					type='category'
				 /> 
					{(formData[fieldId] || []).map((item: any, index: any) => (
						<div
							key={index}
							style={{
								display: 'flex',
								alignItems: 'center',
								marginBottom: '4px',
							}}
						>
							<input
								value={selectedLanguage === 'en' ? categories.find((categorie: any) => categorie.id === item)?.name_en : categories.find((categorie: any) => categorie.id === item)?.name || ''}
								style={{ ...styles.input, marginRight: '8px' }}
								readOnly
							/>
							<button
								onClick={() => handleRemoveStringListItem(fieldId, index)}
								style={{ marginRight: '8px' }}
							>
								x
							</button>
						</div>
					))}
				</>
			)}
			{fieldInfo.data_type === "STRING_LIST" &&(
					<div>
					{(formData[fieldId] || []).map((item: string, index: number) => (
						<div
							key={index}
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "4px",
							}}
						>
							<input
								type="text"
								value={item || ""}
								onChange={(e) =>
									handleStringListChange(fieldId, index, e.target.value)
								}
								style={{...styles.input, marginRight: "8px"}}
							/>
							<button
								onClick={() => handleRemoveStringListItem(fieldId, index)}
								style={{marginRight: "8px"}}
							>
								x
							</button>
						</div>
					))}
					<button onClick={() => handleAddStringListItem(fieldId)}>+</button>
				</div>
			)}
			{fieldInfo.data_type === "TEXT" && (
					<textarea
						value={formData[fieldId] || ''}
						style={{ ...styles.input, 
							marginRight: '8px',
							width: '100%', 
							height: '100px',
							maxHeight: '500px',
							fontSize: '14px', 
							boxSizing: 'border-box', 
							overflow: 'hidden', 
							resize: 'vertical'
						}}
						onChange={(e) => handleInputChange(fieldId, e.target.value)}
					/>
			)}
				{	fieldInfo.data_type !== 'INTEGER' &&
					fieldInfo.data_type !== 'DECIMAL' &&
					fieldInfo.data_type !== 'BOOLEAN' &&
					fieldInfo.data_type !== 'ID' &&
					fieldInfo.data_type !== 'ID_LIST' &&
					fieldInfo.data_type !== 'STRING_LIST' &&
					fieldInfo.data_type !== 'TEXT' && (
						<input
							type="text"
							value={formData[fieldId] || ''}
							onChange={(e) => handleInputChange(fieldId, e.target.value)}
							style={styles.input}
						/>
					)}
			</div>
		));
	};

	const renderDropdown = (fieldId: string) => (
		<select
			value={formData[fieldId] || ""}
			onChange={(e) => handleInputChange(fieldId, e.target.value)}
			style={styles.input}
		>
			<option value="" disabled>
				Select an option
			</option>
			{dropdownOptionsData.map((option) => (
				<option key={option.id} value={option.id}>
					{option.name}
				</option>
			))}
		</select>
	);

	return (
		<div
			style={{ display: "flex", ...styles.rightPanelStyle, marginLeft: "10px" }}
		>
			<div>
				<div style={{ maxWidth: "15vw" }}>{renderInputFields()}</div>
				{showCreateButton && (
					<button
						onClick={addDocument}
						style={{
							marginLeft: "20px",
							padding: "10px 20px",
							backgroundColor: "#4CAF50",
							color: "#fff",
							border: "none",
							borderRadius: "5px",
							cursor: "pointer",
						}}
					>
						Voeg toe
					</button>
				)}
				{showUpdateButton && (
					<button
						onClick={updateDocument}
						style={{
							marginLeft: "20px",
							padding: "10px 20px",
							backgroundColor: "#4CAF50",
							color: "#fff",
							border: "none",
							borderRadius: "5px",
							cursor: "pointer",
						}}
					>
						Update
					</button>
				)}
			</div>
			<div>
				{loading ? (
					<p>Loading...</p>
				) : (
					<MaterialUITable
						data={dataTransformedOptions}
						selectedId={selectedId}
						setSelectedId={setSelectedId}
						collectionName={collectionName}
						sortedTemplateData={template}
					/>
				)}
			</div>
		</div>
	);
};

export default DataInput;
