import React, {useState, useEffect} from "react";
import Main from "../main/Main";
import {useNavigate, useParams} from "react-router-dom";
import {styles} from "../../components/utils/Styles";
import DataInput from "../../screens/management-data-collections";
import DataModelOptions from "../../components/datamodel-bar/DataModelBar";
import Test from "../../screens/management-data-linking";
import UnauthorizedPage from "../../screens/unauthorized";
import useCustomClaims from "../../hooks/useCustomClaims";

interface PageRecord {
	id: string;
	name: string;
	input_ready: boolean;
}

const pages: {[key: string]: number} = {
	"client-inputs": 0,
	facts: 1,
	costs: 2,
	options: 3,
	"input-categories": 4,
	"energy-saving-measures": 5,
	"energy-saving-categories": 6,
	"energy-screening-measures": 7,
	"energy-screening-categories": 8,
	"energy-saving-linking": 9,
	"estimate-texts": 10,
	"estimate-workflows": 11,
	"solar-battery-scenarios": 12,
	"qem_control_input": 13,
};

const pageRecords: PageRecord[] = Object.entries(pages).map(([name, id]) => ({
	id: name,
	name: name,
	input_ready: true,
}));

const Data: React.FC = () => {
	const customClaims = useCustomClaims();
	const navigate = useNavigate();
	const preset = useParams<{collection?: string}>();
	const [activeScreen, setActiveScreen] = useState<string>(
		preset?.collection || "client-inputs"
	);

	useEffect(() => {
		navigate(`/data/${activeScreen}`);
	}, [activeScreen, navigate]);

	return customClaims &&
		(customClaims?.["data-manager"] || customClaims?.["admin"]) ? (
		<Main>
			<div style={{...styles.container}}>
				<DataModelOptions
					selectedInteraction={activeScreen}
					setSelectedInteraction={setActiveScreen}
					interactionData={pageRecords}
				/>
				{activeScreen !== "energy-saving-linking" && (
					<DataInput
						collectionName={activeScreen.replace("-", "_").replace("-", "_")}
					/>
				)}
				{activeScreen === "energy-saving-linking" && <Test />}
				{/* Adjust width as needed */}
			</div>
		</Main>
	) : (
		<UnauthorizedPage />
	);
};

export default Data;
