import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { ClientProvider } from "./context/ClientData/ClientContext"; // Refactor context for product data
import { OrganizationDealProvider } from "./context/OrgData/OrganizationDealContext"; // Refactor context for general data
import {auth} from "./config/firebase";
import routes from "./config/routes"; // Change for refactor
import Center from "./components/utils/Center";
import AuthChecker from "./components/auth-checker/AuthChecker";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

function App() {
	console.log(`App environment: ${process.env.REACT_APP_ENV}`);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				console.info("User detected.");
			} else {
				console.info("No user detected");
			}
			setLoading(false);
		});
	}, []);

	if (loading)
		return (
			<Center>
				<CircularProgress />
			</Center>
		);

	return (
		// <OrganizationDealProvider> {/* Change for refactor */}
		// 	<ClientProvider> {/* Change for refactor */}
				<DndProvider backend={HTML5Backend}>
					<div>
						<BrowserRouter basename={process.env.PUBLIC_URL}>
							<Routes>
								{routes.map((route, index) => (
									<Route
										key={index}
										path={route.path}
										element={
											route.protected ? (
												<AuthChecker>
													<route.component {...route.args} />
												</AuthChecker>
											) : (
												<route.component />
											)
										}
									/>
								))}
							</Routes>
						</BrowserRouter>
					</div>
				</DndProvider>
		// 	</ClientProvider> 
		// </OrganizationDealProvider>
	);
}

export default App;
