import React, {useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import renderInputFields from "../utils/renderInputFields";
import {mergeDoc} from "../../services/firebaseFunctions";
import { Template } from "../../general-interfaces";

interface GeneralProjectInputProps {
	updateId: string;
	generalData: any[];
	templateUnfiltered: Record<string, any>;
	computationType: string;
	filter?: string;
	title?: string;
}

const GeneralProjectInput: React.FC<GeneralProjectInputProps> = ({
	updateId,
	generalData,
	templateUnfiltered,
	computationType,
	filter
}) => {
	const {t} = useTranslation();
	const [formData, setFormData] = useState<Record<string, any>>({});
	const [change, setChange] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [filteredTemplate, setFilteredTemplate] = useState<Template>({});

	const fetchFormData = useCallback(async (template: Template) => {
		try {
		  const computationDataFields = generalData[0]?.fields || {};
		  setFormData(computationDataFields);
		  const filteredRecords: Template = {};
	
		  Object.keys(template).forEach((key) => {
			const record = template[key];
			if ( Array.isArray(record.input_categories) && record.input_categories.some((category: any) =>
				category?.name_clean && (Array.isArray(category.name_clean) ? category.name_clean.includes(filter) : category.name_clean === filter))
			){
			  filteredRecords[key] = record;
			}
			else if (record.input_categories && record.input_categories.includes(filter)) {
			  filteredRecords[key] = record;
			}
		  });
		  setFilteredTemplate(filteredRecords);
	
		} catch (error) {
		  console.error("Error fetching computation data:", error);
		  setFormData({});
		}
	  }, [generalData, filter]);

	useEffect(() => {
		const fetchData = async () => {
		  if (loaded === false) {
			try {
			  setLoading(true);
			  await fetchFormData(templateUnfiltered);
			} catch (error) {
			  console.error("Error fetching template: ", error);
			} finally {
			  setLoading(false);
			  setLoaded(true);
			}
		  }
		};
	
		fetchData();
	}, [fetchFormData, templateUnfiltered, loaded]);

	useEffect(() => {
		if (change === true && Object.keys(formData).length > 0) {
			mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
				fields: formData,
				report_ready: false,
			});
			setChange(false);
		}
	}, [change, formData, computationType, updateId]);

	return (
		<div>
			{loading && <div>Loading...</div>}
			{!loading && (
			<div>
				{filteredTemplate && Object.keys(formData).length > 0 &&
					renderInputFields({
						template: filteredTemplate,
						formData: formData,
						setFormData: setFormData,
						setChange: setChange,
						t: t,
						filter: filter,
					})}
			</div>
			)}
		</div>
	);
};

export default GeneralProjectInput;
